import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import RightArrow from 'react-icons/lib/fa/angle-right';
import LeftArrow from 'react-icons/lib/fa/angle-left';

import { Button } from '../../components';
import './PortfolioCard.css';
import { theme } from '../../styles/theme';
import { useWindowWidth } from '../../hooks';
//import { medias } from "../../utils/mediaQueries";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;

  align-self: center;
  padding: 1em;
  margin: ${props => (props.noButton ? 0 : '0.1em')};

  background-color: ${theme.colors.white};
`;

const FullButton = styled(props => <Button {...props} />)`
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 100%;
  margin: 2em 1em 0 1em;
  font-size: 14px;
  padding: 0;
  height: 2.5em;
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  height: 100%;
`;

/*
const Title = styled.p`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  text-align: left;
`;

const Text = styled.p`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize}px;
  font-weight: 300;
  text-align: left;
`;
*/

// const Content = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start;
// `;

const StyledImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

/*const StyledVideo = styled.video`
  width: 100%;
  object-fit: contain;
`;*/

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <LeftArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        color: theme.colors.grey2,
        position: 'absolute',
        left: '2.5%',
        zIndex: 1,
        top: '35%',
        height: 60,
        width: 40,
      }}
      onClick={onClick}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <RightArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        color: theme.colors.grey2,
        position: 'absolute',
        right: '2.5%',
        zIndex: 1,
        top: '35%',
        height: 60,
        width: 40,
      }}
      onClick={onClick}
    />
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  dotsClass: 'slick-dots',
  fade: true,
};

const PortfolioCard = ({ content, noButton }) => {
  const windowWidth = useWindowWidth();

  const slides = content.map(({ id, source /*title, text,*/ }) => (
    <>
      {source && <StyledImg key={id} src={source} />}
      {/*<Content key={id}>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Content>*/}
    </>
  ));

  const handleClick = () => {
    const scrollHeight =
      windowWidth < 768 ? window.innerHeight * 0.7 : window.innerHeight * 1.5;

    window.scrollBy({
      top: -scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Wrapper noButton={noButton}>
      <StyledSlider {...settings}>{slides}</StyledSlider>
      {!noButton && (
        <FullButton text="SOLICITAR ORÇAMENTO" click={() => handleClick()} />
      )}
    </Wrapper>
  );
};

PortfolioCard.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      //title: PropTypes.string.isRequired,
      text: PropTypes.string,
      //backgroundImg: PropTypes.object.isRequired,
    })
  ).isRequired,
};

export default PortfolioCard;
