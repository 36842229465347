import React from 'react';
import styled from 'styled-components';
import CloseButton from 'react-icons/lib/io/ios-close-outline';

import { theme } from '../styles/theme';

const StyledButton = styled.button`
  font-size: 0.5em;
  height: 3em;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  span {
    display: block;
    width: 5em;
    flex: 0 0 20%;
    background: ${theme.colors.primary};
  }
`;

export const ExitButton = styled(CloseButton)`
  height: 21px;
  width: 21px;
  color: ${theme.colors.error};
  cursor: pointer;
  position: relative;
  /* left: 95%; */
  left: ${props => props.left};
  top: ${props => props.top};
`;

const HamburgerButton = props => {
  if (props.isOpen) {
    return <ExitButton className="hamburger" {...props} />;
  }

  return (
    <StyledButton className="hamburger" {...props}>
      <span />
      <span />
      <span />
    </StyledButton>
  );
};

export default HamburgerButton;
