import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStateValue } from '../../state';
import ModalContainer from '../ModalContainer';
import { DropdownContracts } from '../';
import { medias } from '../../utils/mediaQueries';
import {
  HeaderLogo,
  HeaderWrapper,
  HeaderNav,
  HeaderProfileImage,
  IconLink,
  TopHeaderContainer,
  UserInfo,
} from './HeaderComponents';
import {
  ExitIcon,
  HomeIcon,
  BenefitsIcon,
  RewardIcon,
  IndicateIcon,
  RingsIcon,
  UserIcon,
} from '../icons';
import HamburgerButton from '../HamburgerButton';
import { Backdrop } from '../styled';
import {
  DiamondHeaderIcon,
  ContractIcon,
  TrophyIcon,
  CalendarIcon,
  CalculatorIcon,
  AwardIcon,
} from '../icons/SupplierIcons';
import theme from '../../styles/theme';
import HeaderLinks from './HeaderLinks';
//import { navigate } from 'gatsby';
import { checkSupplierSelectedRegion } from '../../services/checkSession';

const SelectRegionSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RowContainer = styled.div`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CardTitle = styled.h3`
  color: ${theme.colors.grey3};
  display: flex;
  justify-content: center;
`;

const StyledDropdownContracts = styled(props => (
  <DropdownContracts {...props} />
))`
  color: ${theme.colors.grey3};
  display: flex;
  justify-content: center;
  font-size: 1.8em;
`;

const SupplierHeader = ({ session, logOut }) => {
  const [menuOpen, setMenu] = React.useState(false);
  const [regionModal, setRegionModal] = React.useState(false);
  const {
    userName,
    vbs,
    supplierName,
    supplierCategory,
    supplierLogo,
    supplierRegion,
    supplierContracts,
  } = session;
  const [
    ,
    /*{
      session: {
        userName,
        vbs,
        supplierName,
        supplierCategory,
        supplierLogo,
        supplierRegion,
      },
    },*/ dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (
      !session.userId &&
      (!session.supplierCategory || session.supplierCategory === 'undefined')
    ) {
      setRegionModal(true);
    } else {
      selectRegion(supplierContracts[supplierContracts?.length - 1]);
    }
    // eslint-disable-next-line
  }, [session]);

  const supplierMenuItems = [
    {
      label: 'Home',
      path: '/app/fornecedor',
      icon: <HomeIcon />,
      included: true,
      active: true,
    },
    {
      label: 'Benefícios',
      path: '/app/fornecedor/vantagens',
      icon: <BenefitsIcon />,
      active: true,
      included: true,
      visible:
        Number(supplierCategory) !== 4 &&
        supplierCategory !== null &&
        supplierCategory !== 'undefined',
    },
    {
      label: 'Inserir Noivos',
      path: '/app/fornecedor/convidar',
      icon: <IndicateIcon />,
      included: true,
    },
    {
      label: 'Orçamentos',
      path: '/app/fornecedor/oportunidades',
      icon: <CalculatorIcon />,
      active: true,
      included: true,
      visible:
        Number(supplierCategory) !== 4 &&
        supplierCategory !== null &&
        supplierCategory !== 'undefined',
    },
    {
      label: 'Noivos',
      path: '/app/fornecedor/noivos',
      icon: <RingsIcon />,
      included: true,
      visible:
        Number(supplierCategory) !== 4 &&
        supplierCategory !== null &&
        supplierCategory !== 'undefined',
    },
    {
      label: 'Pontuar',
      path: '/app/fornecedor/pontuar',
      icon: <AwardIcon />,
      included: true,
      visible:
        Number(supplierCategory) !== 4 &&
        supplierCategory !== null &&
        supplierCategory !== 'undefined',
    },
    {
      label: 'Contratos',
      path: '/app/fornecedor/contratos',
      icon: <ContractIcon />,
      active: true,
      included: true,
      visible:
        Number(supplierCategory) !== 4 &&
        supplierCategory !== null &&
        supplierCategory !== 'undefined',
    },
    {
      label: 'Agenda',
      path: '/app/fornecedor/agenda',
      icon: <CalendarIcon />,
      included: true,
      visible:
        Number(supplierCategory) !== 4 &&
        supplierCategory !== null &&
        supplierCategory !== 'undefined',
    },
    {
      label: 'Prêmios',
      path: '/app/fornecedor/premios',
      icon: <RewardIcon />,
      active: true,
      included: true,
      visible:
        Number(supplierCategory) !== 4 &&
        supplierCategory !== null &&
        supplierCategory !== 'undefined',
    },
    {
      label: 'Recompensas',
      path: '/app/fornecedor/recompensas',
      icon: <TrophyIcon />,
      included: true,
      visible: Number(supplierCategory) !== 4,
    },
    {
      label: 'Perfil',
      path: '/app/fornecedor/perfil',
      icon: <UserIcon />,
      included: true,
    },
  ];

  const toggleMenu = () => {
    if (menuOpen) {
      document.documentElement.classList.remove('html-modal_open');
    } else {
      document.documentElement.classList.add('html_modal-open');
    }
    setMenu(!menuOpen);
  };

  const selectRegion = contract => {
    if (contract && contract.codCategoriaContratoFornecedor) {
      const region = contract.codRegiao.Regiao;
      const city = region.substr(5);
      const category =
        contract.codCategoriaContratoFornecedor.codCategoriaContratoFornecedor;
      localStorage.setItem('supplierRegion', city);
      localStorage.setItem('supplierCategory', category);
      localStorage.setItem('supplierContract', contract.codContratoFornecedor);
      checkSupplierSelectedRegion(dispatch);
      setRegionModal(false);
    }
  };

  const onExitModal = () => {
    document.documentElement.classList.remove('html-modal_open');
    setMenu(false);
  };

  const setCategories = cat => {
    if (!cat || cat === 'undefined') {
      return '';
    }
    let numCat = Number(cat);
    if (numCat === 1) {
      return 'Bronze';
    }
    if (numCat === 2) {
      return 'Prata';
    }
    if (numCat === 3) {
      return 'Ouro';
    }
    if (numCat === 4) {
      return 'Apoio';
    }
  };

  const SelectRegionModal = ({ closeModal, opened }) => {
    const regionSelect = item => {
      selectRegion(item);
      closeModal();
    };
    return (
      <ModalContainer opened={opened} closeModal={closeModal} hasArabesco>
        <Wrapper>
          <CardTitle>Selecione a região onde deseja fazer login</CardTitle>
          <RowContainer style={{ justifyContent: 'center' }}>
            <StyledDropdownContracts
              onSelectItem={item => regionSelect(item)}
              placeholder="Selecione uma região"
              region
            />
          </RowContainer>
        </Wrapper>
      </ModalContainer>
    );
  };

  return (
    <>
      <SelectRegionModal
        opened={regionModal}
        closeModal={() => setRegionModal(false)}
      />
      {menuOpen && <Backdrop aria-hidden={true} onClick={toggleMenu} />}
      <HeaderWrapper menuOpen={menuOpen}>
        <TopHeaderContainer>
          <HeaderLogo path="/app/fornecedor" />
          <HamburgerButton
            onClick={toggleMenu}
            aria-label={`Botão para ${menuOpen ? 'fechar' : 'abrir'} o menu`}
            isOpen={menuOpen}
          />
          <HeaderNav aria-label={`Menu ${menuOpen ? 'aberto' : 'fechado'}`}>
            <HeaderProfileImage logo={supplierLogo} />
            <UserInfo>
              <div>
                <span className="title" aria-label="Nome da empresa">
                  {supplierName}
                </span>
              </div>
              <div>
                Usuário:
                <span> {userName}</span>
              </div>
              <div>
                Região:{' '}
                {supplierRegion && supplierRegion !== 'undefined' ? (
                  <>
                    <span>{supplierRegion}</span>
                    {'  '}({setCategories(supplierCategory)})
                    <SelectRegionSpan onClick={() => setRegionModal(true)}>
                      {' '}
                      Mudar
                    </SelectRegionSpan>
                  </>
                ) : (
                  <SelectRegionSpan onClick={() => setRegionModal(true)}>
                    Clique para selecionar.
                  </SelectRegionSpan>
                )}
              </div>
            </UserInfo>
            <UserInfo>
              <DiamondHeaderIcon /> Moedas:{' '}
              <span style={{ color: theme.colors.primary }}>{vbs}</span>
            </UserInfo>
            <IconLink icon={ExitIcon}>
              <button onClick={logOut}>Sair</button>
            </IconLink>
          </HeaderNav>
        </TopHeaderContainer>
        <HeaderLinks onExit={onExitModal} items={supplierMenuItems} />
      </HeaderWrapper>
    </>
  );
};

SupplierHeader.propTypes = {
  logOut: PropTypes.func.isRequired,
  session: PropTypes.shape({
    userName: PropTypes.string.isRequired,
    supplierName: PropTypes.string,
    vbs: PropTypes.number,
    supplierLogo: PropTypes.any,
  }),
};

export default SupplierHeader;
