import React from 'react';

import { AdvantagesCard } from '../../components';

const SupplierAdvantages = ({ advantages, category }) => {
  const cards = advantages.map(props => (
    <AdvantagesCard
      advantageData={props}
      key={props.codVantagem}
      category={category}
    />
  ));

  return <>{cards}</>;
};

export default SupplierAdvantages;
