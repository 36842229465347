import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.text_heading};
  margin-top: 2em;
  padding-top: 0.8em;
`;

const HalfOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  ${({ border }) =>
    border &&
    `
    border-top: 2px solid ${theme.colors.grey3};
  `}
`;

const PrizeText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSizeMed}px;
  margin: ${({ last }) => (last ? '0' : '1em')} 0 1em 0;
  color: ${theme.colors.white};
`;

const PrizeTotalText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSizeXlg}px;
  color: ${theme.colors.primary};
`;

const BoldValue = styled.strong`
  color: ${theme.colors.primary};
`;

export const CalendarReports = ({ confirmed, done, /*markedDates*/ }) => {
  return (
    <Wrapper>
      <HalfOverviewContainer>
        <PrizeText>
          Eventos confirmados: <BoldValue>{confirmed}</BoldValue>
        </PrizeText>
        <PrizeText last>
          Eventos realizados: <BoldValue>{done}</BoldValue>
        </PrizeText>
        {/* <PrizeText last>
          Eventos cancelados: <BoldValue>XXX</BoldValue>
        </PrizeText> */}
      </HalfOverviewContainer>
      <HalfOverviewContainer border>
        <PrizeTotalText>
          Total de eventos: <strong>{confirmed + done}</strong>
        </PrizeTotalText>
      </HalfOverviewContainer>{/*
      <HalfOverviewContainer>
        <PrizeText last>
          <em>Datas marcadas como disponíveis por você ou alguém da sua empresa:</em> {markedDates}
        </PrizeText>
      </HalfOverviewContainer>*/}
    </Wrapper>
  );
};

export default CalendarReports;
