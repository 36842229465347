import visibilityReducer from './visibilityReducer';
import searchbarReducer from './searchbarReducer';
import formsReducer from './formsReducer';
import sessionReducer from './sessionReducer';
import faqReducer from './faqReducer';
import aboutReducer from './aboutReducer';
import contactUsReducer from './contactUsReducer';
import generalDataReducer from './generalDataReducer';
import suppliersReducer from './suppliersReducer';
import segmentsReducer from './segmentsReducer';
import advantagesReducer from './advantagesReducer';
import eventsReducer from './eventsReducer';
import prizesReducer from './prizesReducer';

export default (
  {
    visibility,
    searchbar,
    forms,
    session,
    faq,
    about,
    contactUs,
    generalData,
    suppliers,
    segments,
    advantages,
    events,
    prizes
  },
  action
) => ({
  visibility: visibilityReducer(visibility, action),
  searchbar: searchbarReducer(searchbar, action),
  forms: formsReducer(forms, action),
  session: sessionReducer(session, action),
  faq: faqReducer(faq, action),
  about: aboutReducer(about, action),
  contactUs: contactUsReducer(contactUs, action),
  generalData: generalDataReducer(generalData, action),
  suppliers: suppliersReducer(suppliers, action),
  segments: segmentsReducer(segments, action),
  advantages: advantagesReducer(advantages, action),
  events: eventsReducer(events, action),
  prizes: prizesReducer(prizes, action),
});
