import React, { useEffect, useRef, useState } from 'react';
import FaChevronUp from 'react-icons/lib/fa/chevron-up';
import FaChevronDown from 'react-icons/lib/fa/chevron-down';

import { useStateValue } from '../state';
import styled from 'styled-components';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  position: absolute;
  width: 80%;
  right: 20%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  font-family: Lato;
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${theme.colors.white};
  @media ${medias.phoneMax} {
    margin-right: 18px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 10;
`;

const StyledListItem = styled.li`
  @media ${medias.laptopMin} {
    background-color: ${hexToRgb(theme.colors.secondary, 0.8)};
  }

  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-family: Lato;
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${theme.colors.white};
  list-style: none;
  margin: 0;
  padding: 0 0 0 5px;
  background-color: ${theme.colors.secondary};

  &:hover {
    color: white;
    background-color: ${theme.colors.primary};
    cursor: pointer;
  }
`;

const StyledIconUp = styled(FaChevronUp)`
  @media ${medias.phoneMax} {
    margin: 10px 5px 0 0;
  }
  margin-top: 10px;
`;

const StyledIconDown = styled(FaChevronDown)`
  @media ${medias.phoneMax} {
    margin: 10px 5px 0 0;
  }
  margin-top: 10px;
`;

const DropdownSegments = props => {
  const [
    {
      generalData: { segments },
    },
    dispatch,
  ] = useStateValue();
  useFetch({
    url: urls.siteContent.segments,
    method: method.GET,
    condition: () => (!segments ? true : false),
    callback: data =>
      dispatch({
        type: 'setSegments',
        newSegments: data,
      }),
  });

  const { onSelectItem, value, placeholder } = props;

  const node = useRef();
  const [listOpened, setListOpened] = useState(false);

  const handleClick = event => {
    if (node.current.contains(event.target)) {
      return;
    }

    setListOpened(false);
  };

  const handleChange = item => {
    onSelectItem(item);
    setListOpened(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <StyledContainer ref={node}>
      <StyledHeader onClick={() => setListOpened(!listOpened)}>
        {value || placeholder}
        {listOpened ? (
          <StyledIconUp color={theme.colors.primary} />
        ) : (
          <StyledIconDown color={theme.colors.primary} />
        )}
      </StyledHeader>

      {listOpened && (
        <StyledList>
          {segments
            ? segments.map(item => {
                const { Segmento, codSegmento } = item;
                return (
                  <StyledListItem
                    key={codSegmento}
                    onClick={() => handleChange(item)}
                  >
                    {Segmento}
                  </StyledListItem>
                );
              })
            : null}
        </StyledList>
      )}
    </StyledContainer>
  );
};

export default DropdownSegments;
