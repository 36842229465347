import React, { useState } from 'react';
import styled from 'styled-components';

import FormControl from '@material-ui/core/FormControl';
import {
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
} from '../components';

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const OrderBy = ({ onChangeOrder }) => {
  const [order, setOrderValue] = useState('eventDate');

  const handleChangeOnOrder = value => {
    setOrderValue(value);
    onChangeOrder(value);
  };

  return (
    <StyledFormControl>
      <MaterialInputLabel htmlFor="orderby">Ordenar Por</MaterialInputLabel>
      <MaterialSelect
        onChange={event => handleChangeOnOrder(event.target.value)}
        value={order}
        inputProps={{
          name: 'whoIs',
          id: 'orderby',
        }}
        white={true}
      >
        <MaterialMenuItem  value={'eventDate'}>
          Data
        </MaterialMenuItem>
        <MaterialMenuItem  value={'brideName'}>
          Nome ou e-mail do Noivo
        </MaterialMenuItem>
      </MaterialSelect>
    </StyledFormControl>
  );
};

export default OrderBy;
