import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useStateValue } from '../state';
import styled from 'styled-components';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import FaChevronUp from 'react-icons/lib/fa/chevron-up';
import FaChevronDown from 'react-icons/lib/fa/chevron-down';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';

const StyledContainer = styled.div`
@media ${medias.phoneMax}{
    width: ${props => props.isRegionPage ? '78%' : '75%' };
  };
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  position: absolute;
  width: ${props => props.isRegionPage ? '45%' : '75%' };
  right: ${props => props.isRegionPage ? '0' : '25%' };
  left: ${props => props.isRegionPage && '0'};
  margin: ${props => props.isRegionPage && '0 auto'};
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  font-family: Lato;
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${theme.colors.white};
  @media ${medias.phoneMax}{
    margin-right: 18px;
  };
  &:hover {
    cursor: pointer;
  }
  background-color: ${props => props.isRegionPage && 'rgba(4,7,7,0.8)' };
  padding: ${props => props.isRegionPage && '10px' };
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 10;
`;

const StyledListItem = styled.li`
  @media ${medias.laptopMin} {
    background-color: ${hexToRgb(theme.colors.secondary, .8)};
  }

  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-family: Lato;
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${theme.colors.white};
  list-style: none;
  margin: 0;
  padding: 0 0 0 5px;
  background-color: ${theme.colors.secondary};

  &:hover {
    color: white;
    background-color: ${theme.colors.primary};
    cursor: pointer;
  }
`;

const StyledIconUp = styled(FaChevronUp)`
  @media ${medias.phoneMax}{
    margin: 10px 5px 0 0;
  }
  margin-top: 10px;
`;

const StyledIconDown = styled(FaChevronDown)`
  @media ${medias.phoneMax}{
    margin: 10px 5px 0 0;
  }
  margin-top: 10px;
`;

const DropdownRegions = props => {
  const [{ generalData: { regions } ,
    events: { eventRegions }
  }, dispatch] = useStateValue();
  useFetch({
    url: urls.siteContent.regions,
    method: method.GET,
    condition: () => (!regions ? true : false),
    callback: data => dispatch({
      type: 'setRegions',
      newRegions: data,
    })
  });

  const { onSelectItem, value, placeholder, isRegionPage } = props;

  const node = useRef();
  const [listOpened, setListOpened] = useState(false);

  const handleClick = event => {
    if (node.current.contains(event.target)) {
      return;
    }

    setListOpened(false);
  };

  const handleChange = item => {
    onSelectItem(item);
    setListOpened(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <StyledContainer ref={node} isRegionPage={isRegionPage}>
      <StyledHeader isRegionPage={isRegionPage} onClick={() => setListOpened(!listOpened)}>
        {value || placeholder}
        {listOpened ? (
          <StyledIconUp color={theme.colors.primary} />
        ) : (
          <StyledIconDown color={theme.colors.primary} />
        )}
      </StyledHeader>

      {listOpened && (
        <StyledList>
          {
            eventRegions ? eventRegions.map(item => {
              const { Regiao, codRegiao } = item.codRegiao;
              return (
                <StyledListItem key={codRegiao} onClick={() => handleChange(Regiao)}>
                  {Regiao}
                </StyledListItem>
              );
            }) :
            (regions && !eventRegions) ? regions.map(item => {
              const { Regiao, codRegiao } = item;
              return (
                <StyledListItem key={codRegiao} onClick={() => handleChange(Regiao)}>
                  {Regiao}
                </StyledListItem>
              );
            })  : null
          }
        </StyledList>
      )}
    </StyledContainer>
  );
};

DropdownRegions.propTypes = {
  value: PropTypes.string.isRequired,
  onSelectItem: PropTypes.func.isRequired,
};

export default DropdownRegions;
