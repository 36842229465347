import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../styles/theme';

const shrink = keyframes`
  100% {
    transform: scaleY(.05)
  }
`;

const fadeIn = keyframes`
  100% {
    opacity: 1;
  }
`;

const Spinner = styled.div`
  font-size: .75rem;
  margin: 1em auto;
  height: 4em;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: ${fadeIn} .4s .1s ease forwards;
  div,
  ::after,
  ::before {
    content: '';
    width: 1em;
    height: 100%;
    margin: 0 .25em;
    background: ${theme.colors.primary};
    animation: ${shrink} .6s ease infinite alternate;
  }
  ::before {
    animation-delay: -.2s;
  }
  ::after {
    animation-delay: .2s;
  }
`;

const StyledP = styled.p`
  text-align: center;
`;

const Loader = ({ style }) => (
  <div>
    <StyledP>Por favor, aguarde...</StyledP>
    <Spinner style={style}>
      <div/>
    </Spinner>
  </div>
);

export default Loader;
