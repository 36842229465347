import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import { theme } from '../../styles/theme';
import { medias } from '../../utils/mediaQueries';
import { VdbIcon } from '../icons';
import { HeaderLinksWrapper } from './HeaderLinks';

export const TopHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: .75rem;

  @media ${medias.tabletMin} {
  padding: 1.25rem;
    height: 80px;
    border: 1px solid ${theme.colors.grey1};
  }
  @media ${medias.tabletMin} {
    .hamburger {
      display: none;
    }
  }
/* 
  ${HeaderDivider} {
    flex-basis: 100%;
  } */
`;

const StyledLogo = styled(VdbIcon)`
  fill: ${theme.colors.secondary};
  cursor: pointer;
  width: auto;
  height: ${props => props.isPublicSite ? '50px' : '30px'};

  @media ${medias.laptopMin} {
    height: 50px;
  }
`;

const NavItem = styled.a`
  flex: 1;
  cursor: pointer;
`;

export const HeaderLogo = ({ path, isPublicSite }) => (
  <NavItem onClick={e => { e.preventDefault(); navigate(path) }}>
    <StyledLogo centralized isPublicSite={isPublicSite}/>
  </NavItem>
);

export const HeaderNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${medias.phoneMax} {
    > span {
      font-size: 0.9em;
    }
  }
`;

const IconLinkWrapper = styled.span`
  font-size: 1em;
  svg {
    vertical-align: middle;
    width: 1em;
    margin-right: 0.25em;
  }
  a {
    color: ${theme.colors.text_heading};
  }
  button {
    text-decoration: underline;
  }
  @media ${medias.tabletMin} {
    font-size: 0.8em;
  }
`;

export const IconLink = ({ icon, children }) => (
  <IconLinkWrapper>
    {icon({})}
    {children}
  </IconLinkWrapper>
);

const ProfileImageWrapper = styled.div`
  font-size: 2.25em;
  flex-basis: 1em !important;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${theme.colors.grey2};
  svg {
    width: 50%;
  }
  svg,
  path {
    fill: ${theme.colors.grey2};
  }
`;

/*const Image = styled.img`
  max-width:100%;
  max-height:100%;
  object-fit: contain;
  padding: auto; 
`;
*/
export const HeaderProfileImage = ({ logo, icon }) => (
         <ProfileImageWrapper>
           {logo && <img src={logo} />}
           {icon && icon}
         </ProfileImageWrapper>
       );

export const HeaderDivider = styled.div`
  width: 100%;
  background-color: ${theme.colors.grey1};
  padding-top: 1px;
  margin: 5px 0px;
`;

export const UserInfo = styled.div`
  margin: 0 1.25rem 0 0.75rem;
  .title {
    font-weight: bold;
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizeLg}px;
  }
  > div {
    line-height: 1.5;
    color: ${theme.colors.grey3};
    font-size: ${theme.fontSize}px;
    > span {
      font-weight: bold;
    }
  }
  svg {
    width: 1.25em;
    fill: ${theme.colors.grey2};
  }
`;

// Estilos aplicados ao Header quando o menu estiver aberto
const headerModalStyles = `
  position: fixed;
  z-index: 100;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  padding: 1rem;
  border-radius: 10px;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  .hamburger {
    font-size: .25rem;
    position: absolute;
    top: 0;
    right: 0;
    color: ${theme.colors.error};
  }
  ${TopHeaderContainer} {
    padding: 0;
    > a {
      display: none;
    }
  }
  ${HeaderNav} {
    flex-wrap: wrap;
    justify-content: center;
    flex-basis: 100%;
    > * {
      flex-basis: 100%;
    }
    // Botão de "Sair"
    > span {
      margin-top: 1.5rem;
    }
  }
  ${HeaderLinksWrapper} {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin-top: 1.5rem;
  }
  > * {
    flex-basis: 100%;
  }
  > span {
    margin-top: 1rem;
  }
`;

export const HeaderWrapper = styled.header`
  width: 100%;
  z-index: 2;
  box-shadow: 0px 4px 8px 0 rgba(205, 204, 204, 0.3);
  background-color: ${theme.colors.white};
  @media ${medias.laptopMin} {
    position: fixed;
    top: 0;
    left: 0;
  }
  @media ${medias.tabletMin} {
    .hamburger {
      display: none;
    }
  }
  @media ${medias.tabletMax} {
    ${({ menuOpen }) =>
      menuOpen
        ? headerModalStyles
        : `
          ${HeaderNav}, ${HeaderLinksWrapper} {
            display: none;
          }
          `}
  }
`;
