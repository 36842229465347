import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';
import { Button } from './index';
import { medias } from '../utils/mediaQueries';
import ModalContainer, { ModalTitle } from './ModalContainer';
import { MaterialInput } from '../components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  @media ${medias.phoneMax} {
    font-size: 11px;
  }

  border: 2px solid ${theme.colors.text_heading};
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  width: 45%;
  height: 1.8em;
  padding: 0;
`;

const RowContainer = styled.div`
  @media ${medias.phoneMax} {
    align-items: baseline;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 1em 0 0.5em 0;
`;

const UpdateUserModal = ({
  closeModal,
  opened,
  onClickConfirm,
  rule,
  userToUpdate,
  codUserLoggedIn,
}) => {
  const [form, setForm] = useState({
    codUser: userToUpdate.codUser,
    name: userToUpdate.name,
    email: userToUpdate.email,
    currentPassword: null,
    newPassword: null,
    password2: null,
  });

  const updateField = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const clickConfirm = () => {
    onClickConfirm(form);
    closeModal();
  };

  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco>
      <Wrapper>
        <ModalTitle>Atualizar Usuário</ModalTitle>
        <MaterialInput
          style={{ width: '100%' }}
          label="Nome"
          name="name"
          value={form.name}
          onChange={updateField}
        />
        <RowContainer style={{ width: '100%' }}>
          <MaterialInput
            style={{ width: '100%', marginRight: '20px' }}
            label="E-mail"
            name="email"
            value={form.email}
            onChange={updateField}
          />

          {/*<MaterialInput
            style={{ width: '50%' }}
            label="Senha"
            name="password"
            value={form.password}
            onChange={updateField}
            disabled={
              Number(rule) === 2 && Number(codUserLoggedIn) !== form.codUser
            }
          />*/}
        </RowContainer>
        <ModalTitle>Alterar senha</ModalTitle>
        {Number(rule) !== 1 ? (
          <RowContainer>
            <MaterialInput
              style={{ width: '40%' }}
              type="password"
              label="Senha atual"
              name="currentPassword"
              value={form.currentPassword}
              onChange={updateField}
              disabled={
                Number(rule) === 2 && Number(codUserLoggedIn) !== form.codUser
              }
              //{...password('currentPassword')}
            />
          </RowContainer>
        ) : (
          ''
        )}
        <RowContainer>
          <MaterialInput
            style={{ width: '40%' }}
            type="password"
            label="Nova senha"
            name="newPassword"
            value={form.newPassword}
            onChange={updateField}
            disabled={
              Number(rule) === 2 && Number(codUserLoggedIn) !== form.codUser
            }
          />
          <MaterialInput
            style={{ width: '40%' }}
            type="password"
            label="Confirmar senha"
            name="password2"
            value={form.password2}
            onChange={updateField}
            disabled={
              Number(rule) === 2 && Number(codUserLoggedIn) !== form.codUser
            }
          />
        </RowContainer>
        {/*{differentPassword && <p>As senhas estão diferentes</p>}
        {invalidPassword && <p>Senha incorreta</p>}
        {changePasswordError && <p>Erro ao alterar a senha</p>}
        {changePasswordSuccess && <p>Senha alterada</p>}
          */}
        <RowContainer>
          <StyledButton text="ATUALIZAR" click={() => clickConfirm()} />
          <StyledButton text="CANCELAR" click={() => closeModal()} />
        </RowContainer>
      </Wrapper>
    </ModalContainer>
  );
};

UpdateUserModal.propTypes = {
  disabled: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
};

UpdateUserModal.defaultProps = {
  disabled: false,
};

export default UpdateUserModal;
