import { useState, useEffect } from 'react';
import to from './utils/to';
import * as request from './utils/request';

let _baseUrl;
let _token;

export const method = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'destroy',
};

export const setApi = baseUrl => {
  _baseUrl = baseUrl;
};

export const setToken = token => {
  _token = token;
};

export default ({
  method = method.GET,
  payload = undefined,
  url = undefined,
  condition = () => true,
  callback = () => true,
}) => {
  if (!_baseUrl) {
    throw new Error('Base URL não setada.');
  }
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const didUnmount = () => {
    return;
  };

  const didUpdate = () => {
    const fetchData = async () => {
      setIsError(false);

      const requestParams = {
        url: `${_baseUrl}${url}`,
        payload,
        token: _token,
      };

      const [data, err] = await to(request[method](requestParams));

      if (err) {
        setIsError(true);
      }
      setData(data);
      setIsLoading(false);
      callback(data, err);
    };

    if (condition()) {
      fetchData();
    }
    return didUnmount;
  };

  const doUpdate = args => {
    setUpdated(!isUpdated);
    return args;
  };

  useEffect(didUpdate, [isUpdated]);

  return Object.assign([data, isLoading, isError, doUpdate], {
    data,
    isLoading,
    isError,
    doUpdate,
  });
};
