import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';
import CardContainer from './CardContainer';
import {
  DiamondIcon,
  ContractIcon,
  TwoWayIcon,
  CycleIcon,
  AwardIcon,
  MoneyBagIcon,
} from './icons/SupplierIcons';
import HowToEarnModal from './HowToEarnModal';

const dimensionInfo = {
  vbs: {
    label: 'Moedas',
    icon: DiamondIcon,
  },
  potential: {
    label: 'Negócios Gerados',
    icon: TwoWayIcon,
  },
  contracts: {
    label: 'Contratos Fechados',
    icon: ContractIcon,
  },
  deals: {
    label: 'Negócios Fechados',
    icon: MoneyBagIcon,
  },
  roi: {
    label: 'ROI',
    icon: CycleIcon,
  },
  points: {
    label: 'Pts. a Distribuir',
    icon: AwardIcon,
  },
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  justify-content: center;
  grid-gap: 2rem 1rem;
`;

const Dimension = styled.div`
  text-align: center;
  svg {
    height: 1.5em;
    fill: ${theme.colors.grey2};
  }
  h3 {
    margin: 0.5rem 0 0.25rem;
    font-weight: 400;
    color: ${theme.colors.grey3};
    .title {
      display: block;
      font-size: 1.2em;
      font-weight: 600;
      color: ${theme.colors.primary};
    }
  }
  button {
    color: ${theme.colors.primary};
  }
`;

const Subscript = styled.span`
  font-size: 0.7em;
`;

const SupplierReportCard = ({ supplierData }) => {
  const [modalOpen, setModal] = React.useState(false);

  // console.log('supplierData', supplierData)

  const toggleModal = () => {
    setModal(!modalOpen);
  };
  return (
    <>
      <HowToEarnModal opened={modalOpen} closeModal={toggleModal} />
      <CardContainer>
        <Wrapper>
          {supplierData &&
            Object.keys(supplierData).map(k => {
              let value = supplierData[k];
              const info = dimensionInfo[k];
              // 5/11/19: atualizado para valor real em decimal (ex: 1,5x)
              if (k === 'roi') {
                if (value) {
                  value = value.toString();
                  value = value.slice(0, (value.indexOf(".")) + 2);
                }
              }

              return (
                <Dimension key={info.label}>
                  <div>{info.icon({})}</div>
                  <h3>
                    <span className="title">
                      {k === ('deals') && <Subscript>R$</Subscript>}
                      {k === ('potential') && <Subscript>R$</Subscript>}
                      {typeof value === 'number'
                        ? value.toLocaleString('pt')
                        : value}
                      {k === 'roi' && <Subscript>x</Subscript>}
                    </span>
                    {info.label}
                  </h3>
                  {k === 'vb' && (
                    <button onClick={toggleModal}>Veja como ganhar</button>
                  )}
                </Dimension>
              );
            })}
        </Wrapper>
      </CardContainer>
    </>
  );
};

SupplierReportCard.propTypes = {
  supplierData: PropTypes.shape({
    vbs: PropTypes.number.isRequired,
    potential: PropTypes.number.isRequired,
    contracts: PropTypes.number.isRequired,
    deals: PropTypes.number.isRequired,
    roi: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired,
  }),
};

export default SupplierReportCard;
