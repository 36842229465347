import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import FormControl from '@material-ui/core/FormControl';

import { ClipboardIcon } from '../components/icons';
import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import {
  Button,
  RequestBudgetForm,
  Tabs,
  RestrictAccessModal,
  Loader,
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialInput,
  ConfirmModal,
} from '../components';
import { StyledHeart, StyledOutline } from './styled';
import { PortfolioCard, SupplierAdvantages } from '../containers';
import { useStateValue } from '../state';
import { medias } from '../utils/mediaQueries';
import Arabesco from '../images/arabescos.svg';
import { bride } from '../services/api';
import { CenteredText } from '../components/pageComponents';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-bottom: 1em;
  width: 100%;

  background-color: ${theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  border-radius: 10px;
  ${({ bottom }) => (bottom ? 'margin-top: 1em;' : 'margin-bottom: 1em;')}
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  @media ${medias.phoneMax} {
    align-items: flex-start;
    margin: 0px;
    width: 35%;
  }
`;

const SegmentContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 50%;
  }

  display: flex;
  width: 30%;
  align-items: center;
  justify-content: flex-start;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 75%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2em;
  padding: 1em;
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
    height: 41px;
    padding: 0 2em;
  }

  font-size: ${theme.fontSizeSm}px;
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 40%;
  margin: 0 1em;
`;

const FullButton = styled(props => <Button {...props} />)`
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 100%;
  margin: 0 1em ${({ confirm }) => (confirm ? '1em' : '0')} 1em;
`;

const CardTitle = styled.p`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  text-align: left;
`;

const CardText = styled.p`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize}px;
  font-weight: 300;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    width: 40%;
  }

  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 0.5em;
  word-wrap: break-word;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-size: ${theme.fontSizeSm}px;
  width: 90%;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const StyledTabs = styled(props => <Tabs {...props} />)`
  margin-top: 2em;
  width: 90%;
`;

const StyledClipboardIcon = styled(props => <ClipboardIcon {...props} />)`
  margin-right: 10px;
`;

const RadioSection = styled.fieldset`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  width: 90%;
  padding: 0;
  margin: 1em 0;
  @media ${medias.phoneMax} {
    flex-direction: column;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5em;
`;

const RadioInput = styled.input`
  margin: 0;
  margin-right: 0.3em;
`;

const RadioLabel = styled.label`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin: 0;
`;

const ContactLabel = styled.span`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  margin-right: 1em;
`;

const StyledFormControl = styled(props => <FormControl {...props} />)`
  width: 90%;
`;

const tabsContent = [
  {
    id: 0,
    title: 'BENEFÍCIOS',
  },
  {
    id: 1,
    title: 'PORTIFÓLIO',
  },
];

const SupplierCard = ({ supplierData, app, removeFavorite, addFavorite }) => {
  const [restrictVisible, setRestrictVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [opened, setOpened] = useState(false);
  const [favorited, setFavorited] = useState(supplierData.isFavorited);
  const [advantages, setAdvantages] = useState([]);
  const [isSegmentsClosed, setIsSegmentsClosed] = useState(false);
  const [contact, setContact] = useState(0);
  const [message, setMessage] = useState('');
  const [segment, setSegment] = useState('');
  const [alreadyRequestBudget, setAlreadyRequestBudget] = useState(false);
  const [porfolioData, setPortfolioData] = useState(null);
  const [budgetNumber, setBudgetNumber] = useState(0);

  const [
    {
      session: { category, selectedEvent },
    },
  ] = useStateValue();

  useEffect(() => {
    const getFavorite = async () => {
      const response = await bride.getFavoriteEvent(
        supplierData.codFornecedor,
        selectedEvent
      );

      setFavorited(response.data.isFavorite);
    };

    const fetchSupplierAdvantages = async () => {
      const response = await bride.getSupplierAdvantages(
        selectedEvent,
        supplierData.codFornecedor
      );

      setAdvantages(response.data.advantages);
      setIsSegmentsClosed(response.data.brideClosedAllSupplierSegments);
    };

    if (app) {
      getFavorite();
      fetchSupplierAdvantages();
    }
  }, [app, selectedEvent, supplierData.codFornecedor]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await bride.getSupplierPortfolio(
        supplierData.codFornecedor
      );

      if (response.ok && response.data) {
        setPortfolioData(response.data);
      }
    };

    if (isLoggedIn) {
      fetchData();
    }
  }, [isLoggedIn, supplierData.codFornecedor]);

  const { nomeDivulgacao, codFornecedor, resumo, segmentos } = supplierData;
  const isLoggedIn = !!localStorage.getItem('token');

  const handleData = () => {
    let filteredAdvantages = [];
    if (advantages.length > 0) {
      filteredAdvantages = advantages.filter(
        ({ isSegmentOpened, showAdvantage }) => isSegmentOpened && showAdvantage
      );
    }

    return [
      isSegmentsClosed ? (
        <CenteredText>
          Benefícios não disponíveis para segmento já fechado.
        </CenteredText>
      ) : filteredAdvantages.length > 0 ? (
        <SupplierAdvantages
          key={0}
          advantages={filteredAdvantages}
          category={category}
        />
      ) : (
        <CenteredText>Nenhum benefício disponivel</CenteredText>
      ),
      <PortfolioCard key={1} content={porfolioData} />,
    ];
  };

  const renderButtons = () => {
    if (!isLoggedIn) {
      return (
        <ButtonsContainer>
          <HalfButton
            text="BENEFÍCIOS EXCLUSIVOS"
            click={() => setRestrictVisible(!restrictVisible)}
            selected={restrictVisible}
          />
          <HalfButton
            text="ORÇAMENTO"
            click={() => setFormVisible(!formVisible)}
            selected={formVisible}
          />
        </ButtonsContainer>
      );
    } else {
      return (
        <FullButton
          text="DETALHES E BENEFÍCIOS"
          click={() => setOpened(!opened)}
          selected={opened}
        />
      );
    }
  };

  const budgetRequest = async () => {
    const body = {
      userId: Number(localStorage.userId),
      event: Number(selectedEvent),
      contact,
      message,
      supplierName: nomeDivulgacao,
      chosenSegment: segment,
      budgetNumber,
    };

    const response = await bride.brideSupplierBudget(body);

    response.ok && response.data
      ? toast.error('Orçamento solicitado com sucesso!')
      : toast.error('Não foi possivel solicitar o orçamento!');
  };

  const onClickConfirm = async () => {
    const event = Number(selectedEvent);
    const check = await bride.brideSupplierBudgetCheck(
      event,
      nomeDivulgacao,
      segment
    );
    const lastBugdet = await bride.checkLastBudgetToday();

    const today = new Date();
    const init = 1;
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate() + 1).padStart(2, '0');
    const newBudgetNumber =
      today.getFullYear() + month + day + String(init).padStart(4, '0');

    lastBugdet.data
      ? setBudgetNumber(lastBugdet.data.num + 1)
      : setBudgetNumber(newBudgetNumber);

    !check.ok || !check.data ? budgetRequest() : setAlreadyRequestBudget(true);
  };

  return (
    <Wrapper>
      <RestrictAccessModal
        onClickClose={() => setRestrictVisible(false)}
        text="Estes benefícios estão disponíveis apenas para noivos que já fazem parte da Vestidas de Branco! Quer saber como fazer parte e ter acesso a condições imperdíveis com nossos fornecedores? Clique no botão abaixo!."
        buttonText="FAÇA PARTE"
        link="/facaparte/noivo"
        opened={restrictVisible}
      />
      <AssetContainer />
      <TopContainer>
        <IconContainer>
          <StyledClipboardIcon />
        </IconContainer>
        <TextContainer>
          <CardTitle>{nomeDivulgacao}</CardTitle>
          <CardText>{resumo}</CardText>
        </TextContainer>
        {app &&
          (favorited ? (
            <StyledHeart
              onClick={() => {
                setFavorited(!favorited);
                removeFavorite(codFornecedor, 'supplier');
              }}
            />
          ) : (
            <StyledOutline
              onClick={() => {
                setFavorited(!favorited);
                addFavorite(codFornecedor, 'supplier');
              }}
            />
          ))}
      </TopContainer>
      <IconsContainer>
        {segmentos.length > 0 &&
          segmentos.map(item =>
            item ? (
              <SegmentContainer key={item.codSegmento}>
                <StyledIcon src={item.linkIcone} />
                <ItemText>{item.Segmento}</ItemText>
              </SegmentContainer>
            ) : (
              ''
            )
          )}
      </IconsContainer>
      <ConfirmModal
        opened={alreadyRequestBudget}
        marginTop={'400px'}
        closeModal={() => setAlreadyRequestBudget(false)}
        onClickConfirm={() => budgetRequest()}
        text="Você já mandou um orçamento para esse fornecedor, deseja enviar outro?"
      />
      {isLoggedIn && (
        <>
          <p>Solicitar orçamento</p>
          <MaterialInput
            style={{ width: '90%' }}
            label="Mensagem"
            value={message}
            onChange={event => setMessage(event.target.value)}
          />
          <StyledFormControl>
            <MaterialInputLabel htmlFor="codSegment">
              Segmento
            </MaterialInputLabel>
            <MaterialSelect
              style={{ width: '100%' }}
              name="codSegment"
              onChange={event => setSegment(event.target.value)}
              value={segment}
              inputProps={{
                name: 'codSegment',
                id: 'codSegment',
              }}
            >
              {segmentos.length > 0 &&
                segmentos.map(item =>
                  item ? (
                    <MaterialMenuItem
                      key={item.codSegmento}
                      value={item.codSegmento}
                    >
                      {' '}
                      {item.Segmento}{' '}
                    </MaterialMenuItem>
                  ) : (
                    ''
                  )
                )}
            </MaterialSelect>
          </StyledFormControl>
          <RadioSection>
            <ContactLabel>Quero ser contactado:</ContactLabel>
            <RadioGroup>
              <RadioInput
                type="radio"
                onChange={() => setContact(3)}
                value="whatsapp"
                checked={contact === 3}
              />
              <RadioLabel>Whatsapp</RadioLabel>
            </RadioGroup>
            <RadioGroup>
              <RadioInput
                type="radio"
                onChange={() => setContact(1)}
                value="phone"
                checked={contact === 1}
              />
              <RadioLabel>Telefone</RadioLabel>
            </RadioGroup>
            <RadioGroup>
              <RadioInput
                type="radio"
                onChange={() => setContact(2)}
                value="mail"
                checked={contact === 2}
              />
              <RadioLabel>E-mail</RadioLabel>
            </RadioGroup>
          </RadioSection>
          <ButtonsContainer>
            <FullButton
              confirm
              text="CONFIRMAR"
              click={() => onClickConfirm()}
            />
          </ButtonsContainer>
        </>
      )}
      <ButtonsContainer>{renderButtons()}</ButtonsContainer>
      {formVisible && (
        <RequestBudgetForm
          onCancel={() => setFormVisible(false)}
          supplierCod={supplierData.codFornecedor}
        />
      )}
      {opened &&
        (advantages ? (
          <StyledTabs
            content={tabsContent}
            onClick={() => {}}
            components={handleData()}
            lineWidth={'90%'}
          />
        ) : (
          <Loader />
        ))}
      <AssetContainer bottom />
    </Wrapper>
  );
};

SupplierCard.propTypes = {
  supplierData: PropTypes.shape({
    nomeDivulgacao: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icons: PropTypes.arrayOf(PropTypes.node),
    img: PropTypes.string,
    isFavorite: PropTypes.bool,
  }),
};

export default SupplierCard;
