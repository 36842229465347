import produce from 'immer';

export default (faq, action) => {
  // ----> Logs
  // console.log('Called faq reducer');
  // console.log(`Called action type ${action.type}`);
  // console.log('Prev state:');
  // console.table(faq);
  // console.log('Action payload:');
  // console.table(action);

  switch (action.type) {
    case 'setFaqs':
      return produce(faq, draft => {
        draft.faqs = action.newFaqs;
      });

    default:
      return faq;
  }
};
