import React from 'react';
import styled from 'styled-components';
import { MaterialInput } from './';
import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';

const ItemTitle = styled.h3`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin-top: 0.6em;
`;

const SupplierInsertVideo = ({ form, updateField, checkVideoLink }) => {
  return (
    <>
      <ItemTitle>Inserir novo vídeo</ItemTitle>
      <div style={{ width: '85%' }}>
        <MaterialInput
          label="Insira um link completo do Youtube ou Vimeo"
          name="video"
          value={form.video}
          onChange={updateField}
        />
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => checkVideoLink(form.video)}
        >
          Enviar
        </span>
      </div>
    </>
  );
};

export default SupplierInsertVideo;
