import produce from 'immer';

export default (about, action) => {
  // ----> Logs
  // console.log('Called about reducer');
  // console.log(`Called action type ${action.type}`);
  // console.log('Prev state:');
  // console.table(about);
  // console.log('Action payload:');
  // console.table(action);

  switch (action.type) {
    case 'setTestimonials':
      return produce(about, draft => {
        draft.testimonials = action.newTestimonials;
      });

    default:
      return about;
  }
};
