import produce from 'immer';

export default (form, action) => {
  switch (action.type) {
    case 'changeBrideForm':
      return produce(form, draft => {
        draft.bridesForm[action.field] = action.newText;
      });

    case 'changeLoginForm':
      return produce(form, draft => {
        draft.loginForm[action.field] = action.newText;
      });

    case 'changeSupplierForm':
      return produce(form, draft => {
        draft.supplierForm[action.field] = action.newText;
      });

    case 'changeFranchiseForm':
      return produce(form, draft => {
        draft.franchiseForm[action.field] = action.newText;
      });

    case 'changeContactForm':
      return produce(form, draft => {
        draft.contactForm[action.field] = action.newText;
      });

      case 'clearContactForm':
        return {
          ...form,
          contactForm: {
            ...form.contactForm,
            subject: '',
            whoIs: '',
            region: '',
            name: '',
            email: '',
            message: '',
          }
        };

    case 'changeBudgetForm':
      return produce(form, draft => {
        draft.requestBudgetForm[action.field] = action.newText;
      });

    case 'changeBrideWelcomeForm':
      return produce(form, draft => {
        draft.brideWelcomeForm[action.field] = action.newText;
      });

    case 'setBrideDataForm':
      return produce(form, draft => {
        Object.keys(action.newForm)
          .forEach(key => draft.brideWelcomeForm[key] = action.newForm[key])
      });

    case 'changeReferForm':
      return produce(form, draft => {
        draft.referForm[action.field] = action.newText;
      });

    case 'changeProfileForm':
      return produce(form, draft => {
        draft.profileForm[action.field] = action.newText;
      });

      case 'setProfileDataForm':
      return produce(form, draft => {
        Object.keys(action.newForm)
          .forEach(key => draft.profileForm[key] = action.newForm[key])
      });

    default:
      return form;
  }
};
