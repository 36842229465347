import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { theme } from '../styles/theme';
import { Button } from '../components/';
import ModalContainer, { ModalTitle } from './ModalContainer';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  font-size: ${theme.fontSizeLg * 0.9}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 20px 0;
`;

const StyledButton = styled(Button)`
  font-size: ${theme.fontSizeSm}px;
  font-weight: 500;
  color: ${theme.colors.error};
  border: 1px solid ${theme.colors.error};
  margin-bottom: 0.75em;
  margin-left: 5%;
  width: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RestrictAcessModal = ({
  onClickClose,
  text,
  link,
  buttonText,
  opened,
}) => {
  return (
    <ModalContainer
      opened={opened}
      closeModal={onClickClose}
      hasArabesco={true}
    >
      <Wrapper>
        <ModalTitle>ACESSO RESTRITO</ModalTitle>
        <Text>{text}</Text>
        <StyledLink to={link}>
          <StyledButton text={buttonText} click={onClickClose} />
        </StyledLink>
      </Wrapper>
    </ModalContainer>
  );
};

export default RestrictAcessModal;
