import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { MissionIcon, VisionIcon, ValuesIcon } from './icons';

const Card = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 50px 0 50px 0;

  background-color: ${theme.colors.grey1};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  margin: 40px 0 0 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 20px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  @media ${medias.phoneMax} {
    font-size: 1.2em;
  }

  color: ${theme.colors.grey3};
  margin: 0;
`;

const ItemTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06};
  color: ${theme.colors.text_heading};
  margin: 0 0 15px 0;
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
  }

  font-size: ${theme.fontSizeLg * 0.9};
  color: ${theme.colors.grey3};
  margin: 0;
`;

const ListText = styled(ItemText)`
  @media ${medias.phoneMax} {
    font-size: 0.49em;
  }

  margin: 0 20px 5px 0;
`;

const GuidelinesCard = () => {
  return (
    <Card>
      <Title>Missão, visão e valores</Title>
      <ItemContainer>
        <div>
          <MissionIcon />
        </div>
        <TextContainer>
          <ItemTitle>Missão</ItemTitle>
          <ItemText>
            Gerar bons negócios para os Fornecedores e Noivos da Vestidas de
            Branco.
          </ItemText>
        </TextContainer>
      </ItemContainer>
      <ItemContainer>
        <div>
          <VisionIcon />
        </div>
        <TextContainer>
          <ItemTitle>Visão</ItemTitle>
          <ItemText>
            Ser a melhor ferramenta de geração de negócios para nossos
            fornecedores e a fonte mais confiável de indicação e geração de
            benefícios para os noivos.
          </ItemText>
        </TextContainer>
      </ItemContainer>
      <ItemContainer>
        <div>
          <ValuesIcon />
        </div>
        <TextContainer>
          <ItemTitle>Valores</ItemTitle>
          <ListContainer>
            <ItemList>
              <ListText>- Transparência</ListText>
              <ListText>- Integridade</ListText>
              <ListText>- Comprometimento</ListText>
            </ItemList>
            <ItemList>
              <ListText>- Inovação</ListText>
              <ListText>- Melhoria Contínua</ListText>
            </ItemList>
          </ListContainer>
        </TextContainer>
      </ItemContainer>
    </Card>
  );
};

export default GuidelinesCard;
