import React, { useState } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useStaticQuery, graphql } from 'gatsby';

import { CardContainer } from './';
import {
  MaterialInput,
  MaterialDatePicker,
  MaterialMenuItem,
  MaterialSelect,
  Loader,
  MoneyMask,
} from '../components';
import { CheckListIconGreen, RedExitIcon } from '../components/icons';
import { StyledButton } from '../components/styled';
import theme from '../styles/theme';
import { supplier } from '../services/api';
import { useStateValue } from '../state';
import { urls } from '../services/urls';
import useFetch, { method } from '../hooks/useFetch';
import { siteContent } from '../services/api';
import phoneMask from '../utils/phoneMask';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 100%;
  }
  .half {
    flex-basis: 45%;
  }
  button[type='submit'] {
    background-color: ${theme.colors.text_heading};
    color: white;
    padding: 0.5em 1em;
    margin-top: 2rem;
  }
`;

const Row = styled.div`
  align-self: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const HalfInput = styled(props => <MaterialInput {...props} />)`
  flex-basis: 45%;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledLabel = styled.label`
  color: ${theme.colors.grey3};
  font-size: ${theme.fontSizeSm}px;
  margin: 0;
  text-align: left;
`;

const IconWrap = styled.div`
  margin-top: 3%;
  margin-right: 6%;
`;

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

export const SupplierContractForm = () => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [city, setCity] = useState(null);
  const [region, setRegion] = useState(null);
  const [value, setValue] = useState(null);
  const [segment, setSegment] = useState(null);
  const [dateUndefined, setDateUndefined] = useState(false);

  const [
    {
      generalData: { states },
    },
    dispatch,
  ] = useStateValue();

  const [weddingDate, setDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);
  const [availableCities, setAvailableCities] = React.useState(null);

  const [emailInputUsed, setEmailInputUsed] = React.useState(false);
  const [foundUserEmail, setFoundUserEmail] = React.useState(false);
  const [emailLoading, setEmailLoading] = React.useState(false);

  const data = useStaticQuery(graphql`
    {
      regions: allRegion {
        edges {
          node {
            Regiao
            codRegiao
          }
        }
      }
      segments: allSegments {
        edges {
          node {
            Segmento
            codSegmento
          }
        }
      }
    }
  `);
  const [segments, setSegments] = React.useState(data.segments.edges);

  const getSegments = async () => {
    const s = await supplier.getSegments();
    if (s.ok && s.data.segmentCodes) {
      setSegments(
        segments.filter(({ node }) =>
          s.data.segmentCodes.includes(node.codSegmento)
        )
      );
    }
  };

  React.useEffect(() => {
    getSegments();
    // eslint-disable-next-line
  }, []);

  const fetchCities = async codUf => {
    const response = await siteContent.cities(codUf);
    response.ok && response.data
      ? setAvailableCities(response.data)
      : toast.error('Não foi possivel recuperar as cidades!');
  };

  useFetch({
    url: urls.siteContent.states,
    method: method.GET,
    condition: () => (!states ? true : false),
    callback: data =>
      dispatch({
        type: 'setStates',
        newStates: data,
      }),
  });

  const generateContractExistentUser = async () => {
    if (!weddingDate || !region || !segment || !value) {
      toast.error('Preencha todos os campos!');
      setLoading(false);
      return;
    }
    const form = {
      name,
      value,
      email,
      region,
      phone,
      city,
      codRegion: region,
      codSegment: segment,
      weddingDate: moment(weddingDate).format('YYYY-MM-DD'),
    };
    const showSuccessMsgAndReload = () => {
      toast.success('Tudo ok! Seu contrato foi enviado.');
      setTimeout(function() {
        window.location.reload();
      }, 2800);
    };
    const res = await supplier.inviteBrides(form);
    res.ok
      ? showSuccessMsgAndReload()
      : res.data.message === 'Saldo Insuficiente'
      ? toast.error('Você não possui saldo suficiente')
      : toast.error('Houve um problema com seu envio, tente novamente!');
  };

  const generateContractNewUser = async () => {
    if (!weddingDate || !region || !segment || !value) {
      toast.error('Preencha todos os campos!');
      setLoading(false);
      return;
    }

    const form = {
      name,
      value,
      email,
      region,
      phone,
      city,
      codRegion: region,
      codSegment: segment,
      weddingDate: dateUndefined
        ? null
        : moment(weddingDate).format('YYYY-MM-DD'),
    };

    const res = await supplier.inviteBrides(form);
    res.data || res.data.error
      ? toast.success('Cadastro submetido com sucesso!', {
          onClose: () => window.location.reload(),
        })
      : toast.error('Houve um problema com seu envio, tente novamente!');
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (foundUserEmail) {
      await generateContractExistentUser();
    } else {
      await generateContractNewUser();
    }
    setLoading(false);
  };

  const onEmailEntered = enteredEmail => {
    setEmailInputUsed(true);
    setEmailLoading(true);
    checkIfEmailIsRegistered(enteredEmail);
  };

  const checkIfEmailIsRegistered = async email => {
    const response = await supplier.checkUserEmail(email);

    if (response.ok && response.data) {
      setFoundUserEmail(response.data.isRegistered);
    }

    setEmailLoading(false);
  };

  const onRegionChange = value => {
    fetchCities(value);
    setRegion(value);
  };

  return (
    <CardContainer>
      <Form>
        <Row style={{ marginLeft: '10%' }}>
          <MaterialInput
            style={{ width: '90%' }}
            disabled={loading}
            value={email}
            onBlur={e => onEmailEntered(e.target.value)}
            onChange={e => setEmail(e.target.value)}
            required
            label="E-mail"
          />
          {emailInputUsed &&
            (emailLoading ? (
              <Loader />
            ) : foundUserEmail ? (
              <IconWrap>
                <CheckListIconGreen />
              </IconWrap>
            ) : (
              <IconWrap>
                <RedExitIcon />
              </IconWrap>
            ))}
        </Row>
        {emailInputUsed &&
          !emailLoading &&
          !foundUserEmail && (
            <>
              <Row style={{ marginRight: '10%', marginLeft: '10%' }}>
                <HalfInput
                  disabled={loading}
                  onChange={e => setName(e.target.value)}
                  value={name}
                  required
                  label="Nome do cliente"
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Telefone"
                  required
                  value={phone}
                  disabled={loading}
                  onChange={event => setPhone(phoneMask(event.target.value))}
                />
              </Row>
              <Row style={{ marginRight: '10%', marginLeft: '10%' }}>
                <StyledFormControl style={{ width: '45%' }}>
                  <StyledLabel>Estado</StyledLabel>
                  <MaterialSelect
                    disabled={loading}
                    onChange={e => onRegionChange(e.target.value)}
                    value={region}
                    inputProps={{
                      name: 'uf',
                      id: 'uf',
                    }}
                  >
                    {states
                      ? states.map(({ name, cod }) => (
                          <MaterialMenuItem key={cod} value={cod}>
                            {name}
                          </MaterialMenuItem>
                        ))
                      : null}
                  </MaterialSelect>
                </StyledFormControl>
                <StyledFormControl style={{ width: '45%' }}>
                  <StyledLabel>Cidade</StyledLabel>
                  <MaterialSelect
                    disabled={!region || loading}
                    onChange={e => setCity(e.target.value)}
                    value={city}
                    inputProps={{
                      name: 'city',
                      id: 'city',
                    }}
                  >
                    {availableCities
                      ? availableCities.map(({ nomeCidade, codCidadeIBGE }) => (
                          <MaterialMenuItem
                            key={codCidadeIBGE}
                            value={codCidadeIBGE}
                          >
                            {nomeCidade}
                          </MaterialMenuItem>
                        ))
                      : null}
                  </MaterialSelect>
                </StyledFormControl>
              </Row>
            </>
          )}
        {emailInputUsed && !emailLoading && (
          <>
            <Row style={{ marginRight: '10%', marginLeft: '10%' }}>
              <StyledFields>
                <MaterialDatePicker
                  label="Data do evento"
                  format="DD/MM/YYYY"
                  value={weddingDate}
                  onChange={setDate}
                  disabled={loading || dateUndefined}
                  style={{ width: '100%' }}
                />
                <StyledFieldset>
                  <Checkbox
                    type="checkbox"
                    name="hasDate"
                    value="Não definida"
                    onChange={() => setDateUndefined(!dateUndefined)}
                    checked={dateUndefined}
                  />
                  <StyledSpan>Não definida</StyledSpan>
                </StyledFieldset>
              </StyledFields>
              <StyledFormControl style={{ width: '45%' }}>
                <StyledLabel>Região</StyledLabel>
                <MaterialSelect
                  disabled={loading}
                  onChange={e => setRegion(e.target.value)}
                  value={region}
                  inputProps={{
                    name: 'region',
                    id: 'region',
                  }}
                >
                  {data.regions.edges.map(({ node: { codRegiao, Regiao } }) => (
                    <MaterialMenuItem value={codRegiao} key={codRegiao}>
                      {Regiao}
                    </MaterialMenuItem>
                  ))}
                </MaterialSelect>
              </StyledFormControl>
            </Row>
            <Row style={{ marginRight: '10%', marginLeft: '10%' }}>
              <MaterialInput
                style={{ flexBasis: '45%' }}
                disabled={loading}
                onChange={e => setValue(e.target.value)}
                required
                label="Valor do contrato"
                value={value}
                InputProps={{
                  inputComponent: MoneyMask,
                }}
              />
              <StyledFormControl style={{ width: '45%' }}>
                <StyledLabel>Segmento</StyledLabel>
                <MaterialSelect
                  disabled={loading}
                  value={segment}
                  onChange={e => setSegment(e.target.value)}
                  inputProps={{
                    name: 'segment',
                    id: 'segment',
                  }}
                >
                  {segments.map(({ node: { codSegmento, Segmento } }) => (
                    <MaterialMenuItem value={codSegmento} key={codSegmento}>
                      {Segmento}
                    </MaterialMenuItem>
                  ))}
                </MaterialSelect>
              </StyledFormControl>
            </Row>
            <StyledButton
              text={loading ? 'GERANDO CONTRATO' : 'GERAR CONTRATO'}
              click={handleSubmit}
              style={{ width: '80%' }}
              disabled={loading}
            />
          </>
        )}
      </Form>
    </CardContainer>
  );
};

export default SupplierContractForm;
