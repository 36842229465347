import React from 'react';
import styled from 'styled-components';
import ModalContainer from './ModalContainer';
import { StyledHalfButton } from './styled';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CheckWeekModal = ({
  closeModal,
  opened,
  setEntireWeek,
  unsetEntireWeek
}) => {

  const onClickConfirm = async () => {
    setEntireWeek();
    closeModal();
  };

  const onClickCancel = async () => {
    unsetEntireWeek();
    closeModal();
  };

  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco>
      <Wrapper>
        <p>Marcando datas indisponíveis.</p>
        <p>
          Deseja marcar todos os outros dias desta semana como{' '}
          <span style={{ color: 'green' }}>disponíveis?</span>
        </p>
        <StyledHalfButton text="SIM" click={onClickConfirm} />
        <StyledHalfButton text="NÃO" click={onClickCancel} />
      </Wrapper>
    </ModalContainer>
  );
};

export default CheckWeekModal;