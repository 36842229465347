import React from 'react';
import styled from 'styled-components';

import { MaterialSelect, MaterialInputLabel, MaterialMenuItem } from './';
import { years } from '../utils/dropdownValues';

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 1em 0;
`;

export const YearsDropdown = ({ year, callback }) => {
  return (
    <ComponentWrapper>
      <MaterialInputLabel htmlFor="year-simple">
        Selecione o ano
      </MaterialInputLabel>
      <MaterialSelect
        onChange={event => callback(event.target.value)}
        value={year}
        inputProps={{
          name: 'year',
          id: 'year-simple',
        }}
        style={{ width: '100%' }}
      >
        {years
          ? years.map((ano, index) => (
              <MaterialMenuItem key={index} value={ano}>
                {ano}
              </MaterialMenuItem>
            ))
          : null}
      </MaterialSelect>
    </ComponentWrapper>
  );
};

export default YearsDropdown;
