import theme from "./theme";

export const customStyles = `
// helpers for positioning text
.text_right {
  text-align: right;
  margin-right: 0;
  margin-left: auto;
}
.text_center {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
.html_modal-open {
  overflow: hidden;
}
::-webkit-scrollbar {
  width:.75em;
  background: #f9f9f9;
}
::-webkit-scrollbar-track{
  box-shadow:inset 0 0 3px rgba(0,0,0,.3)
}
::-webkit-scrollbar-thumb {
  background: rgba(30,30,10,.2);
}
button:disabled {
  opacity: .7;
  cursor: default;
}
.btn {
  padding: .6em 1em;
  font-size: .9em;
  background: ${theme.colors.secondary};
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  transform: .2s ease opacity;
  :hover {
    opacity: .9;
  }
}
.btn_error {
  background: transparent;
  color: ${theme.colors.error};
  border: 2px solid ${theme.colors.error};
}
.btn_success {
  background: ${theme.colors.primary};
  color: ${theme.colors.secondary};
}
`;

export default customStyles;
