import React from 'react';
import MenuItem from '@material-ui/core/MenuItem'

import { theme } from '../styles/theme';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  cssLabel: {
    fontSize: theme.fontSizeSm,
    // color: theme.colors.error,
    // '&$cssFocused': {
    //   color: theme.colors.error,
    // },
  },
};

const MaterialMenuItem = props => {
  const { classes, ...other } = props;

  return (
    <MenuItem
      {...other}
      classes={{
        root: classes.cssLabel,
      }}
    />
  );
};

// All material textfield props
MaterialMenuItem.propTypes = {
};

export default withStyles(styles)(MaterialMenuItem);
