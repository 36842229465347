import React from 'react';
import styled from 'styled-components';

import ModalContainer from './ModalContainer';
import { PrioritySegmentList } from './';
import { bride } from '../services/api';
import { useStateValue } from '../state';
import Loader from './Loader';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NextSegmentsModal = ({ data, closeModal, opened, updateData }) => {
  const [
    {
      session: { selectedEvent },
    },
  ] = useStateValue();
  const [loading, setLoading] = React.useState(false);

  const handleClickConfirm = async (selectedSegments, oldSegments) => {
    setLoading(true);
    const payload = {
      selectedSegments: selectedSegments,
      event: selectedEvent,
      oldSegments: oldSegments,
    };

    const response = await bride.updatePrioritySegments(payload);
    if (response.ok) {
      updateData();
    } else {
      toast.error('Ocorreu um erro ao atualizar os segmentos');
    }
    setLoading(false);
    closeModal();
  };

  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco>
      <Wrapper>
        {loading ? (
          <Loader />
        ) : (
          <PrioritySegmentList
            data={data}
            onClickConfirm={handleClickConfirm}
          />
        )}
      </Wrapper>
    </ModalContainer>
  );
};

export default NextSegmentsModal;
