import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Arabesco from '../images/arabescos.svg';
import { medias } from '../utils/mediaQueries';
import { Button, AdvantageBudgetModal, AdvantagesDetailsModal } from './';
import { hexToRgb } from '../utils/hexToRgb';
import { theme } from '../styles/theme';
import { StyledHeart, StyledOutline } from './styled';
import { useStateValue } from '../state';
import { tracking } from '../services/api';

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  align-content: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin: 10px auto;
  width: 97% !important;
  height: 290px;
  padding: 0;
  background-color: ${theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const AssetContainer = styled.div`
  background-image: url(${Arabesco});
  background-size: cover;
  width: 100%;
  height: 40px;
  border-radius: 10px;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3px 0;
  height: 210px;
  @media ${medias.laptopMin} {
    width: 100%;
    flex: 1;
    margin: 0;
  }
`;

const ImageContainer = styled.div`
  max-height: 210px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  height: 100%;
  @media ${medias.phoneMax} {
    height: 75%;
    align-self: center;
  }
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 9px 0px 0px;
  width: 50%;
`;

const Title = styled.span`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
  }

  font-size: ${theme.fontSize}px;
  color: ${theme.colors.text_heading};
  font-weight: bold;
  text-align: right;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 0px;
  margin: 0;
`;

const SubTitle = styled.span`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
  width: 100%;
  flex-direction: row;
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  text-align: right;
  margin-bottom: 0.5em;
`;

const SegmentContainer = styled.div`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
  }
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
  align-items: right;
  justify-content: flex-end;
  text-align: right;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

/*const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    width: 40%;
  }

  display: flex;
  align-self: left;
  text-align: flet;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0;
  word-wrap: break-word;
`;
*/

const SavingsContainer = styled.span`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
    margin-left: 5px;
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
  width: 100%;
  flex-direction: row;
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  margin-bottom: 0.5em;
`;

const Savings = styled.span`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.primary};
  font-weight: bold;
  text-align: left;
  margin: 0 0.5em;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

const HeartsContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0;
  margin: 5px 0 0 0 !important;
  width: 100%;
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    font-size: 10.6px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 50%;
  font-size: 12px;
  height: 40px;
  margin-right: 0.2em;
  margin-left: 0.2em;
  padding-top: 0;
  padding-bottom: 0;
  display: inline-flex;
`;

const SmallAdvantagesCard = ({
  advantageData,
  category,
  removeFavorite,
  addFavorite,
  isFavoritePage,
}) => {
  const [categoryDiscount, setDiscount] = useState(null);
  const [budgetOpened, setBudgetOpened] = useState(false);
  const [favorited, setFavorited] = useState(advantageData.userFavorited);
  const [categoryForMore, setCategoryForMore] = useState(null);
  //const [moreDiscount, setMoreDiscount] = useState(null);
  const [detailsOpened, setDetailsOpened] = useState(false);

  const [
    {
      session: { selectedEvent },
    },
  ] = useStateValue();

  useEffect(() => {
    if (!categoryDiscount) {
      const _discountLight = (discount / 100) * 80;
      const _discountPlus = (discount / 100) * 90;
      if (category === 'LIGHT') {
        setDiscount(_discountLight.toFixed());
        //setMoreDiscount(_discountPlus - _discountLight);
        setCategoryForMore('PLUS');
      } else if (category === 'PLUS') {
        setDiscount(_discountPlus.toFixed());
        //setMoreDiscount(discount - _discountPlus);
        setCategoryForMore('VIP');
      } else {
        setDiscount(discount.toFixed());
      }
    }
  }, [category, categoryDiscount, discount]);

  const {
    codAdvantage,
    name,
    supplierName,
    discount,
    //segment,
    codSegment,
    image,
    linkSegment,
    otherBrideFavorited,
    description,
    resume,
  } = advantageData;

  const renderHeartIcon = () => {
    const Component = favorited ? StyledHeart : StyledOutline;
    const favoriteHandler = favorited
      ? () => removeFavorite(codAdvantage, 'advantages')
      : () => addFavorite(codAdvantage);

    return (
      <Component
        onClick={() => {
          setFavorited(!favorited);
          favoriteHandler();
        }}
      />
    );
  };

  const renderOtherBrideHeart = () =>
    otherBrideFavorited ? (
      <StyledHeart secondary />
    ) : (
      <StyledOutline secondary />
    );

  const trackAdvantage = async () => {
    const payload = {
      eventId: selectedEvent,
      advantageId: codAdvantage,
    };

    await tracking.advantage(payload);
  };

  const handleDetailsClick = () => {
    setDetailsOpened(true);
    trackAdvantage();
  };

  const handleBudgetClick = () => {
    setBudgetOpened(true);
    trackAdvantage();
  };

  return (
    <Wrapper>
      <AssetContainer />
      <MainContainer>
        <ImageContainer>
          {image && <Image src={image} alt="Imagem do benefício" />}
        </ImageContainer>
        <DataContainer>
          <Title>{name}</Title>
          <SubTitle>{supplierName}</SubTitle>
          <SegmentContainer key={codSegment}>
            <StyledIcon src={linkSegment} />
            {/*<ItemText>{segment}</ItemText>*/}
          </SegmentContainer>
          <SavingsContainer>
            Economize
            <Savings>R$ {discount}</Savings>
            <HeartsContainer>
              {renderHeartIcon()}
              {isFavoritePage && renderOtherBrideHeart()}
            </HeartsContainer>
          </SavingsContainer>
          <ButtonsContainer>
            <HalfButton text="DETALHES" click={handleDetailsClick} />
            <HalfButton text="EU QUERO" click={handleBudgetClick} />
          </ButtonsContainer>
        </DataContainer>
      </MainContainer>
      <AssetContainer />
      <AdvantageBudgetModal
        advantageData={advantageData}
        opened={budgetOpened}
        selectedEvent={selectedEvent}
        categoryDiscount={categoryDiscount}
        closeModal={() => setBudgetOpened(false)}
      />
      <AdvantagesDetailsModal
        discount={categoryDiscount}
        categoryForMore={categoryForMore}
        description={description}
        resume={resume}
        opened={detailsOpened}
        image={image || ''}
        closeModal={() => setDetailsOpened(false)}
      />
    </Wrapper>
  );
};

SmallAdvantagesCard.propTypes = {
  advantageData: PropTypes.shape({
    codAdvantage: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    supplierName: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
    segment: PropTypes.string.isRequired,
    codSegment: PropTypes.number.isRequired,
    isFavorited: PropTypes.bool,
    image: PropTypes.string.isRequired,
    linkSegment: PropTypes.string.isRequired,
  }),
};

export default SmallAdvantagesCard;
