import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';

import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { medias } from '../utils/mediaQueries';
import { bride } from '../services/api';
import Arabesco from '../images/arabescos.svg';
import { FavoriteStarIcon } from './icons';
import dateUndefined from '../utils/undefinedDate';
import { useStateValue } from '../state';
import Tooltip from '@material-ui/core/Tooltip';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  border: 1px solid ${theme.colors.grey1};
  border-radius: 20px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 4px 8px 0 ${hexToRgb(theme.colors.grey2, 0.5)};
  margin-top: 3rem;

  @media ${medias.laptopMax} {
    width: 90%;
  }
`;

const AssetContainer = styled.div`
  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;'
      : 'margin-bottom: 1em; border-top-left-radius: 20px; border-top-right-radius: 20px;'}
`;

const SegmentsContainer = styled.div`
  @media ${medias.phoneMax} {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  display: grid;
  width: 90%;
  grid-template-columns: repeat(auto-fill, 30%);
  grid-column-gap: 3.3%;
  grid-row-gap: 5px;
  justify-items: center;
  align-items: center;
`;

const SegmentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  font-size: ${theme.fontSize}px;
  text-align: left;
  color: ${theme.colors.text_heading};
  font-weight: bold;
  margin-left: 0.5em;
`;

const MainTitle = styled(Title)`
  width: 100%;
  text-align: center;
  color: ${theme.colors.primary};
`;

const EditSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration-color: ${theme.colors.yellow};
    color: ${theme.colors.yellow};
  }
`;

const ImageWrapper = styled.div`
  height: 20px;
  width: 21px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: center;
  margin: 1em;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;

  cursor: pointer;
`;

/*const ItemText = styled.p`
  @media ${medias.phoneMax} {
    width: 100%;
  }

  width: 80%;
  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeLg * 0.9}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 10px;
  overflow: hidden;
`;
*/

const StyledFavoriteStarIcon = styled(FavoriteStarIcon)`
  cursor: pointer;
  margin-left: 0.5em;
`;

const Segment = ({ data, onClick }) => {
  const { name, icon } = data;

  return (
    <ItemContainer onClick={onClick}>
      <Tooltip title={name}>
        <StyledIcon src={icon} />
      </Tooltip>
    </ItemContainer>
  );
};

export const FeaturedSegments = ({
  data,
  eventDate,
  onClickEdit,
  updateData,
}) => {
  const [
    {
      session: { selectedEvent },
    },
  ] = useStateValue();

  const removeFromPriority = async id => {
    const response = await bride.favoriteSegmentChecklist(id, selectedEvent);

    if (!response.ok) {
      return toast.error(
        'Erro ao remover o segmento. Por favor, tente novamente!'
      );
    }

    updateData();
  };

  const renderTitle = () => {
    if (!eventDate) {
      return '...';
    }

    const daysToEvent = moment(eventDate).diff(moment(), 'days');

    const renderRemainingDaysMsg = () => (
      <>
        Falta{daysToEvent > 1 && 'm'} {daysToEvent} dia
        {daysToEvent > 1 && 's'} para o casamento
      </>
    );

    const renderEventFinishedMsg = () => (
      <>Seu casamento aconteceu! Felicidades!</>
    );

    return (
      <>
        {eventDate !== dateUndefined ? (
          <>
            {daysToEvent >= 1
              ? renderRemainingDaysMsg()
              : renderEventFinishedMsg()}
          </>
        ) : (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate('/app/noivos/eventos', {
                state: { opened: selectedEvent, field: 'date' },
              })
            }
          >
            Informe a data do seu casamento
          </span>
        )}
      </>
    );
  };

  return (
    <Wrapper>
      <AssetContainer />
      <MainTitle>{renderTitle()}</MainTitle>
      <TitleWrapper>
        <ImageWrapper>
          <FavoriteStarIcon />
        </ImageWrapper>
        <Title>
          {data.length} próximos segmentos que vamos contratar{' '}
          <EditSpan onClick={onClickEdit}>
            (editar)
          </EditSpan>
        </Title>
      </TitleWrapper>
      <SegmentsContainer>
        {data.map(segment => (
          <SegmentContainer key={`priority-${segment.id}`}>
            <Segment
              onClick={() =>
                navigate('/app/noivos/fornecedores', {
                  state: {
                    input: segment.id,
                    isSegment: true,
                    segmentName: segment.name,
                  },
                })
              }
              data={segment}
            />
            <StyledFavoriteStarIcon
              onClick={() => removeFromPriority(segment.idChecklist)}
            />
          </SegmentContainer>
        ))}
      </SegmentsContainer>
      <AssetContainer bottom />
    </Wrapper>
  );
};

export default FeaturedSegments;
