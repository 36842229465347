import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import { useStateValue } from '../state';
import { bride, tracking } from '../services/api';
import { toast } from 'react-toastify';

import theme from '../styles/theme';
import Arabesco from '../images/arabescos.svg';
import { hexToRgb } from '../utils/hexToRgb';
import { medias } from '../utils/mediaQueries';
import { Favorite, FavoriteOutline, PhoneIcon, MessageIcon } from './icons';
import { Button, Tabs, ConfirmModal } from './';
import moment from 'moment';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 2em;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px;  border-bottom-right-radius: 10px;'
      : 'margin-bottom: 1em;'}
`;

const RowContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: ${({ first }) => !first && 'center'};
  padding-bottom: 1em;
  ${({ border }) =>
    border &&
    `
    border-bottom: 1px solid ${theme.colors.grey1}
    margin-bottom: 0.7em;
  `}
`;

const PrizeInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const PrizeTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.75em;
  }

  color: ${theme.colors.text_heading};
  margin: 0;
`;

const DefaultText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.75em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  margin: 0;
`;

const SupplierNameText = styled.span`
  padding-top: 0.4rem;
  font-weight: bold;
`;

const TopPaddedText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.75em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  margin: 0;
  padding-top: 0.8em;
`;

const BoldText = styled.strong`
  color: ${theme.colors.primary};
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
    height: 3em;
    text-align: center;
  }

  width: 45%;
  background-color: ${({ white }) =>
    white ? theme.colors.white : theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: ${({ white }) =>
    white ? theme.colors.text_heading : theme.colors.white};
`;

const TextContainer = styled.p`
  align-self: center;
  border: 1px solid ${theme.colors.grey2};
  border-radius: 3px;
  padding: 1em;
  color: ${theme.colors.grey3};
  font-size: ${theme.fontSizeMed};
  width: 90%;
`;

const Title = styled.span`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeMed};
  font-weight: bold;
  display: block;
  margin-bottom: 0.5em;
`;

const ImageContainer = styled.div`
  @media ${medias.laptopMin} {
    width: 40%;
    height: 25%;
  }

  @media ${medias.phoneMax} {
    width: 40%;
    height: 25%;
  }

  object-fit: contain;
  width: 269px;
  height: 151px;
`;

const Image = styled.img`
  width: 100%;
`;

const IconsContainer = styled.span`
  margin-top: 0.3em;
  margin-right: 0.2em;
`;

const StyledSpan = styled.span`
  align-items: center;
  display: flex;
  flex: 1;
`;

const tabsContent = [
  {
    id: 0,
    title: 'DESCRIÇÃO',
  },
  {
    id: 1,
    title: 'FORNECEDOR',
  },
  {
    id: 2,
    title: 'TERMOS DE USO',
  },
];

const StyledText = styled.span`
  font-size: 0.8em;
`;

const ContentComponent = ({ title, text }) => (
  <TextContainer>
    <Title>{title}</Title>
    <StyledText>{text}</StyledText>
  </TextContainer>
);

const HeartsContainer = styled.div`
  display: flex;
`;

export const PrizesCard = ({
  prizeData,
  removeFavorite,
  addFavorite,
  reloadRedeemed,
  isSupplier = false,
  isFavoritePage,
  tab,
  eventPoints,
}) => {
  const {
    codCCPrize,
    redeemDate,
    title,
    supplierName,
    slugUrl,
    supplierEmail,
    supplierPhone,
    points,
    description,
    excerpt,
    codPrize,
    Region,
    eventCategory,
    amountC,
    amountD,
    reedemLimit,
    userFavorited,
    otherBrideFavorited,
    image,
    codSupplier,
  } = prizeData;
  const [openedDetails, setOpenedDetails] = useState(false);
  const [favorited, setFavorited] = useState(userFavorited);
  const [modal, setModalOpen] = useState(false);

  const [
    {
      //prizes: { eventPoints },
      session: { selectedEvent, userId },
    },
    dispatch,
  ] = useStateValue();

  const reedemSucess = () => {
    const newPoints = eventPoints - points;
    dispatch({
      type: 'setEventPoints',
      newEventPoints: newPoints,
    });
    toast.error('Prêmio resgatado');
    reloadRedeemed();
  };

  const reedem = async () => {
    if (eventPoints < points) {
      return toast.error(
        'Você não possui saldo suficiente para resgatar este prêmio!'
      );
    }
    const payload = {
      codPrize,
      codEvent: selectedEvent,
    };
    const response = await bride.reedemPrize(payload);
    response.ok && response.data
      ? reedemSucess(response.data)
      : toast.error(response.data);
  };

  const handleNavigate = () => {
    tracking.supplier({
      userId,
      eventId: selectedEvent,
      supplierId: codSupplier,
    });

    return navigate(`fornecedor/${slugUrl}`, { state: { id: codSupplier } });
  };

  const termsOfUse = (
    <ul>
      <li>
        Clique em Resgatar caso deseje resgatar o prêmio acima. Custo: {points}{' '}
        pontos.
      </li>
      <li>
        Prêmio sujeito a disponibilidade de datas por parte do fornecedor.
      </li>
      <li>
        O conteúdo do prêmio é de responsabilidade do fornecedor. Qualquer
        detalhe adicional deve ser verificado diretamente com o mesmo antes do
        resgate do prêmio. A Rede Vestidas de Branco não se responsabiliza por
        itens/detalhes diferentes do descrito na aba Descrição, como fretes,
        transportes, valores adicionais pela modificação do serviço original,
        etc. Caso tenha dúvidas entre em contato conosco.
      </li>
      <li>Agendamento obrigatório.</li>
      <li>Não acumulativo com outras promoções.</li>
      <li>
        As imagens contidas nesta oferta são meramente ilustrativas, salvo
        aquelas expressamente identificadas como sendo reais.
      </li>
    </ul>
  );

  const renderSupplierInfo = (
    <div>
      <StyledSpan>
        <IconsContainer>
          <PhoneIcon />
        </IconsContainer>{' '}
        Telefone para contato com os noivos:{' '}
        {supplierPhone ? supplierPhone : ''}{' '}
      </StyledSpan>
      {supplierEmail ? (
        <StyledSpan>
          <IconsContainer>
            <MessageIcon />
          </IconsContainer>{' '}
          {supplierEmail}
        </StyledSpan>
      ) : (
        ''
      )}
      <br />
      <SupplierNameText style={{ cursor: 'pointer' }} onClick={handleNavigate}>
        Visitar site de {supplierName}
      </SupplierNameText>
    </div>
  );

  const descriptionCleaned = description
    .split('<br />')
    .map(function(item, key) {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });

  const tabComponents = [
    <ContentComponent
      key={0}
      title="Descrição do Benefício"
      text={descriptionCleaned}
    />,
    <ContentComponent key={1} title={supplierName} text={renderSupplierInfo} />,
    <ContentComponent key={2} title="Termos de Uso" text={termsOfUse} />,
  ];

  const renderFavorite = () =>
    favorited ? (
      <Favorite
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setFavorited(!favorited);
          removeFavorite(codPrize, 'prizes');
        }}
      />
    ) : (
      <FavoriteOutline
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setFavorited(!favorited);
          addFavorite(codPrize, 'prizes');
        }}
      />
    );

  const renderDetails = () => {
    return (
      openedDetails && <Tabs content={tabsContent} components={tabComponents} />
    );
  };

  const renderOtherBrideHeart = () =>
    otherBrideFavorited ? (
      <Favorite secondary />
    ) : (
      <FavoriteOutline secondary />
    );

  const buttonText = openedDetails ? 'OCULTAR DETALHES' : 'DETALHES';
  const limitLabel = isSupplier ? 'fornecedor' : 'casal';

  return (
    <Wrapper>
      <ConfirmModal
        opened={modal}
        style={{ marginBottom: '400px' }}
        closeModal={() => setModalOpen(false)}
        onClickConfirm={reedem}
        text={'Você tem certeza que quer resgatar esse prêmio ?'}
      />
      <AssetContainer />
      <RowContainer border first>
        <ImageContainer>
          {image && <Image src={image} alt="Prize Image" />}
        </ImageContainer>
        <PrizeInfo>
          <RowContainer>
            <PrizeTitle>{title}</PrizeTitle>
            <HeartsContainer>
              {renderFavorite()}
              {isFavoritePage && renderOtherBrideHeart()}
            </HeartsContainer>
          </RowContainer>
          {isSupplier ? (
            <p style={{ marginTop: 0 }}>{excerpt}</p>
          ) : (
            <>
              <SupplierNameText>{supplierName}</SupplierNameText>
              <DefaultText>{Region}</DefaultText>
              <DefaultText>
                Categoria: <BoldText>{eventCategory}</BoldText>
              </DefaultText>
              <TopPaddedText>{supplierPhone}</TopPaddedText>
              <TopPaddedText>{supplierEmail}</TopPaddedText>

              {codCCPrize && (
                <>
                  <DefaultText>
                    Data do resgate: {moment(redeemDate).format('DD/MM/YYYY')}
                  </DefaultText>
                </>
              )}
            </>
          )}
        </PrizeInfo>
      </RowContainer>
      <RowContainer>
        <DefaultText>
          Valor: <BoldText>{points}</BoldText>{' '}
          {isSupplier ? 'moedas' : ' pontos'}
        </DefaultText>
        <DefaultText>
          <BoldText>{amountC - amountD}</BoldText> em estoque
        </DefaultText>
        <DefaultText>
          Máximo <BoldText>{reedemLimit}</BoldText> por {limitLabel}
        </DefaultText>
      </RowContainer>
      {renderDetails()}
      <RowContainer>
        <HalfButton
          white={openedDetails}
          text={buttonText}
          click={() => setOpenedDetails(!openedDetails)}
        />
        <HalfButton
          text={`RESGATAR ${tab === 2 ? 'NOVAMENTE' : ''}`}
          click={() => setModalOpen(true)}
        />
      </RowContainer>
      <AssetContainer bottom />
    </Wrapper>
  );
};

PrizesCard.propTypes = {
  prizeData: PropTypes.shape({
    points: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    supplierName: PropTypes.number.isRequired,
  }),
};

export default PrizesCard;
