import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import Arabesco from '../images/arabescosModal.svg';
import { CirclePlusIcon } from './icons';
import './StoryCard.css';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: center;
  margin-top: 1.5em;
`;

const AssetContainer = styled.div`
  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 3em;
  margin-top: -3.2em;

  z-index: 2;
  position: relative;
`;

const Line = styled.div`
  width: 70px;
  height: 3px;
  background-color: ${theme.colors.primary};
  align-self: center;
  margin: 10px 0 10px 0;
`;

const Card = styled.div`
  position: relative;
  min-height: 357px;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.grey1};
  margin: 25px 0 0 0;
`;

const CardRow = styled.div`
  display: flex;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 0 10px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const PageTitle = styled.h3`
  font-style: normal;
  font-weight: ${theme.bodyWeight};
  align-self: center;
  margin: 0;
`;

const CardTitle = styled.h2`
  color: ${theme.colors.grey3};
  align-self: center;
`;

const CardText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.colors.fontsize * 1.06}px;
  text-align: left;
  width: ${props => props.main ? '70%' : '85%'};
  ${props => (props.main ? 'margin: 5em 0 0 10px' : 'margin: 0 0 4em 10px')};

  a {
    text-decoration: none;
    color: ${theme.colors.text_heading};
  }
`;

const IconText = styled.p`
  @media ${medias.phoneMax} {
    margin: 0.3em 0 0 0;
    font-size: 0.8em;
  }

  font-size: ${theme.colors.fontsize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.secondary};
  margin: 0;
`;

const StyledIcon = styled(CirclePlusIcon)`
  margin: 2px 0 0 0;
  cursor: pointer;
`;

const StyledImage = styled(Img)`
  @media ${medias.phoneMax} {
    max-width: 100%;
  }

  display: flex;
  flex: 1;
  align-self: center;
`;

const ImageContainer = styled.div`
 @media ${medias.phoneMax} {
    width: 100%;
    height: 338px;
  }
  margin: 0 0 20px 0;
  width: 599px;
  height: 338px;
  max-height: 357px;
`;

//@TODO Find the propér image and assets
const StoryCard = () => {
  const [textOpened, setTextOpened] = React.useState(false);

  const data = useStaticQuery(graphql`
    query {
      story: file(relativePath: { eq: "story.png" }) {
        childImageSharp {
          fixed(width: 352, height: 357) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  //@TODO Implement site route on this func
  const onClickIcon = () => {
    setTextOpened(!textOpened);
  };

  return (
    <Wrapper>
      <PageTitle>QUEM SOMOS</PageTitle>
      <Line />
      <CardTitle>Nossa história</CardTitle>
      <Card>
        <CardRow>
          <ImageContainer className="faded faded-all">
            <StyledImage fixed={data.story.childImageSharp.fixed} />
          </ImageContainer>
          <SubContainer>
            <CardText main>
              Em 2014, nossa Co-Fundadora Maíse Góis se tornou noiva, e entrou
              em um mundo novo, onde percebeu várias dificuldades que a noiva
              enfrenta neste momento.
            </CardText>
            <IconContainer>
              <StyledIcon onClick={onClickIcon} />
              <IconText>Saiba mais</IconText>
            </IconContainer>
            {textOpened && (
              <CardText>
                <br />
                As principais dúvidas eram: quais fornecedores contratar, se
                eram confiáveis, se teria algum benefício por consumir tanto
                neste período… Tantas dúvidas que a levaram a ter alguns
                problemas com fornecedores em seu próprio casamento. Assim, em
                2014, nascia o projeto Vestidas de Branco, onde a principal
                ideia era passar segurança e confiabilidade aos noivos, e, além
                disso, premiá-los de alguma forma, uma vez que não havia nenhuma
                contrapartida em suas contratações, mesmo com investimento
                elevado.
                <br />
                <br />
                Em outubro de 2014, Maíse, que já era empreendedora e já possuía
                algumas outras empresas conheceu Daniel Rocha, analista de
                sistemas com mais de 15 anos de experiência em desenvolvimento
                e, assim, viabilizaram de forma que este projeto pudesse ganhar
                forma.
                <br />
                <br />
                Em fevereiro de 2015, a Vestidas de Branco foi lançada
                oficialmente ao mercado de Belo Horizonte (veja fotos{' '}
                <a
                  href="http://www.bheventos.com.br/cobertura/02-03-2015-lancamento-da-rede-de-noivas-vestidas-de-branco-acontece-no-espaco-lourdes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>{' '}
                e matéria{' '}
                <a
                  href="http://www.bheventos.com.br/cobertura/02-03-2015-lancamento-da-rede-de-noivas-vestidas-de-branco-acontece-no-espaco-lourdes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>
                ). De lá para cá, já trabalhamos com mais de 120 fornecedores e
                temos mais de 6500 noivas cadastradas. Já trabalhamos em nossa
                expansão para mais cidades, atendendo e alcançando mais noivos e
                fornecedores e revolucionando a forma de relacionamento e
                fechamento de negócios entre eles (veja matéria veiculada no
                Diario do Commercio{' '}
                <a
                  href="http://www.diariodocomercio.com.br/noticia.php?tit=vestidas_de_branco_preve_movimentar_r_1_milhao_no_ano&id=169513"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>
                , participação na matéria sobre casamentos na Rede Globo Minas{' '}
                <a
                  href="http://g1.globo.com/minas-gerais/mgtv-1edicao/videos/t/integras/v/mgtv-1-edicao-programa-de-sabado-27052017-na-integra/5899416/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>{' '}
                a partir do minuto 24 e matéria sobre nós no Programa Paulo
                Navarro{' '}
                <a
                  href="https://www.youtube.com/watch?v=nyEtEeEeAmE&t=32s"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>
                ).
              </CardText>
            )}
          </SubContainer>
        </CardRow>
        <AssetContainer />
      </Card>
    </Wrapper>
  );
};

export default StoryCard;
