import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import {
  CartIcon,
  HeartIcon,
  ListIcon,
  ShieldIcon,
  ThumbsUpIcon,
  GiftIcon,
} from './icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-top: 2em;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1em;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 27%;
`;

const ItemTitle = styled.h2`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
    height: 3.5em;
  }

  line-height: 1.1em;
  color: ${theme.colors.primary};
  font-weight: bold;
  text-align: center;
  margin: 0.8em 0 0 0;
  height: 2.5em;
  /* margin: ${props => (props.longTitle ? '12px 0 0 0' : '20px 0 20px 0')}; */
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
    margin: 0;
  }

  font-size: ${theme.fontSizeLg * 0.9}px;
  text-align: center;
`;

const JoinInformation = () => {
  return (
    <Wrapper>
      <Row>
        <ItemContainer>
          <CartIcon />
          <ItemTitle longTitle>Compre de um fornecedor</ItemTitle>
          <ItemText longTitle>
          Comprando de um de nossos fornecedores você pode solicitar o seu cadastro na Vestidas de Branco.
          </ItemText>
        </ItemContainer>
        <ItemContainer>
          <HeartIcon />
          <ItemTitle>Seja indicado(a)</ItemTitle>
          <ItemText>
            Cada noivo da rede pode indicar um(a) casal de amigos(as) para
            participar.
          </ItemText>
        </ItemContainer>
        <ItemContainer>
          <ListIcon />
          <ItemTitle>Lista de espera</ItemTitle>
          <ItemText>
          A Vestidas de Branco realiza inserções periódicas de acessos, de acordo com vagas disponíveis ! Preencha o formulário, quem sabe a próxima (o) não pode ser você?
          </ItemText>
        </ItemContainer>
      </Row>
      <Row>
        <ItemContainer>
          <ShieldIcon />
          <ItemTitle>Confiança</ItemTitle>
          <ItemText>
            Todos os fornecedores são avaliados para que seu evento esteja nas
            melhores mãos e para que você curta do início ao fim, sem
            preocupações.
          </ItemText>
        </ItemContainer>
        <ItemContainer>
          <ThumbsUpIcon />
          <ItemTitle>Benefícios</ItemTitle>
          <ItemText>
            Noivos tem benefícios diferenciados com nossos fornecedores e em
            nossos eventos. Nossos noivos podem economizar mais de R$ 20 mil
            reais.
          </ItemText>
        </ItemContainer>
        <ItemContainer>
          <GiftIcon />
          <ItemTitle>Prêmios</ItemTitle>
          <ItemText>
            Toda compra dentro da Vestidas de Branco é pontuada. Os pontos são
            convertidos em prêmios para seu próprio casamento.
          </ItemText>
        </ItemContainer>
      </Row>
    </Wrapper>
  );
};

export default JoinInformation;
