import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';
import { Button, MaterialInput, MoneyMask, Loader } from './';
import { medias } from '../utils/mediaQueries';
import ModalContainer, { ModalTitle } from './ModalContainer';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    width: 90%;
  }
`;

const StyledButton = styled(Button)`
  @media ${medias.phoneMax} {
    font-size: 11px;
  }

  border: 2px solid ${theme.colors.text_heading};
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  width: 90%;
  height: 1.8em;
  padding: 0;
  margin-top: 1em;
`;

const StyledFieldset = styled.div`
  width: 90%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const ContestModal = ({ closeModal, opened, onClickButton, loading }) => {
  const [contractValue, setContractValue] = useState('');
  const [recognized, setRecognized] = useState(false);

  const handleClickConfirm = () => {
    onClickButton(contractValue);
  };

  const handleClickCheckbox = () => {
    setContractValue('');
    setRecognized(!recognized);
  };

  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco>
      <Wrapper>
        <ModalTitle>CONTESTAR CONTRATO</ModalTitle>
        <p>
          Informe o valor correto do contrato fechado com este fornecedor ou
          marque a opção &quot;Contrato não reconhecido&quot; caso não tenha
          fechado contrato.
        </p>
        <MaterialInput
          style={{ width: '90%' }}
          label="Valor do contrato"
          value={contractValue}
          onChange={e => setContractValue(e.target.value)}
          disabled={recognized}
          InputProps={{
            inputComponent: MoneyMask,
          }}
        />
        <StyledFieldset>
          <Checkbox
            type="checkbox"
            name="notRecognized"
            onChange={handleClickCheckbox}
            checked={recognized}
          />
          <StyledSpan>Contrato não reconhecido</StyledSpan>
        </StyledFieldset>
        {loading ? (
          <Loader />
        ) : (
          <StyledButton text="ENVIAR" click={handleClickConfirm}></StyledButton>
        )}
      </Wrapper>
    </ModalContainer>
  );
};

ContestModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onClickButton: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

export default ContestModal;
