import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';
import { Button } from './index';
import { medias } from '../utils/mediaQueries';
import ModalContainer, { ModalTitle } from './ModalContainer';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  @media ${medias.phoneMax} {
    font-size: 11px;
  }

  border: 2px solid ${theme.colors.text_heading};
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  width: 45%;
  height: 1.8em;
  padding: 0;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
  ${({ borderBottom }) =>
    borderBottom &&
    `border-bottom: 1px solid ${theme.colors.grey3}; padding-bottom: 1.5em;`}
`;

const ConfirmModal = ({ closeModal, opened, onClickConfirm, text }) => {
  const clickConfirm = () => {
    onClickConfirm(true);
    closeModal();
  };

  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco>
      <Wrapper>
        <ModalTitle>ATENÇÃO</ModalTitle>
        <p>{text}</p>
        <RowContainer>
          <StyledButton text="CONFIRMAR" click={() => clickConfirm()} />
          <StyledButton text="CANCELAR" click={() => closeModal()} />
        </RowContainer>
      </Wrapper>
    </ModalContainer>
  );
};

ConfirmModal.propTypes = {
  disabled: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  disabled: false,
};

export default ConfirmModal;
