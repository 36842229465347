const initialState = {
  loginForm: {
    email: '',
    password: '',
    type: '',
    forgotPasswordEmail: '',
  },
  bridesForm: {
    name: '',
    email: '',
    phone: '',
    cellphone: '',
    date: null,
    state: '',
    city: '',
    cerimonyLocation: '',
  },
  supplierForm: {
    socialReason: '',
    cnpj: '',
    publicityName: '',
    city: '',
    state: '',
    brideEmail: '',
    bridePhone: '',
    instagram: '',
    webPage: '',
    resume: '',
    comercialPhone: '',
    comercialEmail: '',
    companyStart: null,
    activityType: '',
    otherActivity: '',
    codSupplierOrigin: '',
    weddingsPerMonth: '',
    contractAveragePrice: '',
    weddingsPerDay: '',
    ceremonialPartners: ['', '', ''],
    suppliersPartners: ['', '', ''],
    regionOfInterest: '',
    segmentOfInterest: '',
  },
  franchiseForm: {
    cnpj: '',
    publicityName: '',
    state: '',
    city: '',
    comercialContact: '',
    comercialPhone: '',
    comercialEmail: '',
    resume: '',
    codSupplierOrigin: '',
  },
  contactForm: {
    subject: '',
    whoIs: '',
    region: '',
    name: '',
    email: '',
    message: '',
  },
  requestBudgetForm: {
    name: '',
    email: '',
    phone: '',
    weddingGuests: null,
    codIBGE: 1,
    codContactMe: '',
    cerimonyLocation: null,
    eventDate: null,
    uf: 0,
    city: 0,
    message: null,
  },
  brideWelcomeForm: {
    name: null,
    email: null,
    phone: '',
    cellphone: '',
    uf: '',
    city: '',
    engaged:'',
    marriageCity:'',
    date: new Date(),
    investment: null,
    invited:'',
    party:'',
    local:'',
    cerimonyLocation: null,
    ufCerimony: null,
    cityCerimony: null,
    receptionLocation: null,
    ufReception: null,
    cityReception: null,
    acceptTerms: null,
    eventName: null,
  },
  referForm: {
    name: '',
    email: '',
    phone: '',
    date: new Date(),
    region: 0,
    city: 0,
    uf: 0
  },
  profileForm: {
    name: '',
    email: '',
    phone: '',
    cellphone: '',
    instagram: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }
};

export default initialState;
