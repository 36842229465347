import React, { useState } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';

import { Button, MaterialDatePicker, YearsDropdown } from '.';
import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import ModalContainer from './ModalContainer';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: ${({ tab }) => (tab ? 'space-between ' : 'space-around')};
  align-items: center;
  width: ${({ tab }) => (tab ? '95%' : '100%')};
  ${({ tab }) =>
    tab &&
    `
    border-bottom: 1px solid ${theme.colors.grey2}
    margin-bottom: 0.5em;
  `}
`;

const TabName = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
  }

  font-size: ${theme.fontSize * 0.94}px;
  color: ${theme.colors.text_heading};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  background-color: ${theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: white;
  height: 2em;
  width: 45%;
  margin-top: 1em;
  padding: 0;
`;

const StyledFormControl = styled(FormControl)`
  @media ${medias.phoneMax} {
    width: 90%;
  }

  width: 50%;
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props} />)`
  width: 45%;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 3em;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

const ModalTabs = ({ onSelectTab }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTab = id => {
    setSelectedTab(id);
    onSelectTab(id);
  };

  const renderTabs = () => {
    return (
      <>
        <TabName selected={selectedTab === 0} onClick={() => handleTab(0)}>
          DATA DO CASAMENTO
        </TabName>
        <TabName selected={selectedTab === 1} onClick={() => handleTab(1)}>
          ANO DO CASAMENTO
        </TabName>
      </>
    );
  };

  return (
    <>
      <RowContainer tab>
        <TabContainer>{renderTabs()}</TabContainer>
      </RowContainer>
    </>
  );
};

const ContractsFilterModal = ({
  closeModal,
  opened,
  onClickFilter,
  onClickClear,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [year, setYear] = useState('');
  const [weddingDate, setWeddingDate] = useState({
    from: new Date(),
    to: new Date(),
  });

  const cleanForm = () => {
    setYear('');
    setWeddingDate({
      from: new Date(),
      to: new Date(),
    });
    onClickClear();
  };

  const changeYear = async inputYear => {
    setYear(inputYear);
  };

  const handleFilter = () => {
    if (selectedTab === 0) {
      const data = weddingDate;
      onClickFilter('weddingDate', data);
      return;
    }

    if (selectedTab === 1) {
      const data = year;
      onClickFilter('year', data);
      return;
    }
  };

  const renderFirstPage = () => {
    if (selectedTab === 0) {
      return (
        <RowContainer>
          <StyledDatePicker
            label="De"
            format={'DD/MM/YYYY'}
            value={weddingDate.from}
            onChange={date => setWeddingDate({ ...weddingDate, from: date })}
          />
          <StyledDatePicker
            label="Até"
            format={'DD/MM/YYYY'}
            value={weddingDate.to}
            onChange={date => setWeddingDate({ ...weddingDate, to: date })}
          />
        </RowContainer>
      );
    } else if (selectedTab === 1) {
      return (
        <StyledFormControl>
          <YearsDropdown year={year} callback={changeYear} />
        </StyledFormControl>
      );
    }
  };

  const renderContent = () => {
    return renderFirstPage();
  };

  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco={true}>
      <ModalTabs onSelectTab={id => setSelectedTab(id)} />
      <Wrapper>
        <RowContainer>
          <Container>{renderContent()}</Container>
        </RowContainer>
        <RowContainer>
          <StyledButton text="LIMPAR" click={cleanForm} />
          <StyledButton text="FILTRAR" click={handleFilter} />
        </RowContainer>
      </Wrapper>
    </ModalContainer>
  );
};

export default ContractsFilterModal;
