import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { tracking } from '../services/api';
import { useStateValue } from '../state';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import {
  CirclePlusIcon,
  CircleMinusIcon,
  ConfirmIcon,
  RedExitIcon,
} from './icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ wrapperSize }) => (wrapperSize ? wrapperSize : '100%')};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 0 1.5em;

  cursor: pointer;
`;

const Line = styled.div`
  background-color: ${theme.colors.grey1};
  height: 1px;
  width: 100%;
  margin-bottom: 0.8em;
`;

const SegmentItem = styled.div`
  @media ${medias.phoneMax} {
    width: 42%;
  }

  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5em 0.5em;

  ${({ disabled }) => !disabled && `cursor: pointer;`}
`;

const SegmentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.8em 0 0.8em 0;
  padding-left: 0.8em;
`;

const ItemTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin: 0;
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    margin: 0 0 0 5px;
  }

  display: flex;
  align-self: center;
  width: 83%;
  font-size: ${theme.fontSizeLg * 0.9}px;
  color: ${({ textColor }) =>
    textColor ? textColor : theme.colors.text_heading};
  margin: 0 0 0 10px;
  overflow: hidden;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const StyledAddIcon = styled(CirclePlusIcon)`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};

  cursor: pointer;
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

const AddItemContainer = styled.div`
  background-color: ${theme.colors.text_heading};
  margin: 10px;
  padding: 2% 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContainerElements = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  @media ${medias.phoneMax} {
    font-size: 0.75em;
  }

  background-color: transparent;
  border: none;
  font-family: Lato;
  font-size: ${theme.fontSize}px;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${theme.colors.white};
  margin: 5px;
`;

const StyledLine = styled.div`
  height: 2px;
  margin: 2px;
  background-color: ${theme.colors.white};
`;
const StyledLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const SegmentList = props => {
  const {
    title,
    items,
    wrapperSize,
    include,
    disabled,
    filter,
    onClickFilter,
    onClick,
    selected,
  } = props;

  const [data, setData] = useState(items);
  const [openedStage, setOpenedStage] = useState(true);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [addSegment, setAddSegment] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [
    {
      session: { userId, selectedEvent },
    },
  ] = useStateValue();

  useEffect(() => {
    setData(items);
  }, [items]);

  useEffect(() => {
    setSelectedSegments(selected);
  }, [selected]);

  const handleClickableList = (title, itemId, linkIcone, isFilter) => {
    if (selected && selectedSegments.includes(itemId) && !isFilter) {
      return (
        <ItemContainer>
          <StyledIcon src={linkIcone} />
          <ItemText textColor={theme.colors.primary}>{title}</ItemText>
        </ItemContainer>
      );
    }

    return (
      <ItemContainer>
        <StyledIcon src={linkIcone} />
        <ItemText textColor={theme.colors.text_heading}>{title}</ItemText>
      </ItemContainer>
    );
  };

  const handleUnclickableList = (itemTitle, status, linkIcone, listed) => {
    if (listed) {
      return (
        <ItemContainer disabled>
          <StyledIcon src={linkIcone} />
          <ItemText textColor={theme.colors.grey2}>{itemTitle}</ItemText>
        </ItemContainer>
      );
    }

    const itemColor =
      status === 'done'
        ? theme.colors.grey2
        : status === 'attention'
        ? theme.colors.primary
        : theme.colors.text_heading;

    return (
      <ItemContainer>
        <StyledIcon src={linkIcone} color={itemColor} />
        <ItemText textColor={itemColor}>{itemTitle}</ItemText>
      </ItemContainer>
    );
  };

  const handleConfirmSegment = () => {
    const newSegment = {
      codSegmento: 0,
      Segmento: inputValue,
      linkIcone:
        'https://storage.googleapis.com/assets-vdb/bemCasadoPaoMel.svg',
      inativo: 0,
      faseSegmento: data[0].faseSegmento,
    };

    const newData = [...data, newSegment];

    setData(newData);
    setInputValue('');
  };

  const handleAddSegment = () => {
    return (
      <AddItemContainer>
        <StyledLineContainer>
          <StyledInput
            type="text"
            value={inputValue}
            placeholder="Digite o nome do segmento"
            onChange={event => setInputValue(event.target.value)}
          />
          <StyledLine />
        </StyledLineContainer>
        <ConfirmIcon
          style={{ cursor: 'pointer' }}
          onClick={() => handleConfirmSegment()}
        />
        <RedExitIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setAddSegment(false)}
        />
      </AddItemContainer>
    );
  };

  const onSearchItem = (segmentId, segmentName) => {
    navigate('app/noivos/fornecedores', {
      state: { input: segmentId, isSegment: true, segmentName },
    });
  };

  const handleClickSegment = (segment, id) => {
    tracking.segment({
      userId,
      eventId: selectedEvent,
      segmentId: id,
    });

    if (disabled) {
      onSearchItem(id, segment.Segmento);
      return;
    }

    if (filter) {
      onClickFilter(id, segment.Segmento);
      return;
    }

    onClick(segment);
  };

  return (
    <Wrapper wrapperSize={wrapperSize}>
      <Item>
        <Line />
        <TitleContainer onClick={() => setOpenedStage(!openedStage)}>
          {openedStage ? <CircleMinusIcon /> : <CirclePlusIcon />}
          <ItemTitle>{title}</ItemTitle>
        </TitleContainer>
        {openedStage && (
          <ContainerElements>
            <SegmentContainer>
              {data.map(item => {
                const { Segmento, codSegmento, status, linkIcone } = item;
                if (status !== 'deleted') {
                  return (
                    <SegmentItem
                      disabled={item.escolhido}
                      key={codSegmento}
                      onClick={() => handleClickSegment(item, codSegmento)}
                    >
                      {!include && !disabled
                        ? handleClickableList(
                            Segmento,
                            codSegmento,
                            linkIcone,
                            filter
                          )
                        : handleUnclickableList(
                            Segmento,
                            status,
                            linkIcone,
                            item.escolhido || false
                          )}
                    </SegmentItem>
                  );
                }
              })}
              {include && (
                <SegmentItem onClick={() => setAddSegment(true)}>
                  <StyledAddIcon color={theme.colors.primary} />
                  <ItemText
                    textColor={theme.colors.primary}
                    style={{ marginLeft: '0px', paddingBottom: '5px' }}
                  >
                    INCLUIR SEGMENTO
                  </ItemText>
                </SegmentItem>
              )}
            </SegmentContainer>
            {addSegment && handleAddSegment()}
          </ContainerElements>
        )}
      </Item>
    </Wrapper>
  );
};

// SegmentList.propTypes = {
//   title: PropTypes.string.isRequired,
//   items: PropTypes.arrayOf(
//     PropTypes.shape({
//       title: PropTypes.string.isRequired,
//       icon: PropTypes.string.isRequired,
//     })
//   ),
//   include: PropTypes.boolean,
// };

// SegmentList.defaultProps = {
// };

export default SegmentList;
