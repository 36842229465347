import produce from 'immer';

export default (data, action) => {
  switch (action.type) {
    case 'setPrizes':
      return produce(data, draft => {
        draft.prizes = action.newPrizes;
      });

      case 'setEventPoints':
      return produce(data, draft => {
        draft.eventPoints = action.newEventPoints;
      });

    default:
      return data;
  }
};
