import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useWindowWidth } from '../hooks';
import { TestimonialsCard } from './';
import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import RightArrow from 'react-icons/lib/fa/angle-right';
import LeftArrow from 'react-icons/lib/fa/angle-left';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-top: 1.5em;
`;

const Line = styled.div`
  width: 90%;
  height: 1px;
  background-color: ${theme.colors.grey2};
  margin: 15px 0 18px;
`;

const Title = styled.h2`
  @media ${medias.phoneMax} {
    font-size: 1.2em;
  }
  
  color: ${theme.colors.grey3};
  margin: 0 0 40px 0;
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <LeftArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        color: theme.colors.grey2,
        position: 'absolute',
        left: '2%',
        zIndex: 1,
        top: '52.5%',
      }}
      onClick={onClick}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <RightArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        color: theme.colors.grey2,
        position: 'absolute',
        right: '2%',
        zIndex: 1,
        top: '52.5%',
      }}
      onClick={onClick}
    />
  );
}

const Testimonials = props => {
  const { content } = props;
  const windowWidth = useWindowWidth();

  if (!content) {
    return null;
  }

  const slides = content.map(({ codDepoimento, autor, Depoimento }) => (
    <TestimonialsCard key={codDepoimento} name={autor} text={Depoimento} />
  ));

  let slidesToShow;
  if (slides.length === 1 || slides.length === 2) {
    slidesToShow = slides.length;
  } else {
    slidesToShow = windowWidth <= 768 ? 2 : 3;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    dotsClass: 'slick-dots',
  };

  return (
    <Wrapper>
      <Title>O que as pessoas estão dizendo?</Title>
      <StyledSlider {...settings}>{slides}</StyledSlider>
      <Line />
    </Wrapper>
  );
};

Testimonials.PropTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default Testimonials;
