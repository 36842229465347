import produce from 'immer';

export default (data, action) => {
  switch (action.type) {
    case 'setRegions':
      return produce(data, draft => {
        draft.regions = action.newRegions;
      });

    case 'setSegments':
      return produce(data, draft => {
        draft.segments = action.newSegments;
      });

    case 'setStates':
      return produce(data, draft => {
        draft.states = action.newStates;
      });

    case 'setCities':
      return produce(data, draft => {
        draft.cities = action.newCities;
      });

    default:
      return data;
  }
};
