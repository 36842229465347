import produce from 'immer';

export default (data, action) => {
  // ----> Logs
  // console.log('Called events reducer');
  // console.log(`Called action type ${action.type}`);
  // console.log('Prev state:');
  // console.table(faq);
  // console.log('Action payload:');
  // console.table(action);

  switch (action.type) {
    case 'setEvents':
      return produce(data, draft => {
        draft.events = action.newEvents;
      });

    case 'clearEvents':
      return produce(data, draft => {
        draft.events = null;
        draft.eventRegions = null;
        draft.checklist = null;
        draft.avaliableSegments = null;
      });

    case 'setEventRegions':
      return produce(data, draft => {
        draft.eventRegions = action.newEventRegions;
      });

    case 'clearEventRegions':
        return produce(data, draft => {
          draft.eventRegions = null;
        });

    case 'setChecklist':
      return produce(data, draft => {
        draft.segments = action.newChecklist;
      });

    case 'setAvaliableSegments':
      return produce(data, draft => {
        draft.segments = action.newAvaliableSegments;
      });

    default:
      return data;
  }
};
