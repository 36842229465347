import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { medias } from '../utils/mediaQueries';
import theme from '../styles/theme';

const StyledTitle = styled.div`
  @media ${medias.laptopMax} {
    font-size: 32px;
  }

  @media ${medias.tabletMax} {
    font-size: 28px;
  }
  @media ${medias.smallerTabletMax} {
    font-size: 24px;
  }

  @media ${medias.phoneMax} {
    font-size: 20px;
  }

  font-size: 32px;
  margin-left: 0.5px;
  font-weight: ${theme.headingWeight * 1.4};
  text-align: left; 
  color: ${theme.colors.secondary};
`;

const StyledSubtitle = styled.div`
  @media ${medias.laptopMin} {
    width: 100%;
    font-size: ${theme.fontSize * 1.2}px;
  }

  @media ${medias.phoneMax} {
    font-size: 1em;
  }

  font-size: ${theme.fontSize * 1.1}px;
  text-align: left;
`;

const StyledLine = styled.div`
  @media ${medias.laptopMin} {
    margin-top: 27px;
    height: 6px;
    width: 118px;
  }

  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 1px;
  height: 2px;
  width: 60px;
  background-color: ${theme.colors.primary};
`;

const StyledLink = styled.a`
  color: ${theme.colors.text};
  font-weight: bold;
  font-size: ${theme.fontSize * 1.1 + 2}px;
`;

export class TitleSubtitle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <StyledTitle>COMO FUNCIONA</StyledTitle>
        <StyledLine />
        <StyledSubtitle>
          Encontre fornecedores para o seu casamento qualificados e homologados
          pelo{' '}
          <StyledLink
            href="http://certificadotrust.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trust
          </StyledLink>{' '}
          e ainda tenha acesso à benefícios exclusivos. Feche contrato com
          fornecedores da Rede, acumule pontos e troque por prêmios.
        </StyledSubtitle>
      </div>
    );
  }
}

TitleSubtitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
};

export default TitleSubtitle;
