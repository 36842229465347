import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import Arabesco from '../images/arabescos.svg';
import { hexToRgb } from '../utils/hexToRgb';
import { ClosedSegmentsData } from './';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 9em;
  border: 1px solid ${theme.colors.grey1};
  border-radius: 20px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 4px 8px 0 ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const Title = styled.h2`
  font-size: ${theme.fontSizeXlg}px;
  color: ${theme.colors.grey3};
  text-align: center;
  font-weight: bold;
  margin: 2.5em 0 0.75em 0;
`;

export const ClosedSegments = ({ data, segments }) => {

  return (
    <Wrapper>
      <Title>Segmentos Contratados</Title>
      <Card>
        <Row>
          <ClosedSegmentsData
            title="Fase Inicial"
            totalSegments={data ? data.mappedInitialStage : null}
            closedSegments={segments ? segments.mappedInitialStage.length : null}
            tooltipText="Segmentos que sugerimos contratar no mínimo com 12 meses de antecedência"
          />
          <ClosedSegmentsData
            title="Fase Intermediária"
            totalSegments={data ? data.mappedMiddleStage : null}
            closedSegments={segments ? segments.mappedMiddleStage.length : null}
            border
            tooltipText="Segmentos que sugerimos contratar no mínimo com 8 meses de antecedência"
          />
          <ClosedSegmentsData
            title="Fase Final"
            totalSegments={data ? data.mappedFinalStage : null}
            closedSegments={segments ? segments.mappedFinalStage.length : null}
            tooltipText="Segmentos que sugerimos contratar no mínimo com 4 meses de antecedência"
          />
        </Row>
        <AssetContainer />
      </Card>
    </Wrapper>
  );
};

export default ClosedSegments;
