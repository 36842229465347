import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { DataMoneyMask } from '.';
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.text_heading};
  margin-top: 2em;
  padding-top: 0.8em;
`;

const HalfOverviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  ${({ border }) =>
    border &&
    `
    border-top: 2px solid ${theme.colors.grey3};
  `}
`;


const TotalValueText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.white};
  justify-content: center;
  text-align: center;
`;

/*const SegmentsText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSizeXlg}px;
  color: ${theme.colors.primary};
`;*/

const BoldValue = styled.strong`
  color: ${theme.colors.primary};
`;

export const DataBalance = ({
  value,
  hiredSegments,
  insertedSegments,
  activeSegments,
}) => {
  return (
    <Wrapper>
      <HalfOverviewContainer>
        <TotalValueText>
          Contratado:{' '}
          <BoldValue>
            R$ <DataMoneyMask value={value} />
          </BoldValue>
        </TotalValueText>
      </HalfOverviewContainer>
      <HalfOverviewContainer border>
        <TotalValueText>
          <BoldValue>{hiredSegments}</BoldValue> de{' '}
          <BoldValue>{insertedSegments}</BoldValue> segmentos contratados
            <br />
            <BoldValue>{activeSegments - insertedSegments}</BoldValue> segmentos
            não estão em seu checklist.
          <br />
          Adicione segmentos clicando sobre eles na lista abaixo
        </TotalValueText>
      </HalfOverviewContainer>
    </Wrapper>
  );
};

export default DataBalance;
