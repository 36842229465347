import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { DataMoneyMask } from '.';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.text_heading};
  margin-top: 2em;
  padding-top: 0.8em;
`;

const HalfOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  ${({ border }) =>
    border &&
    `
    border-top: 2px solid ${theme.colors.grey3};
  `}
`;

const PrizeText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSizeMed}px;
  margin: ${({ last }) => (last ? '0' : '0.5em')} 0 0.5em 0;
  color: ${theme.colors.white};
`;

const PrizeTotalText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSizeXlg}px;
  color: ${theme.colors.primary};
`;

const BoldValue = styled.strong`
  color: ${theme.colors.primary};
`;

export const ContractsReports = ({ data }) => {
  const { unconfirmed, confirmed, finalized, total } = data;

  return (
    <Wrapper>
      <HalfOverviewContainer>
        <PrizeText>
          Contratos não confirmados:{' '}
          <BoldValue>
            R$ {unconfirmed ? <DataMoneyMask value={unconfirmed} /> : 0}
          </BoldValue>
        </PrizeText>
        <PrizeText>
          Contratos confirmados:{' '}
          <BoldValue>
            R$ {confirmed ? <DataMoneyMask value={confirmed} /> : 0}
          </BoldValue>
        </PrizeText>
        <PrizeText>
          Contratos finalizados:{' '}
          <BoldValue>
            R$ {finalized ? <DataMoneyMask value={finalized} /> : 0}
          </BoldValue>
        </PrizeText>
      </HalfOverviewContainer>
      <HalfOverviewContainer border>
        <PrizeTotalText>
          Total de contratos:{' '}
          <strong>R$ {total ? <DataMoneyMask value={total} /> : 0}</strong>
        </PrizeTotalText>
      </HalfOverviewContainer>
    </Wrapper>
  );
};

export default ContractsReports;
