import React from 'react';
import styled from 'styled-components';

import { AdvantagesDetails } from '.';
import ModalContainer from './ModalContainer';
import { medias } from '../utils/mediaQueries';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 90vw;
  height: 19vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${medias.smallerTabletMax} {
    height: 40vw;
    width: 65vw;
    }
`;

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  height: 100%;
`;

const AdvantagesDetailsModal = ({
  discount,
  categoryForMore,
  description,
  resume,
  opened,
  closeModal,
  image,
}) => (
  <ModalContainer opened={opened} closeModal={closeModal}>
    <Wrapper>
      {image && (
        <ImageContainer>
          <Image src={image} alt="Imagem do benefício" />
        </ImageContainer>
      )}
      <DetailsContainer>
      <AdvantagesDetails
        discount={discount}
        category={categoryForMore}
        details={description ? description.split('<br />') : ''}
        summary={resume ? resume.split('<br />'): ''}
      />
      </DetailsContainer>
    </Wrapper>
  </ModalContainer>
);

export default AdvantagesDetailsModal;
