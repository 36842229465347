import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SliderComponent from './Slider';

const RenderedSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      bride: file(relativePath: { eq: "brideslide1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      wedding: file(relativePath: { eq: "brideslide2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      event: file(relativePath: { eq: "event.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  // Mock data
  const content = [
    {
      title: 'Planeje seu casamento em um só lugar',
      subtitle: 'Com fornecedores de ',
      styledSubtitle: 'qualidade e confiança',
      text: 'Encontre fornecedores homologados:',
      showSearch: true,
      backgroundImg: data.bride.childImageSharp.fluid,
    },
    {
      title: 'Planeje seu casamento em um só lugar',
      subtitle: 'Com fornecedores de ',
      styledSubtitle: 'qualidade e confiança',
      text: 'Encontre fornecedores homologados:',
      showSearch: true,
      backgroundImg: data.wedding.childImageSharp.fluid,
    },
    {
      title: '',
      subtitle: '',
      styledSubtitle: '',
      text: '',
      showSearch: false,
      link: 'https://subscribe.vestidasdebranco.com.br/21/',
      backgroundImg: data.event.childImageSharp.fluid,
    },
  ];

  return <SliderComponent content={content} />;
};

export default RenderedSlider;
