import to from './to'

const resolveFetch = async (res) => {
  const json = await res.json()
  if (!res.ok) {
    throw json
  }
  return json
}

const getConfig = (config, payload) =>
  payload ? { ...config, ...{ body: JSON.stringify(payload) } } : config

export const request = async (
  url,
  method,
  payload,
  token,
) => {
  const config = {
    method,
    headers: { Authorization: token }, // @TODO Add support for multiple auth types
    credentials: 'same-origin',
  }

  const [results, err] = await to(
    fetch(url, getConfig(config, payload)).then(resolveFetch),
  )

  if (err) {
    throw err
  }

  return results
}

export const get = ({ url, token }) => request(url, 'GET', undefined, token)

export const post = ({ url, payload, token }) =>
  request(url, 'POST', payload, token)

export const put = ({ url, payload, token }) =>
  request(url, 'PUT', payload, token)

export const destroy = ({ url, token }) => request(url, 'DELETE', undefined, token)
