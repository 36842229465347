import React, { Fragment } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/pt-br'; // without this line it didn't work

import { theme } from '../styles/theme';
import ModalContainer from './ModalContainer';

moment.locale('pt-br');

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grey2};
  margin: 0 0 0.5em 0;

  p {
    color: ${theme.colors.grey3};
    font-size: 17px;
    margin: 0 0 0.2em 0;

    strong {
      color: ${theme.colors.text_heading};
    }
  }
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 0.5em 0;
  ${({ event }) =>
    event &&
    `border-bottom: 1px solid ${theme.colors.grey2}; padding: 0 0 0.5em 0; `}

  p {
    color: ${theme.colors.grey3};
    font-size: 18px;
    margin: 0;
  }
`;

// const StyledButton = styled(props => <Button {...props} />)`
//   border: 2px solid ${theme.colors.error};
//   color: ${theme.colors.error};
//   background-color: transparent;
//   width: 45%;
// `;

const ClientName = styled.p`
  font-size: 18px;
  color: ${theme.colors.grey3};
  align-self: flex-start;
  text-decoration: underline;
  margin: 0 0 0.5em 0;
`;

//@TODO remove mocks when implementing full page

const SupplierDateModal = ({ closeModal, opened, selectedDay, events }) => {
  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco={true}>
      <Wrapper>
        <HeaderContainer>
          <p>
            <strong>{selectedDay.format('DD')}</strong>
          </p>
          <p>{selectedDay.format('dddd').toUpperCase()}</p>
        </HeaderContainer>
        {events &&
          events.map((item, index) => (
            <Fragment key={item.id}>
              <ClientName>{item.clientName}</ClientName>
              <RowContainer event={events.length - 1 !== index}>
                <p>{item.nomeEvento}</p>
                <p>Local: {item.cerimonyLocal}</p>
              </RowContainer>
            </Fragment>
          ))}
        {/* <RowContainer>
          <StyledButton text="OPORTUNIDADE" click={() => console.log('a')} />
          <StyledButton text="NOIVOS" click={() => console.log('a')} />
        </RowContainer> */}
      </Wrapper>
    </ModalContainer>
  );
};

export default SupplierDateModal;
