import React from 'react';
import styled from 'styled-components';
import {
  SupplierProfileDataCard,
  SupplierCreateUsers,
  SupplierPointsOutgoing,
} from './';
import { theme } from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { medias } from '../utils/mediaQueries';
import Arabesco from '../images/arabescos.svg';


const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
  padding: 0.8em 0 0.8em 0;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }
  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; '
      : 'margin-bottom: 1em;'}
`;

const ItemTitle = styled.h3`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin-top: 0.6em;
`;

const SupplierProfileData = () => (
  <Card>
    <AssetContainer />
    <ItemTitle>Dados Cadastrais e Histórico</ItemTitle>
    <SupplierProfileDataCard />
    <SupplierCreateUsers />
    <SupplierPointsOutgoing />
  </Card>
);

export default SupplierProfileData;
