import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tabs } from './';
// import { hexToRgb } from '../utils/hexToRgb';
import { theme } from '../styles/theme';
// import { StyledHeart, StyledOutline } from './styled';

const TextContainer = styled.div`
  border: 1px solid ${theme.colors.grey2};
  border-radius: 3px;
  padding: 1em;
  color: ${theme.colors.grey3};
  font-size: ${theme.fontSizeMed};
  width: 100%;
`;

const Title = styled.span`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeMed};
  font-weight: bold;
  display: block;
  margin-bottom: 0.5em;
`;

const SmallTitle = styled.span`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize};
  font-weight: bold;
  display: block;
  padding-top: 0.2em;
  margin-bottom: 0.5em;
`;

/*const AdBar = styled.div`
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  font-size: ${theme.fontSizeMed};
  font-weight: 300;
  width: 90%;
  padding: 1em;
  cursor: pointer;
  margin-bottom: 1em;
  text-align: center;
`;

const YellowText = styled.span`
  color: ${theme.colors.primary};
  font-weight: bold;
  margin: 0 0.3em;
`;*/

const ListItem = styled.li`
  text-decoration: none;
  list-style: none;
`;

const TermsOfUseList = styled.ul`
line-height: 1em;
text-align: left;
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  margin-top: 1.5em;
`;

const tabsContent = [
  {
    id: 0,
    title: 'DESCRIÇÃO',
  },
  {
    id: 1,
    title: 'RESUMO',
  }
];

const termsOfUse = (
  <TermsOfUseList>
    <li>
      Clique em Eu Quero caso deseje aproveitar esse Benefício, dessa forma sua
      solicitação de orçamento será baseado nele.
    </li>
    <li>
      Caso já tenha fechado contrato com esse fornecedor não há a necessidade de
      clicar (o Eu Quero é apenas para que o fornecedor envie o orçamento). O
      contrato fechado pode ser informado no Menu Orçamentos, dentro do segmento
      adequado e assim seus pontos serão creditados, desde que o contrato tenha
      sido fechado após a sua entrada na Rede.
    </li>
    <li>
      Benefício sujeito a disponibilidade de datas por parte do fornecedor.
    </li>
    <li>O Benefício é nominal e poderá ser utilizado apenas por vocês.</li>
    <li>Agendamento obrigatório.</li>
    <li>Não acumulativo com outras promoções.</li>
    <li>
      As imagens contidas nesta oferta são meramente ilustrativas, salvo aquelas
      expressamente identificadas como sendo reais.
    </li>
  </TermsOfUseList>
);

const ContentComponent = ({ title, text }) => (
  <TextContainer>
    <Title>{title}</Title>
    {text}
  </TextContainer>
);

const AdvantagesDetails = ({ details, summary, /*discount, category*/ }) => {
  const resumo = (
    <>
    <ul>
      {summary
        .filter(i => i !== '<br />')
        .map((i, key) => (
          <ListItem key={key}>{i}</ListItem>
        ))}
    </ul>
    <SmallTitle style={{ paddingTop: '1em' }}>TERMOS DE USO</SmallTitle>
    {termsOfUse}
    {/*<SmallTitle>Valor do Benefício para você:</SmallTitle>
    <SmallTitle>R${discount}</SmallTitle>
    */}
    </>
  );

  const desc = (
    <>
    {details && <p style={{ paddingBottom: '0.8em' }}>{details}</p>}
    {/*<SmallTitle>Valor do Benefício para você:</SmallTitle>
    <SmallTitle>R${discount}</SmallTitle>
    */}
    </>
  );

  const components = [
    <ContentComponent key={0} title="Descrição do Benefício" text={desc} />,
    <ContentComponent key={1} title="Resumo" text={resumo} />,
  ];

  return (
    <>
      <StyledTabs
        content={tabsContent}
        components={components}
        onClick={() => {}}
        lineWidth={'100%'}
      />
      {/*moreDiscount && category && (
        <AdBar>
          Economize mais
          <YellowText>R${moreDiscount && moreDiscount.toFixed(2)}</YellowText>
          tornando-se
          <YellowText>NOIVOS {category}</YellowText>
        </AdBar>
      )}
      {moreDiscount && !category && (
        <AdBar>
          Economia de
          <YellowText>R${moreDiscount && moreDiscount}</YellowText>
        </AdBar>
      )*/}
    </>
  );
};

AdvantagesDetails.propTypes = {
  details: PropTypes.string.isRequired,
};

export default AdvantagesDetails;
