import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import {
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialInput,
} from '../components';
import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';

import { useStateValue } from '../state';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import Arabesco from '../images/arabescos.svg';
import { hexToRgb } from '../utils/hexToRgb';
import { REGEX_EMAIL } from '../utils/regex';
import { StyledButton } from '../components/styled';
import { useWindowWidth } from '../hooks';
import { siteContent, joinUs } from '../services/api';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import phoneMask from '../utils/phoneMask';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 0 50px 0;
  width: 100%;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;

  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;'
      : 'margin-bottom: 1em;'}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  @media ${medias.phoneMax} {
    align-items: flex-end;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${theme.colors.grey2};
`;

const Title = styled.h4`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeLg * 0.85}px;
  margin: 0 0 1em 0;
`;

const StyledHalfInput = styled(InputMask)`
  width: 45%;
`;

const StyledFormControl = styled(FormControl)`
  width: 45%;
`;

const FranchiseForm = () => {
  const [supplierOrigin, setSupplierOrigin] = useState([]);
  const [availableCities, setAvailableCities] = useState(null);
  const [
    {
      generalData: { states },
      forms: { franchiseForm },
    },
    dispatch,
  ] = useStateValue();

  const screenWidth = useWindowWidth();
  // eslint-disable-next-line
  const isMobile = screenWidth < 1024;

  const {
    cnpj,
    publicityName,
    state,
    city,
    comercialContact,
    comercialPhone,
    comercialEmail,
    resume,
    codSupplierOrigin,
  } = franchiseForm;

  useFetch({
    url: urls.siteContent.states,
    method: method.GET,
    condition: () => !states,
    callback: data =>
      dispatch({
        type: 'setStates',
        newStates: data,
      }),
  });

  const SendFranchiseForm = async () => {
    // eslint-disable-next-line
    let { state, city, cnpj, ...payload } = franchiseForm;
    const body = {
      ...payload,
      codIBGE: city,
      cnpj: cnpj.replace(/[^0-9 ]/g, ''),
    };
    const response = await joinUs.franchise(body);
    response.ok && response.data
      ? toast.success('Cadastro submetido com sucesso!', {
          onClose: () => window.location.reload(),
        })
      : toast.error('Não foi possivel cadastrar!');
  };

  useEffect(() => {
    const fetchSupplierOrigin = async () => {
      const response = await siteContent.suppliersOrigin();
      if (response.ok && response.data) {
        setSupplierOrigin(response.data);
      }
    };

    fetchSupplierOrigin();
  }, []);

  const handleChange = (newText, field) => {
    dispatch({
      type: 'changeFranchiseForm',
      newText,
      field,
    });
  };

  const fetchCities = async codUf => {
    const response = await siteContent.cities(codUf);
    if (response.ok && response.data) {
      setAvailableCities(response.data);
    }
  };

  return (
    <Wrapper>
      <Card>
        <AssetContainer />
        <TitleContainer>
          <Title>Formulário de Interesse em Franquias</Title>
        </TitleContainer>
        <Line />
        <InputContainer>
          <RowContainer>
            <MaterialInput
              style={{ width: '45%' }}
              label="Nome da empresa"
              value={publicityName}
              onChange={event =>
                handleChange(event.target.value, 'publicityName')
              }
            />
            <StyledHalfInput
              label="CNPJ"
              mask="99.999.999/9999-99"
              value={cnpj}
              onChange={event => handleChange(event.target.value, 'cnpj')}
            >
              {inputProps => <MaterialInput {...inputProps} />}
            </StyledHalfInput>
          </RowContainer>
          <RowContainer>
            <StyledFormControl>
              <MaterialInputLabel htmlFor="state-simple">
                Estado
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event => {
                  fetchCities(event.target.value);
                  handleChange(event.target.value, 'state');
                }}
                value={state}
                inputProps={{
                  name: 'state',
                  id: 'state-simple',
                }}
              >
                {states
                  ? states.map(({ name, cod }) => (
                      <MaterialMenuItem key={cod} value={cod}>
                        {name}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </StyledFormControl>
            <StyledFormControl>
              <MaterialInputLabel htmlFor="city-simple">
                Cidade
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event => handleChange(event.target.value, 'city')}
                value={city}
                inputProps={{
                  name: 'city',
                  id: 'city-simple',
                }}
              >
                {availableCities
                  ? availableCities.map(({ nomeCidade, codCidadeIBGE }) => (
                      <MaterialMenuItem
                        key={codCidadeIBGE}
                        value={codCidadeIBGE}
                      >
                        {nomeCidade}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </StyledFormControl>
          </RowContainer>
          <RowContainer>
            <MaterialInput
              style={{ width: '45%' }}
              label="Contato Principal"
              value={comercialContact}
              onChange={event =>
                handleChange(event.target.value, 'comercialContact')
              }
            />
            <MaterialInput
              style={{ width: '45%' }}
              label="Telefone"
              value={comercialPhone}
              onChange={event =>
                handleChange(phoneMask(event.target.value), 'comercialPhone')
              }
            />
          </RowContainer>
          <RowContainer>
            <MaterialInput
              style={{ width: '45%' }}
              label="E-mail"
              value={comercialEmail}
              onChange={event =>
                handleChange(event.target.value, 'comercialEmail')
              }
            />
            <StyledFormControl>
              <MaterialInputLabel htmlFor="know-simple">
                Onde conheceu
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event =>
                  handleChange(event.target.value, 'codSupplierOrigin')
                }
                value={codSupplierOrigin}
                inputProps={{
                  name: 'codSupplierOrigin',
                  id: 'know-simple',
                }}
              >
                {supplierOrigin &&
                  supplierOrigin.map(
                    ({ codOrigemFornecedor, OrigemFornecedor }) => (
                      <MaterialMenuItem
                        value={codOrigemFornecedor}
                        key={codOrigemFornecedor}
                      >
                        {OrigemFornecedor}
                      </MaterialMenuItem>
                    )
                  )}
              </MaterialSelect>
            </StyledFormControl>
          </RowContainer>
          <MaterialInput
            style={{ width: '100%' }}
            label="Sua experiẽncia no mercado de casamentos"
            value={resume}
            onChange={event => handleChange(event.target.value, 'resume')}
          />
          <StyledButton
            text="ENVIAR"
            click={() => {
              if (
                !publicityName ||
                !cnpj ||
                !city ||
                !comercialContact ||
                !comercialPhone ||
                !comercialEmail ||
                !resume ||
                !codSupplierOrigin
              ) {
                toast.error('Todos os campos são obrigatórios!');
                return false;
              } else SendFranchiseForm();

              if (!REGEX_EMAIL.test(comercialEmail)) {
                toast.error('Digite um e-mail válido!');
                return false;
              }

              return true;
            }}
          />
        </InputContainer>
        <AssetContainer bottom />
      </Card>
    </Wrapper>
  );
};

export default FranchiseForm;
