import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ModalContainer from './ModalContainer';
import { medias } from '../utils/mediaQueries';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled.a`
  display: ${props => (props.localExibicao === 'desktop' ? 'initial' : 'none')};

  @media ${medias.phoneMax} {
    display: ${props =>
      props.localExibicao === 'desktop' ? 'none' : 'initial'};
  }
`;

const StyledImage = styled.img`
  max-width: initial;
`;

const BannerModal = ({ closeModal, opened, banners = [] }) => {
  return (
    <ModalContainer opened={opened} closeModal={closeModal} banner>
      {banners.map(
        ({ codBannerSite, linkDestino, linkImagem, localExibicao }) => (
          <Wrapper key={codBannerSite}>
            <StyledLink
              href={linkDestino}
              target="_blank"
              rel="noopener noreferrer"
              localExibicao={localExibicao}
            >
              <StyledImage src={linkImagem}></StyledImage>
            </StyledLink>
          </Wrapper>
        )
      )}
    </ModalContainer>
  );
};

BannerModal.propTypes = {
  disabled: PropTypes.bool,
  closeModal: PropTypes.func,
  onClickConfirm: PropTypes.func,
};

export default BannerModal;
