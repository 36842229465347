import React from 'react';
import styled from 'styled-components';
import DownArrow from 'react-icons/lib/fa/angle-down';

import { theme } from '../../styles/theme';
import LoginForm from './LoginForm';

const Line = styled.div`
  width: 90%;
  height: 1px;
  background-color: ${theme.colors.grey2};
`;

const SectionContent = styled.button`
  display: flex;
  align-items: center;
  width: 90%;
  padding: 0.5rem 0;
  text-align: left;
  outline: none;
  font-weight: bold;
  font-size: 0.8rem;
  svg {
    flex: 0 0 1.5rem;
    margin-right: 0.75em;
  }
  span {
    flex: 1;
  }
`;

const StyledArrow = styled(({ className }) => (
  <DownArrow className={className} />
))`
  transform: ${({ isDown }) => (isDown ? 'rotate(180deg)' : 'none')};
  transition: 0.3s ease;
  &:hover {
    cursor: pointer;
  }
`;

const Section = props => {
  const { open, icon, text, action, supplier, franchise, bride } = props;

  return (
    <>
      <Line />
      <SectionContent onClick={action}>
        {icon}
        <span>{text}</span>
        <StyledArrow isDown={open} />
      </SectionContent>
      {open && (
        <LoginForm supplier={supplier} franchise={franchise} bride={bride} />
      )}
    </>
  );
};

export default Section;
