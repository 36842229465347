import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import moment from 'moment';
import 'moment/locale/pt-br'; // without this line it didn't work

import PublicHeader from './PublicHeader';
import { useStateValue } from '../../state';
import BrideHeader from './BrideHeader';
import { bride, supplier } from '../../services/api';
import SupplierHeader from './SupplierHeader';
import { checkSiteSelectedRegion, checkSupplierSelectedRegion } from '../../services/checkSession';
import checkSession, { isBrowser } from '../../services/checkSession';
import { toast } from 'react-toastify';

moment.locale('pt-br');

export const handleSession = date => {
  const dateMinus8Months = moment(date)
    .subtract(8, 'M')
    .format('YYYY-MM-DD');
  const dateMinus4Months = moment(date)
    .subtract(4, 'M')
    .format('YYYY-MM-DD');
  const todayDate = moment().format('YYYY-MM-DD');

  if (dateMinus8Months > todayDate) {
    return 'Fase Inicial';
  }

  if (dateMinus8Months < todayDate && dateMinus4Months > todayDate) {
    return 'Fase Intermediária';
  }

  if (dateMinus4Months < todayDate) {
    return 'Fase final';
  }
};

const Header = () => {
  const [
    {
      session,
      visibility,
      events: { events },
    },
    dispatch,
  ] = useStateValue();
  const isLoggedIn = !!session.userId || (isBrowser() && localStorage && localStorage.getItem('userId') !== null);
  const isBride = session.isBride || (isBrowser() && localStorage.getItem('isBride') === 'true');
  const { userId, isSupplier, supplierCategory } = session;
  
  const logOut = () => {
    dispatch({
      type: 'logout',
    });
    dispatch({
      type: 'clearEvents',
    });
    dispatch({
      type: 'clearEventRegions',
    });
    sessionStorage.removeItem('siteInput')
    sessionStorage.removeItem('siteSegmentId')
    navigate('/');
  };
  // Routine to update the session's global state
  useEffect(() => {
    const fetchEventRegions = async codEvento => {
      const response = await bride.eventRegions(codEvento);
      if (response.ok && response.data) {
        dispatch({
          type: 'setEventRegions',
          newEventRegions: response.data,
        });
      }
    };

    const fetchEvents = async () => {
      const response = await bride.brideEvents();
      if (response && response.ok && response.data) {
        dispatch({
          type: 'setEvents',
          newEvents: response.data,
        });
      }
      if (
        response.data &&
        response.data.message &&
        response.data.message === 'Missing authentication'
      ) {
        toast.info('A sua sessão expirou. Por favor faça login novamente');
        //logOut();
      }
    };

    const requestData = async () => {
      const id = userId || localStorage.getItem('userId');
      let brideFlag = isBride;
      let supplierFlag = isSupplier;

      if (!brideFlag) {
        brideFlag = isBrowser() && localStorage.getItem('isBride') === 'true';
      }

      if (!supplierFlag) {
        supplierFlag =
          isBrowser() && localStorage.getItem('isSupplier') === 'true';
      }

      if (brideFlag) {
        checkSession(dispatch, true);
        const response = await bride.getSessionData(id);
        if (response && response.ok && response.data) {
          const {
            selectedEvent,
            selectedRegion,
            inactive,
            eventType,
            date,
            points,
            firstLogin,
          } = response.data;

          if (
            response.data &&
            response.data.message === 'Missing authentication'
          ) {
            toast.info('A sua sessão expirou. Por favor faça login novamente');
            //logOut();
          }

          await fetchEventRegions(selectedEvent);

          const stage = handleSession(date);

          dispatch({
            type: 'updateSessionForm',
            eventType,
            date,
            eventFinished: date < moment().format('YYYY-MM-DD'),
            eventInactive: inactive === 1,
            points,
            firstLogin,
            selectedEvent,
            selectedRegion,
            stage,
          });
        }
        return;
      } else if (supplierFlag) {
        const response = await supplier.getSessionData(id);
        if (
          response.data &&
          response.data.message === 'Missing authentication'
        ) {
          toast.info('A sua sessão expirou. Por favor faça login novamente');
          //logOut();
        }
        if (response.ok && response.data) {
          const {
            vbs,
            supplierName,
            potential,
            contractsCount,
            totalContractsValue,
            roi,
            pointsBalance,
            supplierContracts,
            supplierLogo,
          } = response.data;

          dispatch({
            type: 'updateSessionForm',
            vbs,
            supplierName,
            potential,
            contractsCount,
            totalContractsValue,
            roi,
            pointsBalance,
            supplierContracts,
            supplierLogo,
          });
          if (!supplierCategory) {
            const { supplierContract, supplierRegion } = response.data;
            const sessionCallbackSupplierCategory =
              response.data.supplierCategory;
            localStorage.setItem('supplierRegion', supplierRegion);
            localStorage.setItem(
              'supplierCategory',
              sessionCallbackSupplierCategory
            );
            localStorage.setItem('supplierContract', supplierContract);
            checkSupplierSelectedRegion(dispatch);
          }
        }
        return;
      }
    };
    checkSiteSelectedRegion(dispatch);
    const fetchData = async () => {
      await requestData();
      if (!events && isBride) {
        await fetchEvents();
      }
    };
    fetchData();
  }, [userId, dispatch, events, isBride, isSupplier, supplierCategory]);

  if (isLoggedIn && isSupplier) {
    return <SupplierHeader session={session} logOut={logOut} />;
  }

  if (isLoggedIn && isBride) {
    return (
      <BrideHeader session={session}  logOut={logOut} />
    );
  }

  // @TODO: implementar franqueado
  // if (isLoggedIn && isFranchise) {
  //   return <FranchiseHeader session={session} logOut={logOut} />
  // }

  // @TODO: implementar fallback pra caso isLoggedIn && !isSupplier && !isBride
    return <PublicHeader dispatch={dispatch} visibility={visibility} siteRegion={isBrowser() && sessionStorage.siteSelectedRegion} />;
};

export default Header;
