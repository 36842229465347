import React from 'react';
import NumberFormat from 'react-number-format';

const DataMoneyMask = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale
    isNumericString
    renderText={value => <>{value}</>}
  />
);

export default DataMoneyMask;
