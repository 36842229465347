import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { navigate } from 'gatsby';

import {
  MaterialInput,
  MaterialMenuItem,
  Button,
  ConfirmModal,
  QuotesModal,
  MoneyMask,
  ContestModal,
  Loader,
} from '.';
import {
  FavoriteStarIcon,
  FavoriteStarEmptyIcon,
  TrashIcon,
  FilledCheckListIcon,
} from './icons';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { bride } from '../services/api';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { useStateValue } from '../state';
import Tooltip from '@material-ui/core/Tooltip';
import { FaPencilSquare } from 'react-icons/lib/fa';

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  ${({ last }) =>
    last &&
    `
      border-bottom: 2px solid ${theme.colors.grey1};
      padding-bottom: 1em;
      margin-bottom: 1em;
  `}
`;

const HalfContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: ${({ last }) => (last ? 'flex-end' : 'flex-start')};
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
  }

  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 0.5em;
`;

const BudgetText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    margin: 0 0.5em 0 0;
  }

  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.gray3};
  margin: 0 2.5em 0 0em;

  &:hover {
    cursor: pointer;
  }
`;

const BudgetNumber = styled.strong`
  font-size: ${theme.fontSizeMed}px;
  color: ${({ favorite }) =>
    favorite ? theme.colors.primary : theme.colors.gray3};
  font-weight: ${({ favorite }) => (favorite ? 'bold' : 'normal')};
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledButton = styled(Button)`
  @media ${medias.phoneMax} {
    font-size: ${({ blocked }) => (blocked ? '0.4' : '0.6')}em;
    height: 30px;
  }

  background-color: ${theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: ${theme.colors.white};
  ${({ red }) => red && `background-color: ${theme.colors.white}; border: 0;`}
  ${({ priorityBlocked }) =>
    priorityBlocked &&
    `background-color: #ef9574; color: black;`}
  font-size: 0.8em;
  width: 20%;
  height: 42px;
  text-align: center;
  padding: 0;
`;

const StyledFavoriteStarIcon = styled(FavoriteStarIcon)`
  @media ${medias.phoneMax} {
    margin: 0;
  }

  margin: 0 1em 0 0;
  cursor: pointer;
`;

const StyledFavoriteStarEmptyIcon = styled(FavoriteStarEmptyIcon)`
  @media ${medias.phoneMax} {
    margin: 0;
  }

  margin: 0 1em 0 0;
  cursor: pointer;
`;

const StyledCheckIcon = styled(FilledCheckListIcon)`
  @media ${medias.phoneMax} {
    margin: 0;
  }

  margin: 0 1em 0 0;
`;

const StyledPencilIcon = styled(FaPencilSquare)`
  @media ${medias.phoneMax} {
    margin: 0;
  }

  margin: 0 1em 0 0;
`;

export const ChecklistCard = ({ data, updatePage }) => {
  const {
    id,
    segment: { Segmento, linkIcone, codSegmento },
    supplier,
    favorite,
    finalValue,
    finished,
    vdb,
    supplierIndicated,
    event,
  } = data;

  const [opened, setOpened] = useState(false);
  const [contestModal, setContestModal] = useState(false);
  const [formSupplier, setFormSupplier] = useState(supplier || '');
  const [value, setValue] = useState(String(finalValue));
  const [contested, setContested] = useState(finished === 3);
  const [quotesModal, setQuotesModal] = useState(false);
  const [localFavorite, setLocalFavorite] = useState(favorite === 1);
  const [budgetCount, setBudgetCount] = useState(null);
  const [stateSuggestions, setSuggestions] = useState([]);
  const [suggestionsData, setSuggestionsData] = useState(null);
  const [isEditable, setEditable] = useState(true);
  const [contestLoading, setContestLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const [
    {
      session: { selectedRegion, selectedEvent },
    },
  ] = useStateValue();

  useFetch({
    url: urls.bridesContent.getQuotes(codSegmento),
    method: method.GET,
    condition: () => !budgetCount,
    callback: data => setBudgetCount(data),
  });

  useFetch({
    url: urls.bridesContent.getRecomendations(codSegmento, selectedRegion),
    method: method.GET,
    condition: () => !suggestionsData,
    callback: data => setSuggestionsData(data),
  });

  useEffect(() => {
    if (finalValue === null) {
      setValue(null);
    }
  }, [finalValue]);

  const removeEvent = async () => {
    setRemoveLoading(true);
    const response = await bride.deleteSegmentChecklist(id);

    response.ok && response.data
      ? sucessResponse('Segmento removido com sucesso!')
      : toast.error('Erro ao deletar segmento!');
    setRemoveLoading(false);
  };

  const openModal = () => {
    setOpened(true);

    window.scrollBy({
      top: -(window.innerHeight * 2.5),
      behavior: 'smooth',
    });
  };

  const onClose = () => {
    setOpened(false);
  };

  const handleClickFavorite = async id => {
    setLocalFavorite(!localFavorite);
    const response = await bride.favoriteSegmentChecklist(id, selectedEvent);

    if (!response.ok) {
      toast.error(
        'Erro ao marcar o segmento como favorito. Por favor, tente novamente!'
      );
      setLocalFavorite(localFavorite);
    }
  };

  const handleButtonText = blocked =>
    blocked ? (vdb === 1 ? 'CONTESTAR' : '') : 'INSERIR CONTRATO';

  const handleContestSegment = async value => {
    const {
      segment: { codSegmento },
      contract: { codContratoEvento },
    } = data;

    const body = {
      checklistId: id,
      segmentId: codSegmento,
      value,
      contractId: codContratoEvento,
    };

    if (Number(value) === finalValue) {
      toast.error('Valor a ser contestado é igual ao valor já existente');
      return;
    }

    setContestLoading(true);
    const response = await bride.contestContract(body);

    if (!response.ok) {
      toast.error('Erro ao contestar o segmento!');
      setContestLoading(false);
      setContestModal(false);

      return;
    }

    toast.success('Segmento contestado com sucesso!', {
      onClose: updatePage(),
    });

    setContestLoading(false);
    setContestModal(false);
    setContested(true);
  };

  const sucessResponse = message => {
    toast.success(message);
    updatePage();
  };

  const enableChecklist = async () => {
    const response = await bride.enableChecklist({
      checklistId: id,
    });

    response.ok
      ? sucessResponse('Checklist liberado para editar')
      : toast.error('Erro ao editar checklist');
  };

  const searchBySegment = (segmentId, name) => {
    navigate('app/noivos/fornecedores', {
      state: { input: segmentId, isSegment: true, segmentName: name },
    });
  };

  const handleButtonClick = async (blocked, index) => {
    const prizes = await bride.getPrizes(event.codEvento, 1, 0, 1);

    if (vdb === 1) {
      if (prizes?.data?.count > 0) {
        toast.error(
          'Você não pode contestar este contrato, uma vez que já possui prêmios resgatados na plataforma. Por favor, entre em contato com nosso atendimento para mais informações',
          { autoClose: 10000 }
        );
        return false;
      }
      blocked ? setContestModal(true) : handleHireSupplier(index);
    } else {
      finished === 0 ? handleHireSupplier() : enableChecklist();
    }
  };

  const handleHireSupplier = async () => {
    if (!formSupplier || !value) {
      return toast.error('Por favor, preencha todos os campos!');
    }

    /*
    let normalizedValue;
    normalizedValue = value.replace('.', '');
    normalizedValue = normalizedValue.replace(',', '.');
    */

    const body = {
      supplier: formSupplier,
      contractValue: value,
      segmentId: codSegmento,
    };

    toast.info('Por favor, aguarde...');
    const response = await bride.hireSupplier(body);
    response.ok && response.data
      ? sucessResponse('Contrato registrado!')
      : toast.error('Não foi possivel registrar o contrato!');
  };

  if (isEditable && supplier && finalValue) {
    setFormSupplier(supplier);
    setValue(finalValue);
    setEditable(false);
  }

  const suggestions = suggestionsData
    ? suggestionsData.map(item => ({ label: item }))
    : [];

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderInputComponent = inputProps => {
    const { inputRef = () => {}, ref, ...other } = inputProps;

    return (
      <MaterialInput
        fullWidth
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
        }}
        {...other}
      />
    );
  };

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
      <MaterialMenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map(part => (
            <span
              key={part.text}
              style={{ fontWeight: part.highlight ? 500 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </MaterialMenuItem>
    );
  };

  const getSuggestions = value => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
          const keep =
            count < 5 && suggestion.label.toLowerCase().includes(inputValue);

          if (keep) {
            count += 1;
          }

          return keep;
        });
  };

  const getSuggestionValue = suggestion => suggestion.label;

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  const styles = {
    container: {
      position: 'relative',
      width: '33%',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      fontSize: '0.5em',
    },
  };

  if (contestLoading || removeLoading) {
    return <Loader />;
  }

  return (
    <>
      <ConfirmModal
        closeModal={onClose}
        opened={opened}
        onClickConfirm={() => removeEvent()}
        text={'Você tem certeza que quer deletar esse item?'}
      />
      <ContestModal
        closeModal={() => setContestModal(false)}
        opened={contestModal}
        text={'Você tem certeza que quer deletar esse item?'}
        loading={contestLoading}
        onClickButton={value => handleContestSegment(value)}
      />
      <RowContainer>
        <HalfContainer>
          {(finished === 1 || finished === 2 || finished === 4) && (
            <StyledCheckIcon />
          )}
          {finished !== 1 &&
            finished !== 2 &&
            (localFavorite ? (
              <StyledFavoriteStarIcon onClick={() => handleClickFavorite(id)} />
            ) : (
              <StyledFavoriteStarEmptyIcon
                onClick={() => handleClickFavorite(id)}
              />
            ))}
          {/* {localFavorite ? (
            <StyledFavoriteStarIcon onClick={() => handleClickFavorite(id)} />
          ) : (
            <StyledFavoriteStarEmptyIcon
              onClick={() => handleClickFavorite(id)}
            />
          )} */}
          <StyledIcon
            src={linkIcone}
            onClick={() => searchBySegment(codSegmento, Segmento)}
          />
          <ItemText>{Segmento}</ItemText>
        </HalfContainer>
        <HalfContainer last>
          <BudgetText onClick={() => setQuotesModal(true)}>
            <BudgetNumber>
              {budgetCount ? budgetCount.length : '...'}
            </BudgetNumber>
            &nbsp;Orçamentos +
          </BudgetText>
          {(finished === 1 || finished === 2) &&
            vdb !== 1 &&
            !supplierIndicated && (
              <>
                <Tooltip title="Editar">
                  <div>
                    <StyledPencilIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => setQuotesModal(true)}
                    />
                  </div>
                </Tooltip>
              </>
            )}
          {finished === 0 && (
            <>
              <Tooltip title="Excluir esse segmento pois não teremos no nosso casamento">
                <div>
                  <TrashIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => openModal(id)}
                  />
                </div>
              </Tooltip>
            </>
          )}
        </HalfContainer>
      </RowContainer>
      <RowContainer last>
        {finished === 4 ? (
          <MaterialInput
            style={{ width: '33%' }}
            label="Valor contratado: (obrigatório)"
            onChange={event => setFormSupplier(event.target.value)}
            value={formSupplier}
          />
        ) : (
          <Autosuggest
            {...autosuggestProps}
            inputProps={{
              style: { width: '100%' },
              id: id,
              label: 'Fornecedor (obrigatório)',
              value: formSupplier,
              disabled: finished !== 0,
              onChange: (event, { newValue }) => setFormSupplier(newValue),
            }}
            theme={{
              container: styles.container,
              suggestionsContainerOpen: styles.suggestionsContainerOpen,
              suggestionsList: styles.suggestionsList,
              suggestion: styles.suggestion,
            }}
            renderSuggestionsContainer={options => (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            )}
          />
        )}
        <MaterialInput
          style={{ width: '33%' }}
          label="Valor contratado: (obrigatório)"
          onChange={event => setValue(event.target.value)}
          value={value}
          disabled={finished !== 0}
          InputProps={{
            inputComponent: MoneyMask,
          }}
        />
        {!supplierIndicated &&
          (contested ? (
            <StyledButton text="CONTESTADO" blocked disabled />
          ) : finished === 2 ? (
            <StyledButton text="AGUARDANDO PONTUAÇÃO" blocked disabled />
          ) : (
            <StyledButton
              text={handleButtonText(finished === 1)}
              click={() => handleButtonClick(finished === 1)}
              blocked
              priorityBlocked={finished !== 1}
              red={finished === 1 && vdb !== 1}
              disabled={finished === 1 && vdb !== 1}
            />
          ))}
      </RowContainer>
      <QuotesModal
        opened={quotesModal}
        segmentId={codSegmento}
        finished={finished === 1 || finished === 2}
        closeModal={() => setQuotesModal(false)}
      />
    </>
  );
};

export default ChecklistCard;
