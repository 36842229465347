import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { theme } from '../styles/theme';
import { bride } from '../services/api';
import { Button, MaterialInput } from './';
import { medias } from '../utils/mediaQueries';
import ModalContainer from './ModalContainer';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RadioSection = styled.fieldset`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  width: 90%;
  padding: 0;
  margin: 1em 0;

  @media ${medias.phoneMax} {
    flex-direction: column;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5em;
`;

const RadioInput = styled.input`
  margin: 0;
  margin-right: 0.3em;
`;

const RadioLabel = styled.label`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin: 0;
`;

const ContactLabel = styled.span`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  margin-right: 1em;
`;

const FullButton = styled(props => <Button {...props} />)`
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 100%;
  margin: 0 1em ${({ confirm }) => (confirm ? '1em' : '0')} 1em;
`;

const AdvantageBudgetModal = ({
  closeModal,
  opened,
  advantageData,
  selectedEvent,
  categoryDiscount,
}) => {
  const [contact, setContact] = useState(3);
  const [message, setMessage] = useState('');

  const { codAdvantage, supplierName, codSegment } = advantageData;

  const onClickConfirm = async () => {
    const body = {
      userId: localStorage.userId,
      event: selectedEvent,
      contact,
      message,
      supplierName,
      advantage: codAdvantage,
      codSegment,
      value: categoryDiscount,
    };

    const response = await bride.brideAdvantageBudget(body);

    response.ok && response.data
      ? toast.error('Orçamento solicitado com sucesso!')
      : toast.error('Não foi possivel solicitar o orçamento!');
  };

  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco>
      <Wrapper>
        <MaterialInput
          style={{ width: '90%' }}
          label="Mensagem"
          value={message}
          onChange={event => setMessage(event.target.value)}
        />
        <RadioSection>
          <ContactLabel>Quero ser contactado:</ContactLabel>
          <RadioGroup>
            <RadioInput
              type="radio"
              onChange={() => setContact(3)}
              value="whatsapp"
              checked={contact === 3}
            />
            <RadioLabel>Whatsapp</RadioLabel>
          </RadioGroup>
          <RadioGroup>
            <RadioInput
              type="radio"
              onChange={() => setContact(1)}
              value="phone"
              checked={contact === 1}
            />
            <RadioLabel>Telefone</RadioLabel>
          </RadioGroup>
          <RadioGroup>
            <RadioInput
              type="radio"
              onChange={() => setContact(2)}
              value="mail"
              checked={contact === 2}
            />
            <RadioLabel>E-mail</RadioLabel>
          </RadioGroup>
        </RadioSection>
        <FullButton text="CONFIRMAR" click={onClickConfirm} />
      </Wrapper>
    </ModalContainer>
  );
};

AdvantageBudgetModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onClickButton: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

export default AdvantageBudgetModal;
