import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';

const Wrapper = styled.div`
  display: flex;
  background-color: ${theme.colors.text_heading};
  justify-content: center;
  align-items: center;
`;

const BalanceText = styled.p`
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.white};
`;

const PointsText = styled.span`
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.primary};
`;

export const PointsBalance = ({ points }) => {
  return (
    <Wrapper>
      <BalanceText>
        Seu saldo:
        <PointsText>
          <strong>&nbsp;{points}</strong> pontos
        </PointsText>
      </BalanceText>
    </Wrapper>
  );
};

PointsBalance.propTypes = {
  points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PointsBalance;
