import React from 'react';

import { LayoutBasis } from './src/components';
import { HeroWrapper } from './src/components/styled';
import { StateProvider } from './src/state';
import initialGlobalState from './src/stores';
import mainReducer from './src/state/reducers';
import Layout from './src/styles/Layout';
import { setApi } from './src/hooks/useFetch';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const bugsnagClient = bugsnag('31b88a926c6f664594b92f1d6cdb768b');
bugsnagClient.use(bugsnagReact, React);

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.getPlugin('react');

import ReactGA from 'react-ga';

ReactGA.initialize('UA-60017518-1');
if (typeof window !== 'undefined') {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export const wrapPageElement = ({ element, props }) => {
  setApi(process.env.GATSBY_API); // eslint-disable-line

  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <LayoutBasis {...props}>
      <Layout>
        <HeroWrapper>{element}</HeroWrapper>
      </Layout>
    </LayoutBasis>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
      <StateProvider initialState={initialGlobalState} reducer={mainReducer}>
        {element}
      </StateProvider>
    </ErrorBoundary>
  );
};
