import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import FormControl from '@material-ui/core/FormControl';

import { theme } from '../styles/theme';
import { bride } from '../services/api';
import {
  Button,
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialInput,
  ConfirmModal,
} from './';
//import { medias } from '../utils/mediaQueries';
import { MessageIcon, HomePhoneIcon } from './icons';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormTitle = styled.p`
  text-align: center;
  font-size: ${theme.fontSizeMed}px;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  ::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -0.5em;
    transform: translateX(-50%);
    width: 75%;
    height: 3px;
    background: ${theme.colors.primary};
  }
`;

const RadioSection = styled.fieldset`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  width: 90%;
  padding: 0;
  margin: 1em 0;
  align-items: center;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.1em;
  padding: 0.1em 0.6em;
  width: 100%;
`;

const RadioInput = styled.input`
  margin: 0;
  margin-right: 0.3em;
`;

const RadioLabel = styled.label`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const ContactLabel = styled.span`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  margin-right: 1em;
  margin-bottom: 0.2em;
`;

const StyledFormControl = styled(props => <FormControl {...props} />)`
  width: 90%;
`;

const FullButton = styled(props => <Button {...props} />)`
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 100%;
  margin: 0 1em ${({ confirm }) => (confirm ? '1em' : '0')} 1em;

  &:hover {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.text_heading};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-size: ${theme.fontSizeSm}px;
  width: 90%;
`;

const SupplierBudgetForm = ({ supplierData, selectedEvent }) => {
  const [contact, setContact] = useState(3);
  const [message, setMessage] = useState('');
  const [segment, setSegment] = useState('');
  const [alreadyRequestBudget, setAlreadyRequestBudget] = useState(false);
  const [budgetNumber, setBudgetNumber] = useState(0);

  const { nomeDivulgacao, segmentos } = supplierData;

  const onBudgetRequest = async () => {
    const body = {
      userId: Number(localStorage.userId),
      event: Number(selectedEvent),
      contact,
      message,
      supplierName: nomeDivulgacao,
      chosenSegment: segment,
      budgetNumber,
    };

    const response = await bride.brideSupplierBudget(body);

    return response.ok && response.data
      ? toast.error('Orçamento solicitado com sucesso!')
      : toast.error('Não foi possivel solicitar o orçamento!');
  };

  const onClickConfirm = async () => {
    const event = Number(selectedEvent);
    const check = await bride.brideSupplierBudgetCheck(
      event,
      nomeDivulgacao,
      segment
    );

    const lastBugdet = await bride.checkLastBudgetToday();

    const today = new Date();
    const init = 1;
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate() + 1).padStart(2, '0');
    const newBudgetNumber =
      today.getFullYear() + month + day + String(init).padStart(4, '0');

    lastBugdet.data
      ? setBudgetNumber(lastBugdet.data.num + 1)
      : setBudgetNumber(newBudgetNumber);

    !check.ok || !check.data
      ? onBudgetRequest()
      : setAlreadyRequestBudget(true);
  };

  const renderLoggedForm = () => (
    <>
      <FormTitle>Solicitar orçamento</FormTitle>
      <MaterialInput
        style={{ width: '90%' }}
        label="Mensagem"
        value={message}
        onChange={event => setMessage(event.target.value)}
      />
      <StyledFormControl>
        <MaterialInputLabel
          shrink
          style={{ fontSize: '0.8em' }}
          htmlFor="codSegment"
        >
          Segmento
        </MaterialInputLabel>
        <MaterialSelect
          style={{ width: '100%' }}
          name="codSegment"
          onChange={event => setSegment(event.target.value)}
          value={segment}
          inputProps={{
            name: 'codSegment',
            id: 'codSegment',
          }}
        >
          {segmentos.length > 0 &&
            segmentos.map(item =>
              item ? (
                <MaterialMenuItem
                  key={item.codSegmento}
                  value={item.codSegmento}
                >
                  {' '}
                  {item.Segmento}{' '}
                </MaterialMenuItem>
              ) : (
                ''
              )
            )}
        </MaterialSelect>
      </StyledFormControl>
      <RadioSection>
        <ContactLabel>Quero ser contatado:</ContactLabel>
        <RadioGroup>
          <RadioInput
            type="radio"
            onChange={() => setContact(3)}
            value="whatsapp"
            checked={contact === 3}
          />
          <RadioLabel>Whatsapp</RadioLabel>
          <WhatsAppIcon
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              color: '#EFC874',
            }}
          />
        </RadioGroup>
        <RadioGroup>
          <RadioInput
            type="radio"
            onChange={() => setContact(1)}
            value="phone"
            checked={contact === 1}
          />
          <RadioLabel>Telefone</RadioLabel>
          <HomePhoneIcon />
        </RadioGroup>
        <RadioGroup>
          <RadioInput
            type="radio"
            onChange={() => setContact(2)}
            value="mail"
            checked={contact === 2}
          />
          <RadioLabel>E-mail</RadioLabel>
          <MessageIcon />
        </RadioGroup>
      </RadioSection>
      <ButtonsContainer>
        <FullButton confirm text="CONFIRMAR" click={onClickConfirm} />
      </ButtonsContainer>
    </>
  );

  return (
    <Wrapper>
      {renderLoggedForm()}
      <ConfirmModal
        opened={alreadyRequestBudget}
        marginTop={'400px'}
        closeModal={() => setAlreadyRequestBudget(false)}
        onClickConfirm={onBudgetRequest}
        text="Você já mandou um orçamento para esse fornecedor, deseja enviar outro?"
      />
    </Wrapper>
  );
};

SupplierBudgetForm.propTypes = {
  closeModal: PropTypes.func,
  onClickButton: PropTypes.func,
  opened: PropTypes.bool,
};

export default SupplierBudgetForm;
