import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  border: 1px solid ${theme.colors.grey2};
  border-radius: 10px;
  height: 50px;
  margin-bottom: 10px;
`;

const RatingContainer = styled.div`
  height: 80%;
  width: 10%;
  ${({ last }) => !last && `border-right: 1px solid ${theme.colors.grey2};`}

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => disabled ? `not-allowed` : `pointer`};

  color: ${({ selected }) =>
    selected ? theme.colors.primary : theme.colors.text_heading};
`;

const Rating = ({rating, setRate, disabled}) => {
  const ratingData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const changeRate = (id) => {
    if (!disabled) {
      setRate(id)
    }
  }
  return (
    <Wrapper>
      {ratingData.map(id => (
        <>
          <RatingContainer
            selected={rating === id}
            onClick={() => changeRate(id)}
            last={id === 10}
            disabled={disabled}
            >
            {id}
          </RatingContainer>
        </>
      ))}
    </Wrapper>
  );
};

export default Rating;
