import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';

const Wrapper = styled.button`
  border: 2px solid ${theme.colors.primary};
  border-radius: 3px;
  color: ${theme.colors.text_heading};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.5em;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  :disabled {
    opacity: 0.7;
    cursor: default;
  }
`;

const Button = ({ click, text, className, type = 'button', disabled }) => {
  const onClickButton = () => {
    if (disabled) {
      return;
    }
    if (click) {
      click();
    }
  }
  return (
    <Wrapper
      className={className}
      onClick={onClickButton}
      type={type}
      disabled={disabled}
    >
      {text}
    </Wrapper>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  click: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default Button;
