import React, { useState } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import {
  Button,
  Rating,
  MaterialInput,
  MaterialInputLabel,
  MaterialSelect,
  MaterialMenuItem,
} from '.';
import { reasonDontContract } from '../utils/dropdownValues';
import Arabesco from '../images/arabescos.svg';
import moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2em;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const CardText = styled.span`
  font-size: 1rem;
  margin-bottom: 0.7rem;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;

  padding-bottom: 0.7em;
  margin-bottom: 0.7em;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; '
      : 'margin-bottom: 1em;'}
`;

const Title = styled.p`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  text-align: left;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
  }

  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 0.5em;
`;

const ServiceTitle = styled.p`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  margin: 0;

  align-self: flex-start;
`;

const ServiceName = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.75em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  margin: 0;
  padding-left: 1em;
`;

const StyledButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    font-size: 12px;
  }
  border: 1px solid ${theme.colors.text_heading};
  background-color: ${({ white }) =>
    white ? theme.colors.white : theme.colors.text_heading};
  color: ${({ white }) =>
    white ? theme.colors.text_heading : theme.colors.white};

  height: 2em;
  width: 20%;
  padding: 0;
`;

const StyledFormControl = styled(props => <FormControl {...props} />)`
  width: 100%;
  align-self: flex-start;
`;

const RatingCard = ({ ratingData, type, rateHandler, text }) => {
  const {
    cod,
    supplier,
    segment,
    service,
    NPS,
    dataHoraNPS,
    reason,
    obs,
    segmentIcon,
    contactDate,
    price,
  } = ratingData;
  const [reasonValue, setReasonValue] = useState(reason || 0);
  const [observations, setObservations] = useState(obs);
  const [rating, setRate] = useState(NPS);

  const isClickAvaliable = !NPS;

  return (
    <Wrapper>
      <AssetContainer />
      <Title>{type === 3 ? service : supplier}</Title>
      <InnerWrapper>
        <RowContainer>
          <IconContainer>
            <StyledIcon src={segmentIcon} />
            <ItemText>{segment}</ItemText>
          </IconContainer>
          {type === 1 && (
            <ServiceContainer>
              <ServiceTitle>Valor</ServiceTitle>
              <ServiceName>R${price ? price.toFixed(2) : '?'}</ServiceName>
            </ServiceContainer>
          )}
          {type === 3 && (
            <>
              <ServiceContainer>
                <ServiceTitle>Fornecedor</ServiceTitle>
                <ServiceName>{supplier}</ServiceName>
              </ServiceContainer>
            </>
          )}
        </RowContainer>
        {type === 0 && (
          <RowContainer>
            <ServiceContainer>
              <ServiceTitle>Data de contato</ServiceTitle>
              <ServiceName>
                {contactDate
                  ? moment(contactDate).format('DD/MM/YYYY')
                  : 'Não informada'}
              </ServiceName>
            </ServiceContainer>
            {!isClickAvaliable && (
              <ServiceContainer>
                <ServiceTitle>Data da Avaliação</ServiceTitle>
                <ServiceName>
                  {dataHoraNPS
                    ? moment(dataHoraNPS).format('DD/MM/YYYY')
                    : 'Não informada'}
                </ServiceName>
              </ServiceContainer>
            )}
          </RowContainer>
        )}
        <CardText>{text}</CardText>
        <Rating
          rating={rating}
          setRate={setRate}
          disabled={NPS || !isClickAvaliable}
        />
        {type === 0 && (
          <StyledFormControl>
            <MaterialInputLabel htmlFor="reason-simple">
              Motivo da não contratação
            </MaterialInputLabel>
            <MaterialSelect
              disabled={reason || !isClickAvaliable}
              onChange={event => setReasonValue(event.target.value)}
              value={reasonValue}
              inputProps={{
                name: 'reason',
                id: 'reason-simple',
              }}
            >
              <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
              {reasonDontContract.map(item => (
                <MaterialMenuItem value={item} key={item}>
                  {item}
                </MaterialMenuItem>
              ))}
            </MaterialSelect>
          </StyledFormControl>
        )}
        <RowContainer>
          <MaterialInput
            disabled={obs || !isClickAvaliable}
            label="Depoimentos (alguns elogios poderemos usar em nossas redes sociais, mas qualquer elogio, sugestão ou reclamação, manteremos o sigilo com relação aos nomes e dados dos noivos)"
            value={observations}
            onChange={event => setObservations(event.target.value)}
            multiline
            rows="3"
            style={{ width: '60%' }}
          />
          {!NPS && isClickAvaliable && (
            <StyledButton
              text="AVALIAR"
              click={() => rateHandler(cod, rating, observations, reasonValue)}
              disabled={!isClickAvaliable}
            />
          )}
        </RowContainer>
      </InnerWrapper>
      <AssetContainer bottom />
    </Wrapper>
  );
};

export default RatingCard;
