import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { useStateValue } from '../state';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { TrashIcon, GreenCircle, RedCircle, DisabledIcon } from './icons';
import {
  MaterialInput,
  Button,
  ConfirmModal,
  UpdateUserModal,
} from '../components';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { supplier } from '../services/api';
import { toast } from 'react-toastify';
import { scrollToRef } from '../hooks';
import Grid from '@material-ui/core/Grid';
import Loader from './Loader';

const Title = styled.h2`
  color: ${theme.colors.grey3};
`;

const RowContainer = styled.div`
  @media ${medias.phoneMax} {
    align-items: baseline;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 1em 0 0.5em 0;
`;

const Text = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  margin: 0;
  text-align: center;
  ${({ left }) =>
    left &&
    `margin-left: 0.3em;
  text-align: left;`}
`;

const Span = styled.span`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  margin: 0;
`;

const ClickableSpan = styled(Span)`
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
  @media ${medias.phoneMax} {
    margin-left: 0px;
  }
`;

const ClickableSpanMobile = styled(ClickableSpan)`
  display: none;
  @media ${medias.phoneMax} {
    display: initial;
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
`;

const StyledDisabledIcon = styled(DisabledIcon)`
  cursor: pointer;
`;

const FormTitle = styled.h5`
  color: ${theme.colors.text_heading};
`;

const Line = styled.div`
  height: 1px;
  width: 90%;
  background-color: ${theme.colors.grey1};
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: ${theme.fontSizeSm}px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 90%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin-top: 1em;
`;

const GridContainer = styled(Grid)`
  border-bottom: '1px solid #afa7a7';
  line-height: '32px';
  margin-right: '0';
  margin-left: '0';
  margin-bottom: '10px';
  @media ${medias.phoneMax} {
    border: 1px solid #afa7a7;
    width: 97%;
  }
`;

const GridColumn = styled(Grid)`
  text-align: center;
  line-height: 32px;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
`;

const SupplierCreateUsers = () => {
  const [form, setForm] = useState({
    codUser: null,
    name: null,
    email: null,
    password: null,
  });
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('ADICIONAR');
  const [modalOpened, setModalOpened] = useState(false);
  const [updateModalOpened, setUpdateModalOpened] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);

  const [
    {
      session: { rule, userId },
    },
  ] = useStateValue();

  const addUserDiv = useRef(null);
  const executeScrollToaddUserDiv = () => scrollToRef(addUserDiv);

  useFetch({
    url: urls.supplierContent.supplierGuests,
    method: method.GET,
    condition: () => !users,
    callback: data => setUsers(data),
  });

  const updateField = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateUser = (codUser, name, email) => {
    const user = {
      codUser,
      name,
      email,
    };
    setUserToUpdate(user);
    setUpdateModalOpened(true);
  };

  const handleUserButton = () => {
    if (mode === 'ALTERAR') {
      setModalOpened(true);
      return;
    }

    addUser();
  };

  const updateUser = async userUpdatedForm => {
    setLoading(true);
    const { codUser, ...payload } = userUpdatedForm;
    if (Number(rule) !== 1 && payload.newPassword && !payload.currentPassword) {
      return toast.error(
        'Erro! Insira a senha atual para trocar para uma nova!'
      );
    }
    if (payload.newPassword) {
      if (payload.newPassword !== payload.password2) {
        setLoading(false);
        return toast.error('As senhas inseridas diferem!');
      }
      delete payload.password2;
    }
    const response = await supplier.alterGuest({ codUser, ...payload });
    if (response.ok && response.data) {
      const alteredState = users.map(user => {
        if (codUser === user.codUser) {
          user = {
            ...user,
            name: payload.name,
            email: payload.email,
          };
        }
        return user;
      });
      setUsers(alteredState);
      toast.success('Dados do usuário atualizados com sucesso');
    } else {
      if (response.data.message === 'Password Invalid.') {
        toast.error('Erro! Senha atual incorreta.');
      } else {
        toast.error('Erro ao alterar usuário');
      }
    }
    changeUser(null, '', '', '');
    setMode('');
    setLoading(false);
  };

  const addUser = async () => {
    setLoading(true);
    const { ...payload } = form;
    delete payload.codUser;
    const response = await supplier.addGuest(payload);
    if (response.ok && response.data) {
      const newState = [...users, response.data];
      setUsers(newState);
      toast.success('Usuário Adicionado com sucesso');
    } else {
      const toastMessage =
        response.status === 409
          ? 'Email já cadastrado no sistema.'
          : 'Error ao adicionar usuário';
      toast.error(toastMessage);
    }
    changeUser(null, '', '', '');
    setMode('');
    setLoading(false);
  };

  const deleteUser = async cod => {
    const oldState = users;
    const newState = users.filter(({ codUser }) => codUser !== cod);
    setUsers(newState);
    const response = await supplier.deleteGuest(cod);
    if (!response.ok) {
      toast.error(
        'Erro ao excluir: Possivelmente o usuário possui interações no sistema. Você pode inativá-lo ao invés de excluir.'
      );
      setUsers(oldState);
    }
  };

  const disableUser = async cod => {
    let alterUser = {};
    const oldState = users;
    const newState = [];
    users.map(user => {
      user.inativo =
        user.codUser === cod ? (user.inativo === 0 ? 1 : 0) : user.inativo;
      if (user.codUser === cod) {
        alterUser.codUser = cod;
        alterUser.name - user.name;
        alterUser.email = user.email;
        alterUser.currentPassword = '';
        alterUser.newPassword = '';
        alterUser.inativo = user.inativo;
      }
      newState.push(user);
    });

    console.log(alterUser);

    setUsers(newState);

    const response = await supplier.alterGuest(alterUser);

    if (!response.ok) {
      toast.error('Erro ao inativar.');
      setUsers(oldState);
    }
  };

  const changeUser = (cod, name, email, password) => {
    setForm({
      ...form,
      codUser: cod,
      name: name,
      email: email,
      password: password || '',
    });
    setMode('ALTERAR');
    executeScrollToaddUserDiv();
  };

  return (
    <>
      <ConfirmModal
        opened={modalOpened}
        closeModal={() => setModalOpened(false)}
        onClickConfirm={() => addUser()}
        text={'Você tem certeza que quer alterar esse usuário?'}
      />
      {updateModalOpened && (
        <UpdateUserModal
          opened={updateModalOpened}
          closeModal={() => setUpdateModalOpened(false)}
          onClickConfirm={userUpdatedForm => updateUser(userUpdatedForm)}
          rule={rule}
          userToUpdate={userToUpdate}
          codUserLoggedIn={userId}
        />
      )}
      <Title>Cadastro de Usuários</Title>
      <FormTitle>Usuários</FormTitle>
      {users ? (
        users.length > 0 ? (
          users.map(
            ({ codUser, name, email, inativo, codRegraUsuarioFornecedor }) =>
              (Number(rule) === 1 ||
                (Number(rule) !== 1 && codUser === userId)) && (
                <GridContainer
                  alignContent="space-between"
                  container
                  spacing={1}
                  key={codUser}
                >
                  <GridColumn item xs={12} sm={12} md={5}>
                    <Text>{name}</Text>
                  </GridColumn>
                  <GridColumn item xs={4} sm={4} md={4}>
                    <Text left>
                      {inativo === 0 && (
                        <span title="Ativo">
                          {' '}
                          <GreenCircle />
                        </span>
                      )}
                      {inativo === 1 && (
                        <span title="Inativo">
                          <RedCircle />
                        </span>
                      )}
                      {codRegraUsuarioFornecedor.codRegraUsuarioFornecedor === 1
                        ? 'Adm'
                        : 'Usuário'}
                    </Text>
                  </GridColumn>
                  <GridColumn item xs={4} sm={4} md={2}>
                    <ClickableSpan
                      onClick={() => handleUpdateUser(codUser, name, email)}
                    >
                      Alterar
                    </ClickableSpan>
                  </GridColumn>
                  {Number(rule) === 1 && (
                    <GridColumn item xs={4} sm={4} md={1}>
                      <StyledDiv>
                        <ClickableSpanMobile
                          onClick={() => deleteUser(codUser)}
                        >
                          Deletar
                        </ClickableSpanMobile>

                        <StyledTrashIcon onClick={() => deleteUser(codUser)} />

                        <StyledDisabledIcon
                          onClick={() => disableUser(codUser)}
                        />
                      </StyledDiv>
                    </GridColumn>
                  )}
                </GridContainer>
              )
          )
        ) : (
          <p>Nenhum usuário</p>
        )
      ) : (
        <Loader />
      )}
      <Line />
      <FormTitle>Adicionar Usuário</FormTitle>
      <MaterialInput
        style={{ width: '90%' }}
        label="Nome"
        name="name"
        value={form.name}
        onChange={updateField}
      />
      <RowContainer>
        <MaterialInput
          style={{ width: '50%', marginRight: '20px' }}
          label="E-mail"
          name="email"
          value={form.email}
          onChange={updateField}
        />

        <MaterialInput
          style={{ width: '50%' }}
          label="Senha"
          name="password"
          value={form.password}
          onChange={updateField}
        />
      </RowContainer>
      {!loading && (
        <HalfButton text="ADICIONAR" click={() => handleUserButton()} />
      )}
    </>
  );
};

export default SupplierCreateUsers;
