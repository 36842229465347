import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  ${({ row }) => !row && `flex-direction: column;`}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Button = styled.div`
  padding: 5px 10px;
  border-radius: 100%;
  background-color: ${theme.colors.text_heading};
  margin-bottom: 1.5em;

  &:hover {
    color: ${theme.colors.primary};
    cursor: pointer;
  }
`;

const Pagination = ({ renderItem, data = [], itemsPerPage, row, showButtons = true }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageData, setPageData] = useState(data);
  const [currentIndex, setCurrentIndex] = useState([]);

  useEffect(() => {
    const totalItems = data.length;
    const _totalPages = Math.ceil(totalItems / itemsPerPage);
    const _pageData = data.slice(0, itemsPerPage);

    setTotalPages(_totalPages);
    setPageData(_pageData);
    setCurrentIndex([0, itemsPerPage]);
  }, [data, itemsPerPage]);

  const handlePageChange = direction => {
    const _itemsPerPage = itemsPerPage;
    const [initialIndex, finalIndex] = currentIndex;

    let _pageData = pageData;
    let _currentPage = currentPage;

    if (direction === 'next') {
      if (_currentPage + 1 <= totalPages) {
        _currentPage++;
        const newInitialIndex = initialIndex + _itemsPerPage;
        const newFinalIndex = finalIndex + _itemsPerPage;
        _pageData = data.slice(newInitialIndex, newFinalIndex);
        setCurrentIndex([newInitialIndex, newFinalIndex]);
      }
    }

    if (direction === 'back') {
      if (_currentPage - 1 >= 1) {
        _currentPage--;
        const newInitialIndex = initialIndex - _itemsPerPage;
        const newFinalIndex = finalIndex - _itemsPerPage;
        _pageData = data.slice(newInitialIndex, newFinalIndex);
        setCurrentIndex([newInitialIndex, newFinalIndex]);
      }
    }

    setPageData(_pageData);
    setCurrentPage(_currentPage);

    window.scrollBy({
      top: -(window.innerHeight * 2.5),
      behavior: 'smooth',
    });
  };

  return (
    <Wrapper>
      <Container row={row}>{pageData.map(item => renderItem(item))}</Container>
      {showButtons && (
        <ButtonContainer>
        <Button onClick={() => handlePageChange('back')}>&#60;</Button>
        {currentPage}
        <Button onClick={() => handlePageChange('next')}>&#62;</Button>
        </ButtonContainer>
      )}
    </Wrapper>
  );
};

Pagination.propTypes = {
  renderItem: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  itemsPerPage: PropTypes.num,
  row: PropTypes.bool,
};

Pagination.defaultProps = {
  itemsPerPage: 10,
  row: false,
};

export default Pagination;
