import React from 'react';
import styled from 'styled-components';
import ModalContainer from './ModalContainer';
import { medias } from '../utils/mediaQueries';


const ModalWrapper = styled.div`
  display: flex;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ModalImageContainer = styled.div`
  width: 90%;
  max-height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${medias.smallerTabletMax} {
    height: 40vw;
    width: 65vw;
    }
`;

const SupplierImage = styled.img`
  max-height: 90%;
  max-width: 90%;
  border: 1px solid;
`;

const ImageModal = ({ picture, opened, closeModal }) => (
    <ModalContainer opened={opened} closeModal={closeModal}>
      <ModalWrapper>
        {picture && (
          <ModalImageContainer>
            <SupplierImage src={picture} alt="Imagem do fornecedor" />
          </ModalImageContainer>
        )}
      </ModalWrapper>
    </ModalContainer>
  );

  export default ImageModal;