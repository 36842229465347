import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify'; // maybe change this to more generic location

import { Header, Footer } from '../components';
// import Header from '../components/Headeraa';
import { LoginModal } from '../containers';
import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { useStateValue } from '../state';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../containers/Slider/Slider.css';
import './toast.css';

const Wrapper = styled.div`
  @media ${medias.laptopMin} {
    padding-top: ${({ isLoggedIn }) => (isLoggedIn ? '135px' : '80px')};
  }
  background-color: white;
`;

const Toast = styled(({ className, ...props }) => (
  <div className={className}>
    <ToastContainer {...props} />
  </div>
))`
  .toast {
    background-color: ${theme.colors.grey3};
    color: white;
    padding: 1em;
  }
`;

const Layout = ({ children }) => {
  const [{ session }] = useStateValue();
  const isLoggedIn = session.userId ? true : false;

  return (
    <Wrapper isLoggedIn={isLoggedIn}>
      <Toast position="top-center" autoClose={2500} toastClassName="toast" />
      <LoginModal />
      <Header />
      {children}
      {!session.isSupplier && <Footer isLoggedIn={isLoggedIn} />}
    </Wrapper>
  );
};

export default Layout;
