import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { useStateValue } from '../../state';
import {
  HeaderLogo,
  HeaderWrapper,
  IconLink,
  TopHeaderContainer,
} from './HeaderComponents';
import { ChainIcon } from '../icons';
import Button from '../Button';
import { medias } from '../../utils/mediaQueries';
import NativeSelect from '@material-ui/core/NativeSelect';
import { siteContent } from '../../services/api';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { checkSiteSelectedRegion } from '../../services/checkSession';
import Grid from '@material-ui/core/Grid';

function loadTawk() {
  var Tawk_API = Tawk_API || {};
  (function() {
    if (typeof window !== 'undefined') {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/62b46c947b967b11799625d8/1g68cg92d';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    }
  })();
}

//import { isMobile } from 'react-device-detect';

let isMobile = false;
if (typeof window !== 'undefined') {
  if (document.body.clientWidth < 468) {
    isMobile = true;
  }
}

const StyledButton = styled(Button)`
  display: inline-block;
  margin-left: 0.5em;
  /*
    Diminuimos o tamanho do botão no celular pra sobrar espaço pra tudo sem precisar pular linha 
  */

  @media ${medias.phoneMax} {
    /*font-size: 0.75em;*/
  }
`;

const StyledNativeSelect = styled(NativeSelect)`
  font-size: 14px;
`;

const StyledGridItem = styled(Grid)`
  text-align: right;
  @media ${medias.smallerTabletMax} {
    text-align: center;
  }
  @media ${medias.phoneMax} {
    margin: auto;
    font-size: 0.8em;
  }
`;

const LogoGrid = styled(Grid)`
  @media ${medias.smallerTabletMax} {
    text-align: center;
  }
`;

const PublicHeader = ({ dispatch, visibility, siteRegion = '' }) => {
  loadTawk();
  let rValue = siteRegion === '' ? 1 : siteRegion;
  const [regionValue, setRegionValue] = useState(rValue);
  const [regions, setRegions] = useState([]);

  const [
    {
      session: { siteSelectedRegion },
    },
  ] = useStateValue();

  useEffect(() => {
    const fetchRegions = async () => {
      const response = await siteContent.regions();
      if (response.ok && response.data) {
        setRegions(response.data);
      }
    };
    fetchRegions();

    return setRegions([]); //@Cleanup
  }, []);

  useEffect(() => {
    if (siteSelectedRegion) {
      sessionStorage.setItem('siteSelectedRegion', siteSelectedRegion);
      checkSiteSelectedRegion(dispatch);
      setRegionValue(siteSelectedRegion);
    }

    const rValueSelected = siteSelectedRegion === null ? 1 : siteSelectedRegion;

    return setRegionValue(rValueSelected); //@Cleanup
  }, [siteSelectedRegion, dispatch]);

  const handleChange = value => {
    setRegionValue(value);
    sessionStorage.setItem('siteSelectedRegion', value);
    checkSiteSelectedRegion(dispatch);
    navigate('/');
  };

  return (
    <HeaderWrapper>
      <TopHeaderContainer>
        <Grid container spacing={8}>
          <LogoGrid item xs={12} sm={4} md={4}>
            <HeaderLogo path="/" isPublicSite />
          </LogoGrid>

          <StyledGridItem item xs={isMobile ? 6 : 12} sm={4} md={5}>
            <FormControl>
              <InputLabel shrink>Região</InputLabel>
              <StyledNativeSelect
                value={regionValue}
                onChange={event => handleChange(event.target.value)}
              >
                {regions &&
                  regions.map(item => (
                    <option key={item.codRegiao} value={item.codRegiao}>
                      {item.Regiao}
                    </option>
                  ))}
              </StyledNativeSelect>
            </FormControl>
          </StyledGridItem>

          {isMobile ? (
            <React.Fragment>
              <StyledGridItem item xs={3} sm={4} md={3}>
                <IconLink icon={ChainIcon}>
                  <Link to="/facaparte">Faça Parte</Link>
                </IconLink>
              </StyledGridItem>
              <StyledGridItem item xs={3} sm={4} md={3}>
                <StyledButton
                  click={() =>
                    dispatch({
                      type: 'toggleLoginModal',
                      newVisibility: !visibility.loginModal,
                    })
                  }
                  text="LOGIN"
                />
              </StyledGridItem>
            </React.Fragment>
          ) : (
            <StyledGridItem item xs={12} sm={4} md={3}>
              <IconLink icon={ChainIcon}>
                <Link to="/facaparte">Faça Parte</Link>
              </IconLink>
              <StyledButton
                click={() =>
                  dispatch({
                    type: 'toggleLoginModal',
                    newVisibility: !visibility.loginModal,
                  })
                }
                text="LOGIN"
              />
            </StyledGridItem>
          )}
        </Grid>
      </TopHeaderContainer>
    </HeaderWrapper>
  );
};

export default PublicHeader;
