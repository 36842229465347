import searchbar from './searchbar';
import visibility from './visibility';
import forms from './forms';
import session from './session';
import faq from './faq';
import about from './about';
import contactUs from './contactUs';
import generalData from './generalData';
import suppliers from './suppliers';
import segments from './segments';
import advantages from './advantages';
import events from './events';
import prizes from './prizes';

const initialGlobalState = {
  searchbar,
  visibility,
  forms,
  session,
  faq,
  about,
  contactUs,
  generalData,
  suppliers,
  segments,
  advantages,
  events,
  prizes
};

export default initialGlobalState;
