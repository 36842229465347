import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    items: PropTypes.array.isRequired,
    itemsTotal:PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    scrollToRef: PropTypes.func,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
}

const defaultProps = {
    initialPage: 1,
    pageSize: 10
}

class Pagination2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pager: {} };
    }

    UNSAFE_componentWillMount() {
        // set page if items array isn't empty
        if (this.props.items && this.props.items.length) {
            this.setInitialPage(this.props.initialPage);
        }
    }

    componentDidUpdate(prevProps) {
        // reset page if items array has changed
        if (this.props.items !== prevProps.items) {
            this.setInitialPage(this.props.initialPage);
        }
    }

    setInitialPage(page) {
        // var { items, pageSize, itemsTotal } = this.props;
        var { pageSize, itemsTotal } = this.props;
        var pager = this.state.pager;

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        // get new pager object for specified page
        pager = this.getPager(itemsTotal, page, pageSize);

        // get new page of items from items array
        //var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        this.setState({ pager: pager });
    }

    setPage(page) {
        // var { items, pageSize, itemsTotal } = this.props;
        var { pageSize, itemsTotal } = this.props;
        var pager = this.state.pager;

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        // get new pager object for specified page
        pager = this.getPager(itemsTotal, page, pageSize);

        // get new page of items from items array
        //var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        this.setState({ pager: pager });

        // call change page function in parent component
        this.props.onChangePage(pager.currentPage, true);
        // window.scrollBy({
        //   top: -(window.innerHeight * 5.5),
        //   behavior: 'smooth',
        // });
        if(this.props.scrollToRef){
            this.props.scrollToRef();
        }
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= 5) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 5 total pages so calculate start and end pages
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage +2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        // var pages = [Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        let pages = []
        for(let i=startPage; i<=endPage; i++){
          pages.push(i)
        }

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    render() {
        var pager = this.state.pager;

        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }

        return (
            <ul className="pagination">
                <li className={pager.currentPage === 1 ? 'disabled' : ''}
                onClick={() => this.setPage(1)}>
                    Primeiro
                </li>
                <li className={pager.currentPage === 1 ? 'disabled' : ''}
                onClick={() => this.setPage(pager.currentPage - 1)}>
                    Anterior
                </li>
                {pager.pages.map((page, index) =>
                    <li key={index} 
                    className={pager.currentPage === page ? 'active' : ''}
                    onClick={() => this.setPage(page)}>
                        {page}
                    </li>
                )}
                <li className={pager.currentPage === pager.totalPages ? 'disabled' : ''}
                onClick={() => this.setPage(pager.currentPage + 1)}>
                    Próximo
                </li>
                <li className={pager.currentPage === pager.totalPages ? 'disabled' : ''}
                onClick={() => this.setPage(pager.totalPages)}>
                    Último
                </li>
            </ul>
        );
    }
}

Pagination2.propTypes = propTypes;
Pagination2.defaultProps = defaultProps;
export default Pagination2;