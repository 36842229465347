import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import theme from '../styles/theme';
import phoneMask from '../utils/phoneMask';
import ModalContainer, { ModalTitle } from './ModalContainer';
//import FormControl from '@material-ui/core/FormControl';
import {
  MaterialInput,/*
  MaterialInputLabel,
  MaterialSelect,
  MaterialMenuItem,*/
  MaterialDatePicker,
} from '.';
import { supplier } from '../services/api';
import undefinedDate from '../utils/undefinedDate';
//import { medias } from '../utils/mediaQueries';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .button-page {
    background: ${theme.colors.secondary};
    color: white;
    padding: 0.5em 1em;
    border-radius: 0.1em;
  }
  .button-page:hover {
    background: ${theme.colors.yellow};
    color: ${theme.colors.secondary};
    font-weight: bold;
    text-decoration: underline;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

/*const StyledFormControl = styled(FormControl)`
  @media ${medias.phoneMax} {
    width: 90%;
  }

  width: 50%;
`;*/

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const EditBrideModal = ({
  onCloseModal,
  opened,
  brideData,/*
  segmentData,
  regionsData,*/
}) => {
  const [email, setEmail] = useState(brideData.clientEmail);
  const [name, setName] = useState(brideData.clientName);
  const [cellphone, setCellphone] = useState(brideData.clientCellphone);
  const [eventDate, setEventDate] = useState(brideData.eventDate);
  const [isLoading, setIsLoading] = useState(false);
  const [dateUndefined, setDateUndefined] = useState(false);

  useEffect(() => {
    if (!eventDate || (eventDate && eventDate.length === 0) || (eventDate === undefinedDate)) {
      setDateUndefined(true);
    }
  }, [eventDate]);

  const handleSubmit = async () => {
    if (!name || !email || !cellphone || (!eventDate && !dateUndefined)) {
      toast.error('Preencha todos os campos!');
      return;
    }
    setIsLoading(true);
    const payload = {
      name,
      email,
      cellphone,
      weddingDate: !dateUndefined ? eventDate : undefinedDate,
    };

    toast.info('Aguarde...');
    const res = await supplier.editBrides(payload);
    if (!res.ok) {
      setIsLoading(false);
      return toast.error('Falha ao enviar modificações.');
    }
    setIsLoading(false);
    onCloseModal();
    return toast.success('Modificações enviadas com sucesso!', {
      autoClose: 1500,
    });
  };

  return (
    <Wrapper>
      <ModalContainer opened={opened} closeModal={onCloseModal} hasArabesco>
        <ModalWrapper>
          <ModalTitle>EDITAR CADASTRO DE NOIVO CONVIDADO</ModalTitle>
          <MaterialInput
            style={{ width: '100%' }}
            label="Nome"
            value={name}
            required
            onChange={event => setName(event.target.value)}
          />
          <MaterialInput
            style={{ width: '100%' }}
            label="E-mail"
            value={email}
            required
            email
            onChange={event => setEmail(event.target.value)}
          />
          <Row>
            <MaterialInput
              style={{ width: '45%' }}
              label="Telefone celular"
              required
              value={cellphone}
              disabled={isLoading}
              onChange={event => setCellphone(phoneMask(event.target.value))}
            />
            <StyledFields>
              <MaterialDatePicker
                label="Data do casamento"
                format="DD/MM/YYYY"
                value={eventDate}
                onChange={setEventDate}
                disabled={dateUndefined}
                style={{ width: '100%' }}
              />
              <StyledFieldset>
                <Checkbox
                  type="checkbox"
                  name="hasDate"
                  value="Não definida"
                  onChange={() => setDateUndefined(!dateUndefined)}
                  checked={dateUndefined}
                />
                <StyledSpan>Não definida</StyledSpan>
              </StyledFieldset>
            </StyledFields>
          </Row>
         {/* <Row>
            <StyledFormControl style={{ width: '45%' }}>
              <MaterialInputLabel htmlFor="region">Região</MaterialInputLabel>
              <MaterialSelect
                onChange={event => setRegion(event.target.value)}
                value={region}
                inputProps={{
                  name: 'region',
                  id: 'region',
                }}
              >
                {regionsData &&
                  regionsData.map(({ region, codRegion }) => (
                    <MaterialMenuItem value={codRegion} key={codRegion}>
                      {region}
                    </MaterialMenuItem>
                  ))}
              </MaterialSelect>
            </StyledFormControl>

            <StyledFormControl>
              <MaterialInputLabel htmlFor="segment">
                Segmento
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event => setSegment(event.target.value)}
                value={segment}
                inputProps={{
                  name: 'segment',
                  id: 'segment',
                }}
              >
                {segmentData &&
                  segmentData.map(({ segment, codSegment }) => (
                    <MaterialMenuItem value={codSegment} key={codSegment}>
                      {segment}
                    </MaterialMenuItem>
                  ))}
              </MaterialSelect>
            </StyledFormControl>
                  </Row>*/}
          <button
            className="button-page"
            onClick={handleSubmit}
            style={{
              width: '50%',
              marginTop: '1em',
            }}
            disabled={isLoading}
          >
            {!isLoading ? 'ENVIAR' : 'Aguarde...'}
          </button>
        </ModalWrapper>
      </ModalContainer>
    </Wrapper>
  );
};

export default EditBrideModal;