import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';

import theme from '../styles/theme';

const Wrapper = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${theme.colors.secondary};
  color: ${theme.colors.white};
  cursor: pointer;
`;

const Component = ({ title }) => {
  return (
    <Tooltip disableFocusListener disableTouchListener title={title}>
      <Wrapper>?</Wrapper>
    </Tooltip>
  );
};

export default Component;
