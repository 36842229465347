import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
// import PropTypes from 'prop-types';

import theme from '../styles/theme';
// import { medias } from '../utils/mediaQueries';
import { Button, CardContainer, PrizeReports, ConfirmModal } from './';
import { supplier } from '../services/api';
import { toast } from 'react-toastify';
import dateUndefined from '../utils/undefinedDate';

//@TODO Responsive CSS when implementing full page
//@TODO PropTypes when implementing full page
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ last }) =>
    !last &&
    `border-bottom: 1px solid ${theme.colors.grey2}; margin-bottom: 1em;`};
  padding: 0 0 1em 0;

  h3 {
    color: ${theme.colors.text_heading};
    align-self: flex-start;
    margin: 0;
  }

  p {
    color: ${theme.colors.grey3};
    font-size: 0.8em;
    margin-bottom: 0;
  }
`;

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    strong {
      color: ${theme.colors.primary};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
`;

const StyledButton = styled(props => <Button {...props} />)`
  width: 45%;
  height: 2.5em;
  padding: 0;
  font-size: 0.7em;
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  border: 2px solid ${theme.colors.text_heading};
`;

const SupplierPrizeCard = ({ data, reportData, refetchData }) => {
  const { redeemed, stock } = reportData;
  const [takenModal, setTakenModal] = useState(false);
  const [returnedModal, setReturnedModal] = useState(false);
  const [clickedId, setClickedId] = useState(null);

  const handlePrizeTaken = async id => {
    const payload = {
      codCCPrize: id,
    };

    const response = await supplier.updatePrizeToTaken(payload);

    if (!response.ok) {
      toast.error('Erro ao dar baixa no prêmio.');
      return;
    }

    toast.success('Prêmio retirado com sucesso!');
    refetchData();
  };

  const handlePrizeReturned = async id => {
    const response = await supplier.returnPrizeToStock(id);

    if (!response.ok) {
      toast.error('Erro ao retornar o prêmio para o estoque.');
      return;
    }

    toast.success('Prêmio retornado ao estoque!');
    refetchData();
  };

  return (
    <Wrapper>
      <ConfirmModal
        opened={takenModal}
        closeModal={() => setTakenModal(false)}
        onClickConfirm={() => handlePrizeTaken(clickedId)}
        text={'Você tem certeza que quer dar baixa nesse prêmio ?'}
      />
      <ConfirmModal
        opened={returnedModal}
        closeModal={() => setReturnedModal(false)}
        onClickConfirm={() => handlePrizeReturned(clickedId)}
        text={'Você tem certeza que quer retornar esse prêmio para o estoque ?'}
      />
      <CardContainer>
        {data.length > 0 ? (
          data.map(
            (
              {
                id,
                prizeName,
                brideName,
                brideEmail,
                brideFix,
                brideMobile,
                category,
                eventData,
                prizeDate,
                prizeReturned,
                prizeDateReturned,
                prizeRedeemed,
                prizeDateRedeemed,
              },
              index
            ) => (
              <ItemContainer key={id} last={index === data.length - 1}>
                <h3>{prizeName}</h3>
                <p>
                  Gerado em:{' '}
                  {prizeDate && moment(prizeDate).format('DD/MM/YYYY')}
                </p>
                <p>
                  Data do evento:{' '}
                  {eventData === dateUndefined
                    ? 'Data indefinida'
                    : moment(eventData).format('DD/MM/YYYY')}
                </p>
                <DataContainer>
                  <p>{brideName}</p>
                  {brideEmail ? <p>{brideEmail}</p> : ''}
                  <p>
                    {brideMobile ? brideMobile : ''}
                    {brideFix ? ` / ${brideFix}` : ''}
                  </p>
                  <p>Cliente {category}</p>
                </DataContainer>

                {prizeReturned === 1 ? (
                  <p>
                    Premio devolvido para o estoque em{' '}
                    {moment(prizeDateReturned).format('DD/MM/YYYY HH:MM:SS')}
                  </p>
                ) : prizeRedeemed === 1 ? (
                  <p>
                    Premio resgatado em{' '}
                    {moment(prizeDateRedeemed).format('DD/MM/YYYY HH:MM:SS')}
                  </p>
                ) : (
                  <ButtonContainer>
                    <StyledButton
                      click={() => {
                        setTakenModal(true);
                        setClickedId(id);
                      }}
                      text="DAR BAIXA"
                    />
                    <StyledButton
                      click={() => {
                        setReturnedModal(true);
                        setClickedId(id);
                      }}
                      text="RETORNAR AO ESTOQUE"
                    />
                  </ButtonContainer>
                )}
              </ItemContainer>
            )
          )
        ) : (
          <p style={{ textAlign: 'center' }}>Nenhum prêmio resgatado.</p>
        )}
        <PrizeReports redeemed={redeemed} stock={stock} />
      </CardContainer>
    </Wrapper>
  );
};

export default SupplierPrizeCard;
