import produce from 'immer';

export default (data, action) => {
  // ----> Logs
  // console.log('Called faq reducer');
  // console.log(`Called action type ${action.type}`);
  // console.log('Prev state:');
  // console.table(faq);
  // console.log('Action payload:');
  // console.table(action);

  switch (action.type) {
    case 'setAdvantages':
      return produce(data, draft => {
        draft.advantages = action.newAdvantages;
      });

    case 'setAdvantagesDiscount':
      return produce(data, draft => {
        draft.advantagesDiscount = action.newAdvantagesDiscount;
      });

    case 'setAdvantagesSegments':
      return produce(data, draft => {
        draft.advantagesSegments = action.newAdvantagesSegments;
      });

    default:
      return data;
  }
};
