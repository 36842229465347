import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import moment from 'moment';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { CardContainer } from './';

//@TODO Responsive CSS when implementing full page
//@TODO PropTypes when implementing full page
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 1em 0;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ last }) =>
    !last &&
    `border-bottom: 1px solid ${theme.colors.grey2}; margin-bottom: 1em`};
  padding: 0 0 1em 0;
`;

const EventTitle = styled.h4`
  color: ${theme.colors.text_heading};
  /* margin: 0; */
  align-self: flex-start;
`;

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DataText = styled.p`
  color: ${theme.colors.grey3};
  font-size: 0.8em;
  margin: 0;
  width: 18em;
  align-self: center;

  ${({ client }) => client && 'text-decoration: underline;'}
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const ServicesTitle = styled.h2`
  color: ${theme.colors.grey3};
  display: flex;
  text-align: center;
  font-size: ${theme.fontSize}px;
  justify-content: center;
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    width: 40%;
  }

  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 0.5em;
  word-wrap: break-word;
`;

const SegmentContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 50%;
  }

  display: flex;
  width: 30%;
  align-items: center;
  justify-content: flex-start;
`;

const SupplierCalendarCard = ({ data }) => {
  const { date, venue, brideName, city, hiredSegments, otherSuppliers } = data;
  return (
    <Wrapper>
      <CardContainer>
        <DataContainer>
          <EventTitle>Evento: {moment(date).format('DD/MM/YYYY')}</EventTitle>
          <DataText client>{brideName}</DataText>
        </DataContainer>
        <DataContainer>
          <DataText>{venue}</DataText>
          <DataText>
            Local: {city ? city.uf : 'Indisponível'} -{' '}
            {city ? city.nomeCidade : 'Indisponível'}
          </DataText>
        </DataContainer>

        {hiredSegments && hiredSegments.length > 0 && (
          <>
            {' '}
            <ServicesTitle>Serviços</ServicesTitle>
            {hiredSegments.map(
              ({ codSegmento, Segmento, linkIcone }, index) => (
                <ItemContainer
                  key={codSegmento}
                  last={hiredSegments.length - 1 === index}
                >
                  <SegmentContainer>
                    <StyledIcon src={linkIcone} />
                    <ItemText>{Segmento}</ItemText>
                  </SegmentContainer>
                </ItemContainer>
              )
            )}
          </>
        )}
        {otherSuppliers && otherSuppliers.length > 0 && (
          <>
            <ServicesTitle>Outros Fornecedores</ServicesTitle>
            {otherSuppliers.map(({ nomeDivulgacao, codFornecedor }, index) => (
              <ItemContainer
                key={codFornecedor}
                last={otherSuppliers.length - 1 === index}
              >
                <SegmentContainer>
                  <ItemText>{nomeDivulgacao}</ItemText>
                </SegmentContainer>
              </ItemContainer>
            ))}
          </>
        )}
      </CardContainer>
    </Wrapper>
  );
};

export default SupplierCalendarCard;
