import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from '../styles/theme';
import { navigate } from '@reach/router';
import { medias } from '../utils/mediaQueries';

const Wrapper = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin: 1em;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  padding: ${props => (props.disabled ? 0 : '')};
`;

const Title = styled.p`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeMed}px;
  }
  font-size: ${theme.fontSizeXlg}px;
  color: ${props =>
    props.disabled ? `${theme.colors.grey3}` : `${theme.colors.primary}`};
  text-align: left;
  font-weight: bold;
  margin: 0;
`;

const Description = styled.p`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
  }
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  text-align: left;
  font-weight: 300;
  margin: 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1em;
`;

const IconWrapper = styled.div`
  height: 120px;
  width: 120px;
`;

export const Step = ({
  icon,
  title,
  description,
  path,
  active,
  supplierDescription,
}) => {
  const handleClick = () => {
    if (active) {
      if (supplierDescription) {
        navigate(`fornecedor/${path}`);
        return;
      }
      navigate(path);
      return;
    }
    return;
  };

  return (
    <Wrapper onClick={handleClick} disabled={!active}>
      <IconWrapper>{icon}</IconWrapper>
      <TextWrapper>
        <Title disabled={!active}>{title}</Title>
        <Description disabled={!active}>{description}</Description>
        {supplierDescription && supplierDescription}
      </TextWrapper>
    </Wrapper>
  );
};

Step.propTypes = {
  active: PropTypes.bool,
};

Step.defaultProps = {
  active: true,
};

export default Step;
