import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import moment from 'moment';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import {
  CardContainer,
  Button,
  MaterialInput,
  ConfirmModal,
  MoneyMask,
} from './';
import ModalContainer, { ModalTitle } from './ModalContainer';
import { supplier } from '../services/api';
import dateUndefined from '../utils/undefinedDate';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  margin: 0 0 1em 0;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ last }) =>
    !last &&
    `border-bottom: 1px solid ${theme.colors.grey2}; margin-bottom: 1em`};
  padding: 0 0 1em 0;

  h4 {
    color: ${theme.colors.text_heading};
    align-self: flex-start;
  }
`;

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const DataText = styled.p`
  color: ${theme.colors.grey3};
  font-size: 0.8em;
  margin: 0;
  width: 18em;
  align-self: flex-start;
`;

const SegmentContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 50%;
  }

  display: flex;
  width: 30%;
  align-items: center;
  justify-content: flex-start;
`;

const SegmentName = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    width: 40%;
  }

  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 0.5em;
  word-wrap: break-word;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const ContactForm = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.primary};
  margin: 0 0 10px 5px;
  text-decoration: underline;
`;

const ContactTitle = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
  }

  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  margin: 10px 0 3px 0;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const StyledButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
    width: 15em;
  }

  background-color: ${theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: ${theme.colors.white};
  font-size: 0.8em;
  width: 18em;
  ${props => props.margin && 'margin-right: 1em;'}
`;

const ModalButton = styled.div`
  background-color: ${theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: ${theme.colors.white};
  width: 90%;
  height: 2.5em;
  font-size: 0.8em;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 2em;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  margin-top: 1em;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const GenerateContractModal = ({ onCloseModal, opened, onClickConfirm }) => {
  const [budget, setBudget] = useState('');

  return (
    <ModalContainer opened={opened} closeModal={onCloseModal} hasArabesco>
      <ModalWrapper>
        <ModalTitle>REGISTRAR CONTRATO</ModalTitle>
        <MaterialInput
          style={{ width: '90%' }}
          label="Valor do serviço"
          value={budget}
          onChange={event => setBudget(event.target.value)}
          InputProps={{
            inputComponent: MoneyMask,
          }}
        />
        <ModalButton onClick={() => onClickConfirm(budget)}>
          REGISTRAR
        </ModalButton>
      </ModalWrapper>
    </ModalContainer>
  );
};

const SupplierOpportunityCard = ({ data, selected, refreshData }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState({});
  const [closedContracts, setClosedContracts] = useState([]);
  const [refuseModal, setRefuseModal] = useState(false);

  const markAsContacted = async id => {
    const payload = {
      opportunityId: id,
    };

    const response = await supplier.markAsContacted(payload);

    if (!response.ok) {
      toast.error('Falha ao marcar oportunidade como contactada.');
      return;
    }

    toast.success('Oportunidade marcada como contactada com sucesso!');
    refreshData();
  };

  const generateContract = async budget => {
    const payload = {
      codEvent: selectedOpportunity.eventId,
      budgetId: selectedOpportunity.id,
      contractValue: budget,
    };

    const response = await supplier.registerContract(payload);

    if (!response.ok) {
      response.status == 413
        ? toast.error(response.data.message)
        : toast.error('Falha ao gerar o contrato.');
      setSelectedOpportunity({});
      return;
    }

    setModalOpened(false);
    setClosedContracts([...closedContracts, selectedOpportunity.id]);
    setSelectedOpportunity({});
    toast.success('Contrato gerado com sucesso!');
    refreshData();
  };

  const handleButtonClick = (id, eventId) => {
    if (selected === 0) {
      markAsContacted(id);
      return;
    }

    if (selected === 1) {
      setSelectedOpportunity({ id, eventId });
      setModalOpened(true);
      return;
    }

    return;
  };

  const handleRefuseClick = id => {
    setSelectedOpportunity({ id });
    setRefuseModal(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
    setSelectedOpportunity({});
  };

  const refuseOpportunity = async () => {
    const payload = {
      opportunityId: selectedOpportunity.id,
    };

    const response = await supplier.refuseOpportunity(payload);

    if (!response.ok) {
      toast.error('Falha ao recusar a oportunidade selecionada!');
      setSelectedOpportunity({});
      return;
    }

    toast.success('Oportunidade recusada com sucesso!');
    setSelectedOpportunity({});
    refreshData();
  };

  return (
    <Wrapper>
      <GenerateContractModal
        opened={modalOpened}
        onClickConfirm={budget => generateContract(budget)}
        onCloseModal={handleCloseModal}
      />
      <CardContainer>
        {data.length > 0 ? (
          data.map(
            (
              {
                id,
                eventId,
                clientName,
                eventDate,
                segmentIcon,
                segmentName,
                contactForm,
                advantage,
                eventName,
                localCerimonia,
                localRecepcao,
                convidados,
                cidadeCerimonia,
              },
              index
            ) => (
              <ItemContainer key={id} last={index === data.length - 1}>
                <h4>Evento: {eventName}</h4>
                <DataContainer>
                  <DataText>
                    {' '}
                    <strong>Data: </strong>
                    {eventDate === dateUndefined
                      ? 'Data indefinida'
                      : moment(eventDate).format('DD/MM/YYYY')}
                  </DataText>
                  <DataText>
                    {' '}
                    <strong>Cliente: </strong> {clientName}
                  </DataText>
                  <DataText>
                    <strong>Cidade: </strong>
                    {cidadeCerimonia}
                  </DataText>
                </DataContainer>
                <DataContainer>
                  <DataText>
                    <strong>Local da Cerimônia: </strong>
                    {localCerimonia}
                  </DataText>
                  <DataText>
                    <strong>Local da Recepção: </strong>
                    {localRecepcao}
                  </DataText>
                </DataContainer>
                <DataContainer>
                  <DataText>
                    <strong>Convidados: </strong>
                    {convidados}
                  </DataText>
                </DataContainer>
                <DataContainer>
                  <SegmentContainer>
                    {eventId && <StyledIcon src={segmentIcon} />}
                    <SegmentName>{segmentName}</SegmentName>
                  </SegmentContainer>
                </DataContainer>
                {advantage && (
                  <DataContainer>
                    <DataText>Benefício: {advantage}</DataText>
                  </DataContainer>
                )}
                <DataContainer>
                  <ContactContainer>
                    <ContactTitle>Contactar por:</ContactTitle>
                    <ContactForm>{contactForm}</ContactForm>
                  </ContactContainer>
                  {selected === 1 && (
                    <StyledButton
                      text="RECUSAR"
                      click={() => handleRefuseClick(id)}
                      margin
                    />
                  )}
                  {!(selected === 1 && !eventId) && (
                    <StyledButton
                      text={
                        selected === 1
                          ? 'GERAR CONTRATO'
                          : 'JÁ ENTROU EM CONTATO'
                      }
                      click={() => handleButtonClick(id, eventId)}
                      disabled={closedContracts.includes(id)}
                    />
                  )}
                </DataContainer>
              </ItemContainer>
            )
          )
        ) : (
          <p style={{ textAlign: 'center' }}>
            Nenhuma oportunidade disponivel.
          </p>
        )}
      </CardContainer>
      <ConfirmModal
        opened={refuseModal}
        text="Tem certeza que deseja recusar a oportunidade contactada?"
        onClickConfirm={() => refuseOpportunity(selectedOpportunity)}
        closeModal={() => setRefuseModal(false)}
      />
    </Wrapper>
  );
};

export default SupplierOpportunityCard;
