import React, { useEffect, useRef, useState } from 'react';
import FaChevronUp from 'react-icons/lib/fa/chevron-up';
import FaChevronDown from 'react-icons/lib/fa/chevron-down';
import { FilterIcon } from './icons';
import { useStateValue } from '../state';
import styled from 'styled-components';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import moment from 'moment';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: end;

  max-width: 92%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  font-family: Lato;
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  text-align: left;
  color: ${theme.colors.grey3};
  @media ${medias.phoneMax} {
    margin-right: 18px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 10;
`;

const StyledListItem = styled.li`
  @media ${medias.laptopMin} {
    background-color: ${hexToRgb(theme.colors.secondary, 0.8)};
  }

  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-family: Lato;
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${theme.colors.white};
  list-style: none;
  margin 0;
  padding: 0 2px 0 5px;
  background-color: ${theme.colors.secondary};
  border: 0.2px solid;
  border-color: white;
  line-height: 1.8rem;
  


  &:hover {
    color: white;
    background-color: ${theme.colors.primary};
    cursor: pointer;
  }
`;

const StyledIconUp = styled(FaChevronUp)`
  @media ${medias.phoneMax} {
    margin: 10px 5px 0 0;
  }
  margin-top: 10px;
`;

const StyledIconDown = styled(FaChevronDown)`
  @media ${medias.phoneMax} {
    margin: 10px 5px 0 0;
  }
  margin-top: 10px;
`;

const StyledFilterIcon = styled(FilterIcon)`
  margin-top: 0px;
`;

const DropdownContracts = props => {
  const [
    {
      session: { supplierContracts }
    },
  ] = useStateValue();

  const { onSelectItem, placeholder, region } = props;

  const node = useRef();
  const [listOpened, setListOpened] = useState(false);
  const [value, setValue] = useState(false);

  const handleClick = event => {
    if (node.current.contains(event.target)) {
      return;
    }

    setListOpened(false);
  };

  const handleChange = (item, value) => {
    setValue(value);
    onSelectItem(item);
    setListOpened(false);
  };

  const getItemName = (item) => {
    const { inicio, termino, codRegiao } = item;
    const i = inicio.slice(0, 10);
    const t = termino.slice(0, 10);
    const from = 'De  ' + i.substr(5, 3) + i.substr(0, 4);
    const to = ' até ' + t.substr(5, 3) + t.substr(0, 4);
    const contractInterval =
      from.replace('-', '/') + to.replace('-', '/') + ' - ' + codRegiao.Regiao;
    return contractInterval;
  };

  const getItemRegion = (item) => {
    const { inicio, termino, codRegiao } = item;
    const today = moment();
    if (moment(termino) > today && moment(inicio) < today) {
    return codRegiao.Regiao;
    }
    return '';
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <StyledContainer ref={node}>
      <StyledHeader onClick={() => setListOpened(!listOpened)}>
        <StyledFilterIcon />
        {value || placeholder}
        {listOpened ? (
          <StyledIconUp color={theme.colors.primary} />
        ) : (
          <StyledIconDown color={theme.colors.primary} />
        )}
      </StyledHeader>

      {listOpened && (
        <StyledList>
          {!region && (
            <StyledListItem
              onClick={() => handleChange('all', 'Todos contratos')}
            >
              Selecionar todos
            </StyledListItem>
          )}
          {supplierContracts && supplierContracts.length > 0 ? (
            supplierContracts.map(item => {
              const contractInterval = !region
                ? getItemName(item)
                : getItemRegion(item);
              return (
                <StyledListItem
                  key={item.codContratoFornecedor}
                  onClick={() => handleChange(item, contractInterval)}
                >
                  {contractInterval}
                </StyledListItem>
              );
            })
          ) : (
            <StyledListItem key={0}>Aguarde...</StyledListItem>
          )}
        </StyledList>
      )}
    </StyledContainer>
  );
};

export default DropdownContracts;
