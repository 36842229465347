import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import FormControl from '@material-ui/core/FormControl'

import theme from '../styles/theme'
import {
  Button,
  MaterialInput,
  /*MaterialInputLabel,*/
  MaterialSelect,
  MaterialMenuItem,
  MoneyMask,
} from './'
import { bride } from '../services/api'

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
  ${({ borderBottom }) =>
    borderBottom &&
    `border-bottom: 1px solid ${theme.colors.grey3}; padding-bottom: 1.5em;`}
`

const StyledInput = styled(MaterialInput)`
  width: 45%;
`

const StyledButton = styled(Button)`
  border: 2px solid ${theme.colors.text_heading};
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  width: 45%;
  height: 1.8em;
  padding: 0;
  ${({ add }) => add && `margin-top: 0.5em;`}
`

const reasonOptions = [
  'Localização do Fornecedor',
  'Valor',
  'Fornecedor não possui a data disponível',
  'Gostei mais de outro fornecedor',
  'Desisti de contratar esse segmento',
  'Outro',
]

export const QuoteItem = ({
  id,
  name,
  value,
  motivoNaoFechamento,
  filled,
  reloadFunction,
  updateFunction,
}) => {
  const [supplierName, setSupplierName] = useState(name || '')
  const [supplierValue, setSupplierValue] = useState(value || '')
  const [reasonValue, setReasonValue] = useState(motivoNaoFechamento || 0)

  const isClickAvaliable = name && value && reasonValue !== 0

  const updateQuote = async () => {
    const payload = {
      quoteId: id,
      supplier: supplierName,
      value: supplierValue,
      declinedReason: reasonValue === 0 ? '' : reasonValue,
    }
    const response = await bride.confirmOutsideQuote(payload)
    if (response.ok) {
      toast.success('Dados do orçamento confirmados.')
      reloadFunction(null)
      updateFunction()
    } else {
      toast.error('Erro ao confirmar orçamento.')
    }
  }

  return (
    <Fragment>
      <RowContainer>
        <StyledInput
          value={supplierName}
          onChange={event => setSupplierName(event.target.value)}
          label="Empresa"
        />
        <MaterialInput
          style={{ width: '45%' }}
          onChange={event => setSupplierValue(event.target.value)}
          value={filled ? value : supplierValue}
          label="Valor"
          disabled={filled}
          InputProps={{
            inputComponent: MoneyMask,
          }}
        />
      </RowContainer>
      <RowContainer borderBottom>
        <FormControl style={{ width: '45%' }} disabled={filled}>
          <MaterialSelect
            name="reason"
            onChange={event => setReasonValue(event.target.value)}
            value={reasonValue}
            inputProps={{
              name: 'reason',
              id: 'reason',
            }}
          >
            <MaterialMenuItem value={0}>
              Motivo da não contratação
            </MaterialMenuItem>
            {reasonOptions.map(item => (
              <MaterialMenuItem key={item} value={item}>
                {item}
              </MaterialMenuItem>
            ))}
          </MaterialSelect>
        </FormControl>
        {!filled && (
          <StyledButton
            text="CONFIRMAR"
            click={updateQuote}
            disabled={!isClickAvaliable}
          />
        )}
      </RowContainer>
    </Fragment>
  )
}

export default QuoteItem
