import { create } from 'apisauce';

const api = create({
  baseURL: process.env.GATSBY_API, // eslint-disable-line
});

export const updateToken = newToken =>
  api.setHeaders({
    Authorization: newToken,
    //contentType: 'application/x-www-form-urlencoded',
  });

export const forgetPassoword = {
  forgetPassword: props => api.post(`/signin/forget-my-password`, props),
};

export const siteContent = {
  getCNPJ: cnpj => api.get(`/site-content/cnpj?cnpj=${cnpj}`),
  faq: () => api.get(`/site-content/faq`),
  testimonials: () => api.get(`/site-content/testimonial`),
  regions: () => api.get(`/site-content/regions`),
  protocolTypes: () => api.get(`/site-content/protocol-types`),
  applicantTypes: () => api.get(`/site-content/applicant-types`),
  suppliersOrigin: () => api.get(`/signup/supplier-origin`),
  suppliers: () => api.get(`/site-content/suppliers`),
  filteredSuppliersSegment: (segment, region) => {
    if (region) {
      return api.get(
        `/site-content/suppliers?segment=${segment}&region=${region}`
      );
    }
  },
  filteredSuppliersSearch: (search, region) => {
    if (search && region) {
      return api.get(
        `/site-content/suppliers?search=${search}&region=${region}`
      );
    }
  },
  supplierSegments: () => api.get(`/site-content/suppliers-segments`),
  segments: () => api.get(`/site-content/segments`),
  advantages: () => api.get(`/site-content/advantages`),
  advantagesDiscount: () => api.get(`/site-content/advantages-discounts`),
  advantagesSegments: () => api.get(`/site-content/advantages-segments`),
  contactUsForm: props => api.post(`/site-content/new-contact-form`, props),
  cities: codUf =>
    !codUf
      ? api.get(`/site-content/cities`)
      : api.get(`/site-content/cities/${codUf}`),
  sliderImages: () => api.get(`/site-content/home-slider`),
  budget: props => api.post(`/site-content/request-budget`, props),
  banners: () => api.get(`/site-content/banners-site`),
  getSupplier: id => api.get(`/site-content/supplier?supplierId=${id}`),
  getSegments: codFornecedor =>
    api.get(
      `/site-content/get-supplier-segments?codFornecedor=${codFornecedor}`
    ),
  JoinBrideFromIndication: payload =>
    api.post(`/site-content/bride-join-indication`, payload),
};

export const joinUs = {
  bride: props => api.post(`/signup/new-waiting-list-bride`, props),
  waitingListSupplier: props =>
    api.post('/signup/new-waiting-list-supplier', props),
  franchise: props => api.post('/signup/new-waiting-list-franchises', props),
};

export const userData = {
  changePassword: payload => api.post('/userdata/change-password', payload),
  setBasicData: payload => api.put(`/userdata/set-basic-data`, payload),
  setMarriageInfo: payload =>
    api.put('/userdata/set-basic-marriage-data', payload),
  registerAccess: payload => api.post('/userdata/register-access', payload),
};

export const bride = {
  getEventDate: codEvento =>
    api.get(`/bride/person-current-event-date?codEvent=${codEvento}`),
  favoritesAdvantages: (codEvent, filter, inputSearched) =>
    api.get(
      `/bride/favorites-advantages?codEvent=${codEvent}${
        filter ? `&filter=${filter}` : ''
      }${inputSearched ? `&inputSearched=${inputSearched}` : ''}`
    ),
  featuredAdvantages: selectedRegion =>
    api.get(`/bride-advantages/featured-advantages?region=${selectedRegion}`),
  favoritesSuppliers: (codEvent, filter, inputSearched) =>
    api.get(
      `/bride/favorites-suppliers?codEvent=${codEvent}${
        filter ? `&filter=${filter}` : ''
      }${inputSearched ? `&inputSearched=${inputSearched}` : ''}`
    ),
  favoritesPrizes: (codEvent, filter, inputSearched) =>
    api.get(
      `/bride/favorites-prizes?codEvent=${codEvent}${
        filter ? `&filter=${filter}` : ''
      }${inputSearched ? `&inputSearched=${inputSearched}` : ''}`
    ),
  getFavoriteEvent: (supplierId, eventId) =>
    api.get(
      `/event/get-favorite-event?eventId=${eventId}&supplierId=${supplierId}`
    ),
  getSupplierAdvantages: (codEvent, codSupplier) =>
    api.get(
      `/bride/supplier-advantages?codEvent=${codEvent}&codSupplier=${codSupplier}`
    ),
  login: payload => api.post(`/userdata/login`, payload),
  userInfo: () => api.get(`/userdata/user-info`),
  budget: props => api.post(`/bride/budget`, props),
  brideEvents: () => api.get(`/bride/events`),
  buyEventRegion: payload => api.post('/bride/buy-event-region', payload),
  disableEvent: payload => api.put('/event-card/disable-event', payload),
  addUserToEvent: props => api.post(`/bride/add-user-to-event`, props),
  deleteUserFromEvent: codUserEvent =>
    api.delete(`/bride/remove-user-from-event/${codUserEvent}/`),
  eventRegions: codEvento => api.get(`/bride/event-regions?event=${codEvento}`),
  eventAvaliableRegions: codEvento =>
    `/bride/event-avaliable-regions?event=${codEvento}`,
  indicatePerson: props => api.post(`/bride/indicate-person`, props),
  reedemPrize: props => api.post(`/bride/reedem-prize`, props),
  checklistSegments: codEvento =>
    api.get(`/bride/checklist-segments?event=${codEvento}`),
  mappedSegments: codEvent =>
    api.get(`/bride-home-page/mapped-checklists?event=${codEvent}`),
  deleteSegmentChecklist: id =>
    api.delete(`/bride/remove-checklist-segments?id=${id}`),
  favoriteSegmentChecklist: (id, selectedEvent) =>
    api.put(
      `/bride/favorite-checklist-segment?checklist=${id}&eventId=${selectedEvent}`
    ),
  addSegmentChecklist: props => api.post(`/bride/add-checklist`, props),
  contestContract: props => api.post(`/bride/contest-contract`, props),
  enableChecklist: props => api.post(`/bride/enable-checklist`, props),
  updatePrioritySegments: props =>
    api.post('bride-home-page/update-priority-segments', props),
  hireSupplier: props => api.post(`/bride/hire-supplier`, props),
  getPrizesSegments: () => api.get('/supplier-prizes/prizes-segments'),
  getPrizesSuppliers: () => api.get('/supplier-prizes/prizes-suppliers'),
  getPrizes: (codEvent, codRegiao, page, resPerPage) =>
    api.post(`/bride/prizes`, { codEvent, codRegiao, page, resPerPage }),
  getPrizesBySupplier: (supplier, codEvent, codRegiao, page, resPerPage) =>
    api.post(`/bride/prizes`, {
      supplier,
      codEvent,
      codRegiao,
      page,
      resPerPage,
    }),
  getPrizesBySegment: (segment, codEvent, codRegiao, page, resPerPage) =>
    api.post(`/bride/prizes`, {
      segment,
      codEvent,
      codRegiao,
      page,
      resPerPage,
    }),
  getPrizesByValues: (min, max, codEvent, codRegiao, page, resPerPage) =>
    api.post(`bride/prizes`, {
      minValue: min || '',
      maxValue: max || '',
      codEvent,
      codRegiao,
      page,
      resPerPage,
    }),
  getPrizesByKeyword: (keyword, codEvent, codRegiao, page, resPerPage) =>
    api.post(`/bride/prizes`, {
      keyword,
      codEvent,
      codRegiao,
      page,
      resPerPage,
    }),
  newOutsideQuote: props => api.post('/bride/register-outside-quote', props),
  submitSegmentsForm: props => api.post(`/bride/form-checklist`, props),
  confirmOutsideQuote: props => api.put('/bride/confirm-outside-quote', props),
  addFavorite: props => api.post(`/bride/add-favorite`, props),
  removeFavorite: (codEvent, identifier, codIdent) =>
    api.delete(`/bride/remove-favorite/${codEvent}/${identifier}/${codIdent}`),
  brideAdvantageBudget: props =>
    api.post('/bride-advantages/request-budget', props),
  brideSupplierBudgetCheck: (codEvent, supplierName, segment) =>
    api.get(
      `/bride-suppliers/check-budget?supplierName=${supplierName}&event=${codEvent}&codSegment=${segment}`
    ),
  checkLastBudgetToday: () =>
    api.get(`/bride-suppliers/check-last-budget-today`),
  brideSupplierBudget: props =>
    api.post('/bride-suppliers/request-budget', props),
  rateSupport: props => api.post(`/bride/rate-support`, props),
  rateExecution: props => api.post(`/bride/rate-execution`, props),
  ratePrize: props => api.post(`/bride/rate-prize`, props),
  getSessionData: userId =>
    api.get(`/bride-data/session-data?userId=${userId}`),
  getEventData: eventId => api.get(`/bride-data/event-data?eventId=${eventId}`),
  getEventPoints: (eventId, regionId) =>
    api.get(`bride-data/event-points?eventId=${eventId}&regionId=${regionId}`),
  prioritySegments: codEvent =>
    api.get(`/bride-home-page/get-priority-segments?event=${codEvent}`),
  finishedSegments: codEvent =>
    api.get(`/bride-home-page/get-finished-segments?event=${codEvent}`),
  leftSegments: codEvent =>
    api.get(`/bride-home-page/get-left-segments?event=${codEvent}`),
  unfinishedSegments: codEvent =>
    api.get(`/bride-home-page/get-unfinished-segments?event=${codEvent}`),
  updateUserInfo: props => api.put('/bride/update-person', props),
  updateEventDate: props => api.put('/bride/update-event-date', props),
  getAdvantages: (codEvent, codRegiao, resPerPage, page) =>
    api.get(
      `/bride/advantages?codEvent=${codEvent}&codRegiao=${codRegiao}&page=${page}&resPerPage=${resPerPage}`
    ),
  getAdvantagesByValue: (codEvent, codRegiao, dropdown, resPerPage, page) =>
    api.get(
      `/bride/advantages?segment=${dropdown}&codEvent=${codEvent}&codRegiao=${codRegiao}&page=${page}&resPerPage=${resPerPage}`
    ),
  getAdvantagesByKeyword: (codEvent, codRegiao, input, resPerPage, page) =>
    api.get(
      `/bride/advantages?keyword=${input}&codEvent=${codEvent}&codRegiao=${codRegiao}&page=${page}&resPerPage=${resPerPage}`
    ),
  getAdvantagesFilter: (
    codEvent,
    codRegiao,
    input,
    dropdown,
    resPerPage,
    page
  ) =>
    api.get(
      `/bride/advantages?keyword=${input}&segment=${dropdown}&codEvent=${codEvent}&codRegiao=${codRegiao}&page=${page}&resPerPage=${resPerPage}`
    ),
  updateEvent: payload => api.put('/bride/update-event', payload),
  getSegmentsWithRegisteredSuppliers: () =>
    api.get(`/bride/get-segments-with-registered-suppliers`),
  getSuppliersSegments: () => api.get(`/site-content/suppliers-segments`),
  getSupplierSegments: id =>
    api.get(`/site-content/supplier-segments?supplierId=${id}`),
  getSupplierPortfolio: id =>
    api.get(`/bride-suppliers/portfolio?supplierId=${id}`),
  getSegments: () => api.get(`/site-content/segments`),
  getRatingSupport: codEvent =>
    api.get(`/bride/ratting-support?codEvent=${codEvent}`),
  getRatingExecution: codEvent =>
    api.get(`/bride/ratting-execution?codEvent=${codEvent}`),
  getRatingPrize: codEvent =>
    api.get(`/bride/ratting-prizes?codEvent=${codEvent}`),
  bridePrizes: (codEvent, codRegiao) =>
    api.post(`/bride/prizes`, { codEvent, codRegiao }),
  redemeedPrizes: codEvent =>
    api.get(`/bride/redeemed-prizes?codEvent=${codEvent}`),
  activeSegments: () => api.get(`/bride/get-active-segments`),
};

export const supplier = {
  login: payload => api.post('/userdata/supplier-login', payload),
  getSessionData: () => api.get('/supplier/get-network-data'),
  inviteBrides: payload => api.post('/supplier/indicate-person', payload),
  editBrides: payload => api.post('/supplier/edit-indicated-person', payload),
  getSegments: () => api.get('/supplier-data/get-supplier-segments'),
  updatePrizeToTaken: payload => api.put('/supplier/prize-taken', payload),
  returnPrizeToStock: prize => api.post(`/supplier/prize-returned/${prize}/`),
  getRewards: () => api.get('/supplier-rewards/rewards'),
  getPointsHistory: uid =>
    api.get(`/supplier-rewards/get-points-history?supplierCode=${uid}`),
  redeemReward: codReward =>
    api.post('/supplier-rewards/rewards-redemption', { codReward }),
  markAsContacted: payload =>
    api.put('/suppliers-opportunities/mark-contacted', payload),
  refuseOpportunity: payload =>
    api.put('/suppliers-opportunities/refuse-opportunity', payload),
  registerContract: payload => api.post('/supplier/contract-register', payload),
  addGuest: payload => api.post(`/supplier-data/add-guest`, payload),
  deleteGuest: props => api.delete(`/supplier-data/delete-guest/${props}/`),
  alterGuest: payload => api.put(`/supplier-data/alter-guest`, payload),
  changeData: payload => api.put(`/supplier-data/change-data`, payload),
  getSupplierEvents: (month, date) => {
    if (month) {
      return api.get(`/supplier/get-events?month=${month}`);
    }
    if (date) {
      return api.get(`/supplier/get-events?date=${date}`);
    }
  },
  getOpportunitiesFilteredBrides: (month, date) => {
    if (month) {
      return api.get(`/supplier/get-events?month=${month}&type=opportunities`);
    }
    if (date) {
      return api.get(`/supplier/get-events?date=${date}&type=opportunities`);
    }
  },
  contestContract: codContract =>
    api.post(`/supplier/contest-contract/`, { codContract }),
  confirmContract: payload => api.post('/supplier/confirm-contract/', payload),
  getOpportunitiesBrides: () => api.get(`/suppliers-opportunities/get-brides`),
  checkUserEmail: email => api.get(`/supplier/check-email/${email}`),
  getBridesFilteredByType: (order, type, page, resPerPage, supplierCategory) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=${type}&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}`
    ),
  filterReports: codContratoFornecedor =>
    api.get(
      `/supplier/get-report-by-contract?codContratoFornecedor=${codContratoFornecedor}`
    ),
  checkContractValue: (valBeforeComma, valAfterComma, codEvento) =>
    api.post(
      `/supplier/check-contract-value?beforeComma=${valBeforeComma}&afterComma=${valAfterComma}&codEvento=${codEvento}`
    ),
  insertProfile: payload => api.post(`/supplier/insert-profile`, payload),
  getActiveRegions: () => api.get('/supplier/get-active-regions'),
};

export const supplierContent = {
  changeAdvantageInativation: props =>
    api.post(`/supplier/change-advantage-inativation`, props),
  deleteAdvantage: cod => api.delete(`/supplier/delete-advantage/${cod}/`),
  addAdvantage: props => api.post(`/supplier/new-advantages`, props),
  alterAdvantage: props => api.put(`/supplier/alter-advantage`, props),
  getBridesFilteredByType: type =>
    api.get(`/supplier/get-filtered-brides?type=${type}`),
  getBridesBySegment: (order, type, data, page, resPerPage, supplierCategory) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=${type}&segment=${data}&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}`
    ),
  getBridesByRegion: (order, type, data, page, resPerPage, supplierCategory) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=${type}&region=${data}&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}`
    ),
  getBridesByDate: (
    order,
    type,
    initialDate,
    finalDate,
    page,
    resPerPage,
    supplierCategory
  ) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=${type}&initialDate=${initialDate}&finalDate=${finalDate}&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}`
    ),
  getBridesByDay: (date, page) =>
    api.get(
      `/supplier/get-filtered-brides?initialDate=${date}&finalDate=${date}&type=potenciais&page=${page}`
    ),
  getBridesByRegister: (
    order,
    type,
    initialDate,
    finalDate,
    page,
    resPerPage,
    supplierCategory
  ) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=${type}&registerFrom=${initialDate}&registerTo=${finalDate}&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}`
    ),
  getBridesByName: (order, type, data, page, resPerPage) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=${type}&search=${data}&page=${page}&resPerPage=${resPerPage}`
    ),
  getBridesByInvitation: (
    order,
    type,
    dataMin,
    dataMax,
    page,
    resPerPage,
    supplierCategory
  ) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=${type}&invitedMin=${dataMin}&invitedMax=${dataMax}&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}`
    ),
  getBridesBySupplier: (order, data, page, resPerPage, supplierCategory) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=convidados&supplierCode=${data}&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}`
    ),
  getBridesByBrides: (
    order,
    type,
    brideName,
    brideEmail,
    page,
    resPerPage,
    supplierCategory
  ) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}&type=${type}&${
        brideName !== '' ? `brideName=${brideName}` : ''
      }${brideName !== '' && brideEmail !== '' ? '&' : ''}${
        brideEmail !== '' ? `brideEmail=${encodeURIComponent(brideEmail)}` : ''
      }`
    ),
  getBridesByReception: (
    order,
    type,
    data,
    page,
    resPerPage,
    supplierCategory
  ) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=${type}&reception=${data}&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}`
    ),
  getBridesByInvestment: (
    order,
    type,
    dataFrom,
    dataTo,
    page,
    resPerPage,
    supplierCategory
  ) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=${type}&investmentFrom=${dataFrom}&investmentTo=${dataTo}&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}`
    ),
  getBridesByEventCode: (
    order,
    type,
    data,
    page,
    resPerPage,
    supplierCategory
  ) =>
    api.get(
      `/supplier/get-filtered-brides?order=${order}&type=${type}&eventCode=${data}&&page=${page}&resPerPage=${resPerPage}&supplierCategory=${supplierCategory}`
    ),
  markBrideAsContacted: eventId =>
    api.put(`/supplier-data/mark-contacted-bride?eventId=${eventId}`),
  getContactedBrides: (page, resPerPage) =>
    api.get(
      `/supplier-data/get-contacted-brides?page=${
        page !== '' ? page : 1
      }&resPerPage=${resPerPage !== '' ? resPerPage : 25}`
    ),
  unmarkBrideAsContacted: codEvento =>
    api.delete(`/supplier-data/unmark-contacted-brides/${codEvento}/`),
  getHistoryPointsByContract: codContratoFornecedor =>
    api.get(
      `/supplier-data/point-history${
        codContratoFornecedor ? `?codContrato=${codContratoFornecedor}` : ''
      }`
    ),
  updateMedia: payload => api.post('/supplier-data/update-media', payload),
  getPortfolioLength: () => api.get('/supplier-data/get-portfolio-length'),
  deleteMedia: payload => api.post('/supplier-data/delete-media', payload),
  markDate: (date, unavailable, wholeWeek) =>
    api.post(
      `/supplier-data/mark-date?date=${date}&available=${
        unavailable ? 0 : 1
      }&week=${wholeWeek ? 1 : 0}`
    ),
  unmarkDate: date => api.post(`/supplier-data/unmark-date?date=${date}`),
  unmarkAllDates: () => api.delete(`/supplier-data/unmark-all-dates`),
  changeMediaOrder: payload =>
    api.post(`/supplier-data/change-media-order`, payload),
  getOwnId: () => api.get(`/supplier-data/get-id`),
};

export const tracking = {
  advantage: payload => api.post('/tracking/advantages', payload),
  search: payload => api.post('/tracking/search', payload),
  supplier: payload => api.post('/tracking/suppliers', payload),
  segment: payload => api.post('/tracking/segments', payload),
  supplierClick: payload => api.post('/tracking/supplier-click', payload),
};
