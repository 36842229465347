// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-app-app-jsx": () => import("./../src/app/app.jsx" /* webpackChunkName: "component---src-app-app-jsx" */),
  "component---src-components-external-jsx": () => import("./../src/components/External.jsx" /* webpackChunkName: "component---src-components-external-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-site-contato-jsx": () => import("./../src/site/contato.jsx" /* webpackChunkName: "component---src-site-contato-jsx" */),
  "component---src-site-facaparte-fornecedor-jsx": () => import("./../src/site/facaparte/fornecedor.jsx" /* webpackChunkName: "component---src-site-facaparte-fornecedor-jsx" */),
  "component---src-site-facaparte-franqueado-jsx": () => import("./../src/site/facaparte/franqueado.jsx" /* webpackChunkName: "component---src-site-facaparte-franqueado-jsx" */),
  "component---src-site-facaparte-jsx": () => import("./../src/site/facaparte.jsx" /* webpackChunkName: "component---src-site-facaparte-jsx" */),
  "component---src-site-facaparte-noivo-jsx": () => import("./../src/site/facaparte/noivo.jsx" /* webpackChunkName: "component---src-site-facaparte-noivo-jsx" */),
  "component---src-site-faq-jsx": () => import("./../src/site/faq.jsx" /* webpackChunkName: "component---src-site-faq-jsx" */),
  "component---src-site-fornecedores-jsx": () => import("./../src/site/fornecedores.jsx" /* webpackChunkName: "component---src-site-fornecedores-jsx" */),
  "component---src-site-index-jsx": () => import("./../src/site/index.jsx" /* webpackChunkName: "component---src-site-index-jsx" */),
  "component---src-site-indicacao-noivo-jsx": () => import("./../src/site/indicacao/noivo.jsx" /* webpackChunkName: "component---src-site-indicacao-noivo-jsx" */),
  "component---src-site-parceiro-jsx": () => import("./../src/site/parceiro.jsx" /* webpackChunkName: "component---src-site-parceiro-jsx" */),
  "component---src-site-privacidade-jsx": () => import("./../src/site/privacidade.jsx" /* webpackChunkName: "component---src-site-privacidade-jsx" */),
  "component---src-site-sobre-jsx": () => import("./../src/site/sobre.jsx" /* webpackChunkName: "component---src-site-sobre-jsx" */)
}

