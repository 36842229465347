import styled from 'styled-components';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';

export const PageTitle = styled.h1`
  font-size: 1rem;
  font-weight: ${theme.bodyWeight};
  margin: 1rem 0 3rem 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  ::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -0.5em;
    transform: translateX(-50%);
    width: 3em;
    height: 3px;
    background: ${theme.colors.primary};
  }
`;

export const PageWrapper = styled.main`
  width: 90%;
  margin: 2rem auto 6rem;
  padding: 0 1rem;


  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${medias.phoneMax} {
    width: 100%;
    padding: 0;
  }
`;

export const AttentionContainer = styled.section`
  margin-bottom: 1.2em;
  h2 {
    font-size: 1rem;
  }
  p {
    font-size: 0.9rem;
  }
`;

export const PointsContainer = styled.section`
  width: 100%;
  background-color: ${theme.colors.text_heading};
  color: white;
  padding: 0.75rem 0.5rem;
  margin-bottom: 2em;
  text-align: center;
  p {
    margin: 0;
  }
  b {
    color: ${theme.colors.primary};
  }
`;

export const Title = styled.h4`
  font-size: ${theme.fontSizeLg * 0.9}px;
  font-weight: ${theme.bodyWeight};
  margin: 40px 0 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenteredText = styled.p`
  text-align: center;
`;