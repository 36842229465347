import produce from 'immer';

export default (data, action) => {
  // ----> Logs
  // console.log('Called faq reducer');
  // console.log(`Called action type ${action.type}`);
  // console.log('Prev state:');
  // console.table(faq);
  // console.log('Action payload:');
  // console.table(action);

  switch (action.type) {
    case 'setSuppliers':
      return produce(data, draft => {
        draft.suppliers = action.newSuppliers;
      });

    case 'setSupplierSegments':
    return produce(data, draft => {
      draft.supplierSegments = action.newSupplierSegments;
    });

    default:
      return data;
  }
};
