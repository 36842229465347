const initialState = {
  loginModal: false,
  passwordModal: false,
  fianceeAccess: false,
  supplierAccess: false,
  franchiseAccess: false,
  restrictAccess: false,
  suppliersInfo: false,
  budgetForm: false,
  detailsAndAdvantages: false,
  mobileMenu: false,
  prizesFilters: false,
  quotesModal: false,
  confirmModal: false
}

export default initialState;
