import React from 'react';
import PropTypes from 'prop-types';

import { GlobalStyles } from '../../styles';
import { FallbackPageMeta } from '../';

const LayoutBasis = ({ children }) => (
  <>
    <FallbackPageMeta />
    <GlobalStyles />
    {children}
  </>
);

LayoutBasis.propTypes = {
  children: PropTypes.node,
};

export default LayoutBasis;
