import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeMed};
  padding: 50px;
`;

export const EmptyList = () => {
  return (
    <Wrapper>Lista vazia!</Wrapper>
  );
};

export default EmptyList;