import React from 'react';
import styled from 'styled-components';
import { medias } from '../utils/mediaQueries';
import moment from 'moment';
import { useFormState } from 'react-use-form-state';
import { toast } from 'react-toastify';
import theme from '../styles/theme';
import { PageWrapper, PageTitle } from './pageComponents';
import CardContainer from './CardContainer';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import {
  MaterialInput,
  MaterialDatePicker,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialSelect,
  Button,
  Loader,
} from '.';
import { useStateValue } from '../state';
import { FormControl } from '@material-ui/core';
import { supplier, siteContent } from '../services/api';
import { CheckListIcon, UserIcon } from './icons';
import phoneMask from '../utils/phoneMask';
import dateUndef from '../utils/undefinedDate';

const initialProfileFormState = {
  name: '',
  email: '',
  mobilePhone: '',
  fixPhone: '',
  weddingDate: '',
  local: '',
  codRegion: null,
};

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 100%;
  }
  .half {
    flex-basis: 45%;
  }
  button[type='submit'] {
    background-color: ${theme.colors.text_heading};
    color: white;
    padding: 0.5em 1em;
    margin-top: 2rem;
  }
`;

const InviteButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: ${theme.fontSizeSm}px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 90%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin-top: 1em;
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const SuccessWrapper = styled(PageWrapper)`
  text-align: center;
  button {
    /* color: ${theme.colors.text_heading} !important; */
    margin: 1rem auto 0;
  }
`;

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width: 100%;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ border }) =>
    border &&
    `border: 1px solid ${theme.colors.grey2}; margin-bottom: 1em; padding: 0.2em 0.9em;`};

  h4 {
    color: ${theme.colors.text_heading};
    align-self: flex-start;
  }
`;

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const DataText = styled.p`
  color: ${theme.colors.grey3};
  font-size: 0.8em;
  margin: 0;
  width: 18em;
  align-self: flex-start;
`;

const ContactText = styled.p`
  color: ${theme.colors.text_heading};
  font-size: 0.8em;
  margin: 0;
  width: 18em;
  align-self: flex-start;
`;

const UserSpan = styled.span`
  color: ${theme.colors.yellow};
  font-weight: bold;
`;

const StatusSpan = styled.span`
  color: ${theme.colors.yellow};
  font-weight: bold;
`;

const StyledFormControl = styled(props => <FormControl {...props} />)`
  width: 45%;
`;

const InvitePersonForm = () => {
  const [formState, { text, email, tel, raw }] = useFormState(
    initialProfileFormState,
    {
      withIds: true,
    }
  );
  const [
    {
      generalData: { states },
    },
    dispatch,
  ] = useStateValue();

  useFetch({
    url: urls.siteContent.states,
    method: method.GET,
    condition: () => (!states ? true : false),
    callback: data =>
      dispatch({
        type: 'setStates',
        newStates: data,
      }),
  });

  useFetch({
    url: urls.supplierContent.getInsertedProfiles,
    method: method.GET,
    condition: () => (!personList ? true : false),
    callback: data => data && setPersonList(data),
  });

  const {
    name,
    email: _email,
    mobilePhone,
    local,
    codRegion,
  } = formState.values;

  const [weddingDate, setDate] = React.useState(null);
  const [dateUndefined, setDateUndefined] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [availableCities, setAvailableCities] = React.useState(null);
  const [stateLabel, setStateLabel] = React.useState(null);
  const [personList, setPersonList] = React.useState(null);

  const isClickAvaliable =
    name &&
    _email &&
    mobilePhone &&
    (weddingDate || dateUndefined) &&
    local &&
    codRegion !== 0;

  const handleSuccessSubmit = payload => {
    if (personList && personList.length > 0) {
      setPersonList([...personList, payload]);
    } else {
      setPersonList([payload]);
    }
    setSuccess(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!codRegion || !_email || !local || !mobilePhone || !name) {
      toast.error('Preencha todos os campos!');
      setLoading(false);
      return;
    }

    const res = await supplier.insertProfile({
      ...formState.values,
      weddingDate: dateUndefined
        ? null
        : moment(weddingDate).format('YYYY-MM-DD'),
    });

    res.ok
      ? handleSuccessSubmit(res.data)
      : res.status === 413
      ? toast.error(
          'Noivo já existente no sistema ou aguardando inserção via lista de espera!'
        )
      : toast.error('Houve um problema com seu envio, tente novamente!');

    setLoading(false);
  };

  const resetForm = () => {
    formState.clear();
    setDate(null);
    setSuccess(false);
  };

  const fetchCities = async codUf => {
    const response = await siteContent.cities(codUf);
    response.ok && response.data
      ? setAvailableCities(response.data)
      : toast.error('Não foi possivel recuperar as cidades!');
  };

  if (success) {
    return (
      <SuccessWrapper>
        <PageTitle>Perfil inserido com sucesso!</PageTitle>
        <p>
          <CheckListIcon /> {formState.values.name} convidado(a) com sucesso!
        </p>
        <Button click={resetForm} text="Inserir outros" />
      </SuccessWrapper>
    );
  }

  return (
    <PageWrapper>
      <PageTitle>Inserir Pessoa (Perfil)</PageTitle>
      <CardContainer>
        <Form onSubmit={handleSubmit}>
          <MaterialInput
            disabled={loading}
            {...text('name')}
            required
            label="Nome"
          />
          <MaterialInput
            disabled={loading}
            {...email('email')}
            required
            label="E-mail"
          />
          <RowContainer>
            <MaterialInput
              {...tel('mobilePhone')}
              disabled={loading}
              required
              label="Telefone celular"
              className="half"
              onChange={e =>
                formState.setField('mobilePhone', phoneMask(e.target.value))
              }
            />
            <MaterialInput
              {...tel('fixPhone')}
              disabled={loading}
              label="Telefone fixo"
              className="half"
              onChange={e =>
                formState.setField('fixPhone', phoneMask(e.target.value))
              }
            />
          </RowContainer>
          <RowContainer>
            <StyledFields>
              <MaterialDatePicker
                label="Data do casamento"
                format="DD/MM/YYYY"
                value={weddingDate}
                onChange={setDate}
                disabled={dateUndefined}
                style={{ width: '100%' }}
              />
              <StyledFieldset>
                <Checkbox
                  type="checkbox"
                  name="hasDate"
                  value="Não definida"
                  onChange={() => setDateUndefined(!dateUndefined)}
                  checked={dateUndefined}
                />
                <StyledSpan>Não definida</StyledSpan>
              </StyledFieldset>
            </StyledFields>
          </RowContainer>
          <RowContainer>
            <MaterialInput
              disabled={loading}
              {...text('local')}
              required
              label="Local da Cerimônia"
            />
          </RowContainer>
          <RowContainer style={{ marginTop: '0.8em' }}>
            <StyledFormControl>
              <MaterialInputLabel htmlFor="state-simple">
                Estado *
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event => {
                  fetchCities(event.target.value);
                  setStateLabel(event.target.value);
                }}
                value={stateLabel || 'Selecione'}
                inputProps={{
                  id: 'state-simple',
                }}
              >
                {states ? (
                  states.map(({ name, cod }) => (
                    <MaterialMenuItem key={cod} value={cod}>
                      {name}
                    </MaterialMenuItem>
                  ))
                ) : (
                  <MaterialMenuItem key={0}>vazio</MaterialMenuItem>
                )}
              </MaterialSelect>
            </StyledFormControl>
            <StyledFormControl required disabled={loading}>
              <MaterialInputLabel htmlFor="city" shrink>
                Cidade
              </MaterialInputLabel>
              <MaterialSelect
                {...raw({
                  name: 'codRegion',
                })}
                onChange={e => formState.setField('codRegion', e.target.value)}
                value={codRegion || ''}
                inputProps={{
                  id: 'city',
                  name: 'city',
                }}
              >
                {availableCities
                  ? availableCities.map(({ nomeCidade, codCidadeIBGE }) => (
                      <MaterialMenuItem
                        key={codCidadeIBGE}
                        value={codCidadeIBGE}
                      >
                        {nomeCidade}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </StyledFormControl>
          </RowContainer>
          {loading ? (
            <Loader />
          ) : (
            <InviteButton
              text="INSERIR"
              click={handleSubmit}
              disabled={!isClickAvaliable}
            />
          )}
        </Form>
      </CardContainer>
      {personList && personList.length > 0 && (
        <CardContainer>
          <PageTitle>Pessoas Inseridas</PageTitle>
          {personList.map(
            (
              {
                id,
                nome,
                dataEvento,
                localCerimonia,
                cidadeCerimonia,
                celular,
                telefone,
                usuario,
                status,
              },
              index
            ) => (
              <ItemContainer
                border
                key={id}
                last={index === personList.length - 1}
              >
                <h4>Cliente: {nome}</h4>
                <DataContainer>
                  <DataText>
                    {' '}
                    <strong>Data do evento: </strong>
                    {dataEvento === dateUndef
                      ? 'Data indefinida'
                      : moment(dataEvento).format('DD/MM/YYYY')}
                  </DataText>
                  <DataText>
                    <StatusSpan>Cidade: </StatusSpan>
                    {cidadeCerimonia}
                  </DataText>
                </DataContainer>
                <DataContainer>
                  <DataText>
                    <strong>Local da Cerimônia: </strong>
                    {localCerimonia}
                  </DataText>
                </DataContainer>
                <DataContainer>
                  <ContactText>
                    <strong>Contatos: </strong>
                    {celular || null} {telefone ? ` / ${telefone}` : null}
                  </ContactText>
                </DataContainer>
                <DataContainer>
                  <DataText>
                    <strong>Inserido por: </strong>
                    {'  '}
                    <UserIcon />
                    {'  '}
                    <UserSpan>{usuario}</UserSpan>
                  </DataText>
                  <DataText>
                    <strong>Status: </strong> <StatusSpan>{status}</StatusSpan>
                  </DataText>
                </DataContainer>
              </ItemContainer>
            )
          )}{' '}
        </CardContainer>
      )}
    </PageWrapper>
  );
};

export default InvitePersonForm;
