import React, { Fragment } from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: 3em 0 0 0;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;

  justify-content: space-evenly;
  ${({ border, last }) =>
    border && !last && `border-bottom: 2px solid ${theme.colors.grey1};`}
`;

const Title = styled.h2`
  color: ${theme.colors.grey3};
`;

const HistoryTitle = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
  }

  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  margin: 10px 0 3px 0;
`;

const HistoryText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  margin: 0 0 10px 5px;
`;

const PrizeDataHistory = ({ data }) => {
  return (
    <Wrapper>
      <Title>Relatório</Title>
      {data.map(({ codPrize, title, inStock, redeemed, collected }, index) => (
        <Fragment key={codPrize}>
          <RowContainer>
            <HistoryTitle style={{ width: '50%' }}>Nome do prêmio</HistoryTitle>
            <HistoryTitle style={{ width: '15%' }}>Estoque</HistoryTitle>
            <HistoryTitle style={{ width: '15%' }}>Resgatado</HistoryTitle>
            <HistoryTitle style={{ width: '15%' }}>Retirado</HistoryTitle>
          </RowContainer>
          <RowContainer border last={index === data.length - 1}>
            <HistoryText style={{ width: '50%' }}>{title}</HistoryText>
            <HistoryText style={{ width: '15%' }}>{inStock}</HistoryText>
            <HistoryText style={{ width: '15%' }}>{redeemed}</HistoryText>
            <HistoryText style={{ width: '15%' }}>{collected}</HistoryText>
          </RowContainer>
        </Fragment>
      ))}
    </Wrapper>
  );
};

export default PrizeDataHistory;
