import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { ImageModal, Loader } from './';
import { CircleMinusIcon, CirclePlusIcon, TrashIcon } from './icons';
import { theme } from '../styles/theme';
import { supplierContent } from '../services/api';
import Tooltip from '@material-ui/core/Tooltip';

const Container = styled.div`
display: flex;
flex-direction: column;
border: 2px solid ${theme.colors.yellow};
margin-bottom: 0.8em;
justify-content: center;
align-items: center;
text-align: center;
`;

const ImageContainer = styled.div`
  height: 110px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
`;

const SupplierImage = styled.img`
  max-height: 90%;
  max-width: 90%;
  border: 1px solid;
`;

const IconsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 1em;
  justify-content: center;
  text-align: center;
`;

const TooltipWrapper = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StrongSpan = styled.span`
  font-weight: 900;
`;

const SupplierPortfolioPictureCard = ({ link, ordem, update }) => {
  const [imgOpened, setImgOpened] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const mediaDelete = async (input, type) => {
    setLoading(true);
    let payload = {
      input,
      type,
    };
    const res = await supplierContent.deleteMedia(payload);
    if (res.ok && res.data) {
      toast.success('Mídia removida com sucesso');
      update();
      setLoading(false);
    } else {
      toast.error('Erro ao obter link da mídia.');
    }
  };

  const changeMediaOrder = async (media, type) => {
    if (type === 'minus' && ordem <= 1) {
      return toast.error('Erro. Essa foto já deve ser a primeira a aparecer');
    }
    const payload = {
      media,
      type,
    };
    const res = await supplierContent.changeMediaOrder(payload);

    if (res.ok && res.data) {
      toast.success('Mídia atualizada com sucesso');
      update();
    }
  };

  if (loading) {
    return <Loader />;
  }
  
  return (
    <Container>
      <ImageContainer>
        <SupplierImage
          onClick={() => setImgOpened(true)}
          src={link}
          alt="Imagem do fornecedor"
        />
      </ImageContainer>
      <p>Essa será a <StrongSpan>{ordem}ª</StrongSpan> foto a aparecer na sua página.</p>
      <IconsDiv> 
        <Tooltip title="Mostrar antes">
          <TooltipWrapper>
            <CirclePlusIcon
              dark
              onClick={() => changeMediaOrder(link, 'minus')}
            />
          </TooltipWrapper>
        </Tooltip>
        <Tooltip title="Mostrar depois">
          <TooltipWrapper>
            <CircleMinusIcon
              dark
              onClick={() => changeMediaOrder(link, 'plus')}
            />
          </TooltipWrapper>
        </Tooltip>
        <Tooltip title="Excluir foto">
          <TooltipWrapper>
            <TrashIcon onClick={() => mediaDelete(link, 1)} />
          </TooltipWrapper>
        </Tooltip>
      </IconsDiv>
      <ImageModal
        picture={link}
        opened={imgOpened}
        closeModal={() => setImgOpened(false)}
      />
    </Container>
  );
};

export default SupplierPortfolioPictureCard;
